import React, { useState, useEffect,useRef} from "react";
import { Container, Row, Col } from "react-bootstrap";

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'

import { Link } from 'react-router-dom'

import ReactModal from "react-modal";
import { Checkbox, Select   } from 'antd';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ExcelJs from "exceljs";
import ChartReport from "./ChartReport";
import { getPproductivityDetailOverview } from "../../productivity/actionMethods/actionMethods";
import moment from "moment";
import { getPeformanceIndividualHistory } from "../../productivity/actionMethods/actionMethods";
import { eqProps, prop } from "ramda";
import {getPastReportData, getPerformanceHistory} from '../actionMethods/actionMethods'
import { getOnBoardEmp } from "../../costManagement/actionMethods/actionMethods";
import { getEmployeeCost } from "../../costManagement/actionMethods/actionMethods";
// import dummyData from '../../assets/get_onboarding_report.json'
// import perfrmanceReg from '../../assets/get_productivity_history_emp.json'
// import perfrmanceDaily from '../../assets/get_productivity_history_daily.json'
// import {ReactComponent as StarFill} from '../../assets/images/star-sharp.svg'
// import {ReactComponent as StarEmpty} from '../../assets/images/star-sharp-empty.svg'
import { getReportByAttendanceV2 } from "../actionMethods/actionMethods";
import { Collapse } from 'antd';

export default function Attendance(props){

    let StarFill =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f2bc19"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM351.9 311.8l33.72 103.9l-88.25-64.18c-5.61-4.08-13.21-4.08-18.82 0L190.3 415.6l33.72-103.9c2.139-6.589-.2053-13.81-5.808-17.88L129.9 229.6h109.2c6.933 0 13.08-4.465 15.22-11.06L288 114.8l33.7 103.8c2.141 6.594 8.285 11.06 15.22 11.06h109.2l-88.34 64.24C352.2 297.1 349.8 305.2 351.9 311.8z"/></svg>`;
    let StarEmpty =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#b5b1a6"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"/></svg>`;
    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

    const [allDataPeformance,SetAllDataPerformance]=useState([]);
    const [allDataAgency,SetAllDataAgency]=useState([]);
    const [allDataFunction,SetAllDataFunction]=useState([]);

    const [dataPeformance,SetDataPerformance]=useState([]);
    const [showDataDefault,SetShowDataDefault]=useState([]);
    const [chartLoader,SetChartLoader]=useState(true);
    const [filterBtn,SetFilterBtn]= useState([])
    const [filterBtnAgency,SetFilterBtnAgency]= useState([])
    const [filterActive,SetFilterActive]= useState([])
    const [chartData,SetChartData] = useState({series:[],categories:[],week:[]})
    const [chartDataAgency,SetChartDataAgency] = useState({series:[],categories:[],week:[]})
    const [filterActiveAgency,SetFilterActiveAgency] = useState('');
    const [chart1,SetChart1] = useState({series:[],categories:[]})
    const [chart2,SetChart2] = useState(true)
    const [loader,SetLoader]=useState(true);
    const [isLoading,SetisLoading] =useState(false);
    const [dateCount,SetDateCount] =useState(true);
    const [totalcount,SetTotalCount] =useState(true);
    const [count, SetCount]= useState(0)
    const { Panel } = Collapse;
    const monthInt= ['','Jaunary',"February","March","April","May","June","July","August","September","October","November","December"]
    const [week,SetWeek]=useState([]);
    const [showDataAgency,SetShowDataAgency] =useState([])
    const [showDataFunction,SetShowDataFunction] =useState([])
    const [workerCost,SetWorkerCost] = useState([]);
    const [workerCostApi,SetWorkerCostApi] = useState(false);
    let workday = props.workday;
    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }


      function ratingCal(val){
        if(parseFloat(val)>= 90){
            return 5
        }else if(parseFloat(val)>= 80 && parseFloat(val)< 90){
            return 4
        }
        else if(parseFloat(val)>= 70 && parseFloat(val)< 80){
            return 3
        }
        else if(parseFloat(val)>= 60 && parseFloat(val)< 70){
            return 2
        }
        else if(parseFloat(val)>= 50 && parseFloat(val) < 60){
            return 1
        }else{
            return 0
        }

      }
      let startShift={hour:workday?.start_hour.split(":")[0],minute:workday?.start_hour.split(":")[1],second:0};
      let endShift= {hour:workday?.end_hour.split(":")[0],minute:workday?.end_hour.split(":")[1],second:59};
      const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]

    useEffect(()=>{
      let requestBody={}

      getEmployeeCost(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){

          SetWorkerCost(res.data);
          SetWorkerCostApi(true);
        }
      })
      
    },[])

   
  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };


function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}


  function slugify(string) {
    return string
      .toString()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "_");
  }

function isNumeric(value) {
    return typeof value === 'number' && !isNaN(value);
}

function getDaysCount(start,end){
     
     let startDate =moment(start).toDate();
     let endDate = moment(end).toDate();
     let totalDays = moment(end).diff(start, 'days') + 1     
     let incHol= 0;
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        if(!isWeekday(startDate)){
          incHol++;
        }
      }
     
      return totalDays - incHol;
}

  const isWeekday = (date) => {
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return     
        }else{
        return day ;
        }        
  };
  
  function formatToHour(x,y){
 
  if(x ==="00:00:00" || x ==="" || x ==="0:00:00" ){
    return " - "
  }else{
    return x;
  }    
    
  }
  
    function formatNumberWithAbbreviation(number) {
      const billion = 1e9;
      const million = 1e6;
      const thousand = 1e3;

      if (number >= billion) {
        return (number / billion) + 'B';
      } else if (number >= million) {
        return (number / million) + 'M';
      } else if (number >= thousand) {
        return (number / thousand) + 'k';
      } else {
        return number;
      }
}

  


useEffect(()=>{
//let res= dummy;
const fetchData = async () => {


if(props.filterEnableCycle ){
SetisLoading(true);
// let emp=[];
let allemp =  []
let allData =[];
 let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
 
 let incLoop=0;
//for(let m= 0; m < props.type.length;m++){
  let record={};
  const mergedData = {
    "data": {},
    "message": "Success",
    "status": 200
  };
  let empPlanned= [];
  let currentDate = new Date(props.start_date);
  let endDate = new Date(props.end_date);
  console.log("props.threeshold[0]",props.threeshold);
  // let attendance =  await getReportByAttendanceV2(userSession,org_id,sub_org_id,getDateFormat(props.start_date),getDateFormat(props.end_date), props.threeshold[1],props.type.join(','),props.reportPerformance);


  let attendance =  await getReportByAttendanceV2(userSession,org_id,sub_org_id,getDateFormat(props.start_date),getDateFormat(props.end_date),props.threeshold[0],props.threeshold[1],props.type.join(','),props.reportPerformance);
  
  if(attendance.status===200){
    console.log("attendance",attendance);
    console.log("attendance.worker_attendance",attendance.worker_attendance)
    SetDataPerformance(attendance.worker_attendance)
    SetAllDataPerformance(attendance.worker_attendance);

    SetAllDataAgency(attendance.agency_data);
    SetAllDataFunction(attendance.sub_department_data);

    
    AGTable(attendance.worker_attendance,"AllDataEMpPeform")  
    AGTableAgency(attendance.agency_data,"AllDataAgency");
    AGTableFunction(attendance.sub_department_data,'AllDataFunction')  

    SetLoader(false);
    
    SetisLoading(false);
    props.exportEnableFunction();
  }else{
    SetLoader(false);
    SetisLoading(false);
  }



              //    SetDataPerformance(allData);
              //    SetAllDataPerformance(allData);
                 
    
              //    SetChartLoader(false);
                
              //    SetisLoading(false);
              //   props.exportEnableFunction();
                
              //    AGTable(allData,"AllDataEMpPeform")  

              //  AGTableAgency(allData,"AllDataEMpPeformAgency")
              //   AGTableFunction(allData,"AllDataEMpPeformFunction") 


// }else{
//                  SetDataPerformance([]);
//                  SetAllDataPerformance([]);
// }
} 
  }
  fetchData()
},[props.filterEnableCycle])



      // useEffect(()=>{
      //     if(props.filterEnableCycle){
      //       if(props.employee.length > 0 && allDataPeformance.length>0)
      //       AGTable(allDataPeformance,"AllDataEMpPeform") 
    
      //       AGTableAgency(allDataPeformance,"AllDataEMpPeformAgency");
      //       AGTableFunction(allDataPeformance,"AllDataEMpPeformFunction") 
      //     }
      // },[props.threeshold])

      function formatDataFunction(moDiff,allDataPeformance){
        //Function 
        let deptList =[];

        Object.keys(allDataPeformance).forEach(key=>{
        let agencySingle ={
          department:key,
          ...allDataPeformance[key]
        };
        deptList.push(agencySingle);
       });

       console.log("deptList",deptList)

        return deptList
      }
            function formatDataAgency(moDiff,allDataPeformance){
        //Function 
        let agencyList =[];

       Object.keys(allDataPeformance).forEach(key=>{
        let agencySingle ={
          agency_name:key,
          ...allDataPeformance[key]
        };
        agencyList.push(agencySingle);
       });

       console.log("agencyList",agencyList);
       
return agencyList
      }

      useEffect(()=>{
      
        if(props.exportEnableCycle){
          exportToExcel();
        }
        },[props.exportEnableCycle])
        const exportToExcel = () => {
            let sheetName = "Worker Attendance";
            let headerName= "Report"
            let fileName='';
            let moDiff = getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance)
            let data = allDataPeformance;
            if(props.start_date == props.end_date){
                fileName = "Attendance Governance"+"-"+moment(props.start_date).format("MMM DD")+".xlsx";
            }else{
                fileName = "Attendance  Governance"+"-"+moment(props.start_date).format("MMM DD")+" - "+moment(props.end_date).format("MMM DD")+".xlsx";
            }
            
            const workbook = new ExcelJs.Workbook();
            let loc = [];
            let arr3=[]
          
          
            let worksheet = workbook.addWorksheet('Worker Attendance');
            worksheet.addRow(['Min Filter',props.threeshold[0]])
            worksheet.addRow(['Max Filter',props.threeshold[1]])
            worksheet.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
            worksheet.addRow(['End Date',moment(props.end_date).format("MMM DD")])
            let cols =[
               {name:'Name', header: 'Name', key: 'emp_name'},
              {name:'Worker ID', header: 'Worker ID', key: 'worker_id'},   
              {name:'Agency Name', header: 'Agency Name', key: 'agency_name'}
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                    
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Performance %", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Performance %", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Paln Attendance (days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+"Plan Attendance (days)", key:"attendance_plan_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance (days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance (days)", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+"  Overtime", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+"  Overtime", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+"  Overtime", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+"  Shortime", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     cols.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                  }else{
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Performance %", header:weekSlug(moDiff[j].weekName)+" Performance %", key: weekSlug(moDiff[j].weekName)  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Plan Attendance (days)", header:weekSlug(moDiff[j].weekName)+" Plan Attendance (days)", key:"attendance_plan_"+weekSlug(moDiff[j].weekName)} );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Actual Attendance (days)", header:weekSlug(moDiff[j].weekName)+" Attendance (days)", key: weekSlug(moDiff[j].weekName)  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Actual Attendance (days)", header:weekSlug(moDiff[j].weekName)+" Overtime Count", key: weekSlug(moDiff[j].weekName)  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Actual Attendance (days)", header:weekSlug(moDiff[j].weekName)+" Shortime Count", key: weekSlug(moDiff[j].weekName)  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Overtime", header:weekSlug(moDiff[j].weekName)+"  Overtime", key: weekSlug(moDiff[j].weekName)+"hour"  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Shortime", header:weekSlug(moDiff[j].weekName)+"  Shortime", key: weekSlug(moDiff[j].weekName)+"shortime"  } );
                     cols.push({name:weekSlug(moDiff[j].weekName)+" Cost", header:weekSlug(moDiff[j].weekName)+" Cost", key: weekSlug(moDiff[j].weekName)  } );
                  }
            
                }
                // cols.push({name:'Attendance %', header: 'Attendance %', key: 'daysCount'})
            console.log("header",cols);  
                let attdData = data.map((e) => {
                let arr =[];
                let arr2=[];

                    for(let j=0;j<moDiff.length;j++){

                      if(props.reportPerformance=='month'){
                          arr2.push(parseFloat(e['performance_'+moment(moDiff[j].start).set(startShift).format('MMMM')] )) ;
                          arr2.push(parseFloat(e[`attendance_plan_${moment(moDiff[j].start).set(startShift).format('MMMM')}`])) ;
                          arr2.push(e[`attendance_actual_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]);
                          arr2.push(parseFloat(e[`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`])) ;
                          
                          arr2.push(parseFloat(e[`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`])) ;
                          arr2.push(formatToHour(e[`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]));
                          arr2.push(formatToHour(e[`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]));
                          arr2.push(formatNumberWithAbbreviation(e[`worker_cost_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]));
                      
                      }else{
                          arr2.push(parseFloat(e[weekSlug(moDiff[j].weekName)] ));
                          arr2.push(parseFloat(e[`attendance_plan_${weekSlug(moDiff[j].weekName)}`]));
                          arr2.push(parseFloat(e[`attendance_actual_${weekSlug(moDiff[j].weekName)}`]));
                          arr2.push(parseFloat(e[`over_time_${weekSlug(moDiff[j].weekName)}_count`]));
                          arr2.push(parseFloat(e[`under_time_${weekSlug(moDiff[j].weekName)}_count`]));
                          arr2.push(formatToHour(e[`over_time_${weekSlug(moDiff[j].weekName)}`]));
                          arr2.push(formatToHour(e[`under_time_${weekSlug(moDiff[j].weekName)}`]));
                          arr2.push(formatNumberWithAbbreviation(e[`worker_cost_${weekSlug(moDiff[j].weekName)}`]));
                      }
                  }
                  arr.push(e.name,e.worker_id,e.agency,...arr2.map(l=> l));
          
                 return arr;
              })

            worksheet.addTable({
              name: "WorkerAttendance",
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : cols,
              rows: attdData
            })

            
            console.log("attdData",attdData)
          
            worksheet.state = 'visible';
            const table = worksheet.getTable('WorkerAttendance');

            worksheet.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
            
          const worksheet3 = workbook.addWorksheet('Function Attendance');
          worksheet3.addRow(['Min Filter',props.threeshold[0]])
          worksheet3.addRow(['Max Filter',props.threeshold[1]])
          worksheet3.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
          worksheet3.addRow(['End Date',moment(props.end_date).format("MMM DD")])



            

      let deptList  = formatDataFunction(moDiff,allDataPeformance)
        
      
      let data3=[]   
       data3 =  deptList

          let col3 =[
               {name:'Function Name', header: 'Function Name', key: 'name'},
                
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Peformance", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Total", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Plan Attendance(Man Days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance(Man Days)", key: moment(moDiff[j].start).set(startShift).format('MMMM')+"_threeshold"  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Actual Attendance(Man Days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance(Man Days)", key: moment(moDiff[j].start).set(startShift).format('MMMM')+"_threeshold"  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime Count", key: 'over_time_'+moment(moDiff[j].start).set(startShift).format('MMMM')+"_count"  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime Count", key: "under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')+"_count"  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Function Overtime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Function Overtime", key: "over_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Function Shortime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Function Shortime", key: "under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col3.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", key: "worker_cost_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );

                  }else{
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Peformance", header:weekSlug(moDiff[j].weekName)+" Total", key: weekSlug(moDiff[j].weekName)  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Plan Attendance(Man Days)", header:weekSlug(moDiff[j].weekName)+" Plan Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Overtime Count", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Shortime Count", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Function Overtime", header:weekSlug(moDiff[j].weekName)+" Function Overtime", key: "over_time_"+weekSlug(moDiff[j].weekName)  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Function Shortime", header:weekSlug(moDiff[j].weekName)+" Function Shortime", key: "under_time_"+weekSlug(moDiff[j].weekName)  } );
                     col3.push({name:weekSlug(moDiff[j].weekName)+" Cost", header:weekSlug(moDiff[j].weekName)+" Cost", key: "worker_cost_"+weekSlug(moDiff[j].weekName)  } );
                  }
            
                }
                
              
            worksheet3.addTable({
              name: 'FunctionGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : col3,
              rows: data3.map((e) => {
                let arr =[];
                let arr2=[];
                for(let j=0;j<moDiff.length;j++){
                  if(props.reportPerformance=='month'){
                     arr2.push(parseFloat(e["performance_"+moment(moDiff[j].start).set(startShift).format('MMMM')])); 
                      arr2.push(parseFloat(e[`attendance_plan_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]));
                      arr2.push(parseFloat(e['performance_'+moment(moDiff[j].start).set(startShift).format('MMMM')]));
                     
                      arr2.push(parseFloat(e[`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`]));
                      arr2.push( parseFloat(e[`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`]));
                      
                      arr2.push(formatToHour(e["over_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')],'inError'));
                      arr2.push(formatToHour(e["under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')],'inError'));
                      arr2.push(formatNumberWithAbbreviation(e["worker_cost_"+moment(moDiff[j].start).set(startShift).format('MMMM')]));
                     
                  }else{
                     arr2.push(parseFloat(e["performance_"+weekSlug(moDiff[j].weekName)])); 
                      arr2.push(parseFloat(e[weekSlug(moDiff[j].weekName)]));
                      arr2.push(parseFloat(e[`attendance_plan_${weekSlug(moDiff[j].weekName)}`]));
                      arr2.push(parseFloat(e[`over_time_${weekSlug(moDiff[j].weekName)}_count`]));
                      arr2.push(parseFloat(e[`under_time_${weekSlug(moDiff[j].weekName)}_count`]));
                      arr2.push(formatToHour(e["over_time_"+weekSlug(moDiff[j].weekName)]));
                      arr2.push(formatToHour(e["under_time_"+weekSlug(moDiff[j].weekName)]));
                      arr2.push(formatNumberWithAbbreviation(e["worker_cost_"+weekSlug(moDiff[j].weekName)]));
                  }
                }
                arr.push(e.department,...arr2.map(l=> l));
               
                return arr;
              })
            })
          
            worksheet3.state = 'visible';
            const table3 = worksheet3.getTable('FunctionGovernance');
           
            worksheet3.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
            
           //agency 
           const worksheet4 = workbook.addWorksheet('Agency Attendance');
          worksheet4.addRow(['Min Filter',props.threeshold[0]])
          worksheet4.addRow(['Max Filter',props.threeshold[1]])
          worksheet4.addRow(['Start Date',moment(props.start_date).format("MMM DD")])
          worksheet4.addRow(['End Date',moment(props.end_date).format("MMM DD")])



            

      let agencyList  = formatDataAgency(moDiff,allDataPeformance)
        
      
      let data4=[]   
       data4 =  agencyList

          let col4 =[
               {name:'Agency Name', header: 'Agency Name', key: 'name'},
                
            ];
                  for(let j=0;j<moDiff.length;j++){

                  if(props.reportPerformance=='month'){
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Peformance", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Total", key: moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Plan Attendance(Man Days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance(Man Days)", key: moment(moDiff[j].start).set(startShift).format('MMMM')+"_threeshold"  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Actual Attendance(Man Days)", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Attendance(Man Days)", key: moment(moDiff[j].start).set(startShift).format('MMMM')+"_threeshold"  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Overtime Count", key: 'over_time_'+moment(moDiff[j].start).set(startShift).format('MMMM')+"_count"  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime Count", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Shortime Count", key: "under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')+"_count"  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Agency Overtime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Agency Overtime", key: "over_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Agency Shortime", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Agency Shortime", key: "under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );
                     col4.push({name:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", header:moment(moDiff[j].start).set(startShift).format('MMMM')+" Cost", key: "worker_cost_"+moment(moDiff[j].start).set(startShift).format('MMMM')  } );

                  }else{
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Peformance", header:weekSlug(moDiff[j].weekName)+" Total", key: weekSlug(moDiff[j].weekName)  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Plan Attendance(Man Days)", header:weekSlug(moDiff[j].weekName)+" Plan Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Overtime Count", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Shortime Count", header:weekSlug(moDiff[j].weekName)+" Actual Attendance(Man Days)", key: weekSlug(moDiff[j].weekName)+"_threeshold"  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Agency Overtime", header:weekSlug(moDiff[j].weekName)+" Agency Overtime", key: "over_time_"+weekSlug(moDiff[j].weekName)  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Agency Shortime", header:weekSlug(moDiff[j].weekName)+" Agency Shortime", key: "under_time_"+weekSlug(moDiff[j].weekName)  } );
                     col4.push({name:weekSlug(moDiff[j].weekName)+" Cost", header:weekSlug(moDiff[j].weekName)+" Cost", key: "worker_cost_"+weekSlug(moDiff[j].weekName)  } );
                  }
            
                }
                
            worksheet4.addTable({
              name: 'AgencyGovernance',
              ref: "A6", 
              headerRow: true,
              totalsRow: false,
              style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
              },
              columns : col4,
              rows: data4.map((e) => {
                let arr =[];
                let arr2=[];
                for(let j=0;j<moDiff.length;j++){
                  if(props.reportPerformance=='month'){
                     arr2.push(parseFloat(e["performance_"+moment(moDiff[j].start).set(startShift).format('MMMM')])); 
                      arr2.push( parseFloat(e[`attendance_plan_${moment(moDiff[j].start).set(startShift).format('MMMM')}`]));
                      arr2.push( parseFloat(e['performance_'+moment(moDiff[j].start).set(startShift).format('MMMM')]));
                      //ovetime count shortime count
                      arr2.push( parseFloat(e[`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`]));
                      arr2.push( parseFloat(e[`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`]));
                      
                      arr2.push(formatToHour(e["over_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')],'inError'));
                      arr2.push(formatToHour(e["under_time_"+moment(moDiff[j].start).set(startShift).format('MMMM')],'inError'));
                      arr2.push(formatNumberWithAbbreviation(e["worker_cost_"+moment(moDiff[j].start).set(startShift).format('MMMM')]));
                     
                  }else{
                     arr2.push(parseFloat(e["performance_"+weekSlug(moDiff[j].weekName)])); 
                      arr2.push(parseFloat(e[weekSlug(moDiff[j].weekName)]));
                      arr2.push(parseFloat(e[`attendance_plan_${weekSlug(moDiff[j].weekName)}`]));
                      arr2.push(parseFloat(e[`over_time_${weekSlug(moDiff[j].weekName)}_count`]));
                      arr2.push(parseFloat(e[`under_time_${weekSlug(moDiff[j].weekName)}_count`]));
                      arr2.push(formatToHour(e["over_time_"+weekSlug(moDiff[j].weekName)]));
                      arr2.push(formatToHour(e["under_time_"+weekSlug(moDiff[j].weekName)]));
                      arr2.push(formatNumberWithAbbreviation(e["worker_cost_"+weekSlug(moDiff[j].weekName)]));
                  }
                }
                arr.push(e.agency_name,...arr2.map(l=> l));
                
                return arr;
              })
            })
          
            worksheet4.state = 'visible';
            const table4 = worksheet4.getTable('AgencyGovernance');
           
            worksheet4.columns.forEach(column => {
              const lengths = column.values.map(v => v.toString().length);
              const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
              column.width = maxLength+2;
            });
          

          table4.commit();

            table.commit();

            table3.commit();


            const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
            props.exportProcessExist();
          };
      function capitalizeFirstLetter(string) {
        if(string){
          return string.toLowerCase();
        }
      }


      function starRating(val){
        let rate='';
        if(val==0){
            return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
        }else{
            for(let i=1;i<=5;i++){
                if(i<=val){
                    rate+=`${StarFill}`;
                }else{
                    rate+=`${StarEmpty}`;
                }
            }
        }
        return `<div class="ratingFill ratingAG">${rate}</div>`;
      }


      function valueRenderPerformance(params,label,passed="agency"){
        
        var op =document.createElement('div');
    
        var eGui = document.createElement('div');
        if(params.value > -1){
    if(params.value == 0 ){
          eGui.innerHTML=`<div class="disabledCell headerSmallSize"> - </div>`;
        }
        else if(params.value > props.threeshold[1] ){
          eGui.innerHTML=`<div class=" headerSmallSize">${params.value}%</div>`;
        }else{
          eGui.innerHTML=`<div class=" headerSmallSize">${params.value}%</div>`;

        }

        }else{
         
        }
        
            
      return eGui;  
      }

      function starRatingRender(params){
        

        var op =document.createElement('div');
    
        var eGui = document.createElement('div');
         
            eGui.innerHTML=`${starRating(params.value)}`;
                
    
    
      
         return eGui;
    
    
    
    }

    const cellClassRules = {
  "cell-no-space":true 
};


function weekSlug(name){
  return name.toLowerCase().replaceAll(" ","_");
}

      function AGTable(data,key){
       
        key = data.length

        let arr;
        let arr2=[];
         let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        
         let newData=[]
      
         
        if(data){  
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(3+(moDiff.length*9)) :(props.widthContainer - 90)/ (3+(moDiff.length*9))      

          arr2.push(  
            <AgGridColumn
                field="name"
                headerName={"Name"}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
                cellClass = {"ag-cell--textUppercase"}
                minWidth={150}
            />
          )
          

     
        arr2.push(  
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
              cellClass = {"ag-cell--textUppercase"}
              minWidth={100}
          />
        )

         arr2.push( 
          <AgGridColumn
            field="agency"
            headerName={"Agency Name"}
            cellClass={'textCapitalize textCap'}
            minWidth={150}
          />
        )
          
      
          for(let j=0;j<moDiff.length;j++){

            if(props.reportPerformance =='month'){ 
              arr2.push(
                <AgGridColumn
              field={`performance_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Performance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,moment(moDiff[j].start).set(startShift).format('MMMM'),'From table')}
            />
             ,<AgGridColumn
              field={"attendance_plan_"+moment(moDiff[j].start).set(startShift).format('MMMM')}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Plan Attendance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              valueFormatter={(params)=>parseFloat(params.value)}
            />
           ,<AgGridColumn
              field={`attendance_actual_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Actual Attendance`}
              valueFormatter={(params)=>parseFloat(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            />,
            <AgGridColumn
            field={`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Over Time Count`}
            valueFormatter={(params)=>params.value? params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}

          />, 
          <AgGridColumn
            field={`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Shortime Count`}
            valueFormatter={(params)=>params.value?params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}
          />,
            <AgGridColumn
              field={`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Overtime`}
              valueFormatter={(params)=> params.value && params.value!="0:00:00"?params.value:" - "}
              minWidth={100}
              cellClassRules= {cellClassRules}
            />,
          <AgGridColumn
            field={`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Shortime`}
            valueFormatter={(params)=>params.value && params.value!="0:00:00"?params.value:" - "}
            minWidth={100}
            cellClassRules= {cellClassRules}
          />, 
            <AgGridColumn
              field={`worker_cost_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Cost`}
              valueFormatter={(params)=> formatNumberWithAbbreviation(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            /> )



            }else{
              
              arr2.push(
                <AgGridColumn
              field={`performance_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Performance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,weekSlug(moDiff[j].weekName),'From table')}
            />
             ,<AgGridColumn
              field={"attendance_plan_"+weekSlug(moDiff[j].weekName)}
              headerName={`${weekSlug(moDiff[j].weekName)} Plan Attendance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              valueFormatter={(params)=>parseFloat(params.value)}
            />
           ,<AgGridColumn
              field={`attendance_actual_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Actual Attendance`}
              valueFormatter={(params)=>parseFloat(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            />,
            <AgGridColumn
            field={`over_time_${weekSlug(moDiff[j].weekName)}_count`}
            headerName={`${weekSlug(moDiff[j].weekName)} Over Time Count`}
            valueFormatter={(params)=>params.value? params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}

          />, 
          <AgGridColumn
            field={`under_time_${weekSlug(moDiff[j].weekName)}_count`}
            headerName={`${weekSlug(moDiff[j].weekName)} Shortime Count`}
            valueFormatter={(params)=>params.value?params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}
          />,
            <AgGridColumn
              field={`over_time_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Overtime`}
              valueFormatter={(params)=> params.value && params.value!="0:00:00"?params.value:" - "}
              minWidth={100}
              cellClassRules= {cellClassRules}
            />,
          <AgGridColumn
            field={`under_time_${weekSlug(moDiff[j].weekName)}`}
            headerName={`${weekSlug(moDiff[j].weekName)} Shortime`}
            valueFormatter={(params)=>params.value && params.value!="0:00:00"?params.value:" - "}
            minWidth={100}
            cellClassRules= {cellClassRules}
          />, 
            <AgGridColumn
              field={`worker_cost_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Cost`}
              valueFormatter={(params)=> formatNumberWithAbbreviation(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            /> )

          }
        }
        
  
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                

                enableRangeSelection= {true}
                //onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
        
                }}
                width={widthCal}
                minWidth={100}
                headerHeight={35}
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >    
        {arr2}
        </AgGridReact>
        </div></div>
        
        SetShowDataDefault(arr);          
            
    }
            
    
    
      }

    function AGTableSingle(data,key){


    key = data.length

        let arr;
        let arr2=[];
         let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        
         let newData=[]
      
         
        if(data){  
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(3+(moDiff.length*9)) :(props.widthContainer - 90)/ (3+(moDiff.length*9))      

          arr2.push(  
            <AgGridColumn
                field="name"
                headerName={"Name"}
                valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
                cellClass = {"ag-cell--textUppercase"}
                minWidth={150}
            />
          )
          

     
        arr2.push(  
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
              cellClass = {"ag-cell--textUppercase"}
              minWidth={100}
          />
        )

         arr2.push( 
          <AgGridColumn
            field="agency"
            headerName={"Agency Name"}
            cellClass={'textCapitalize textCap'}
            minWidth={150}
          />
        )
          
      
          for(let j=0;j<moDiff.length;j++){

            if(props.reportPerformance =='month'){ 
              arr2.push(
                <AgGridColumn
              field={`performance_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Performance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,moment(moDiff[j].start).set(startShift).format('MMMM'),'From table')}
            />
             ,<AgGridColumn
              field={"attendance_plan_"+moment(moDiff[j].start).set(startShift).format('MMMM')}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Plan Attendance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              valueFormatter={(params)=>parseFloat(params.value)}
            />
           ,<AgGridColumn
              field={`attendance_actual_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Actual Attendance`}
              valueFormatter={(params)=>parseFloat(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            />,
            <AgGridColumn
            field={`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Over Time Count`}
            valueFormatter={(params)=>params.value? params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}

          />, 
          <AgGridColumn
            field={`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}_count`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Shortime Count`}
            valueFormatter={(params)=>params.value?params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}
          />,
            <AgGridColumn
              field={`over_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Overtime`}
              valueFormatter={(params)=> params.value && params.value!="0:00:00"?params.value:" - "}
              minWidth={100}
              cellClassRules= {cellClassRules}
            />,
          <AgGridColumn
            field={`under_time_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
            headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Shortime`}
            valueFormatter={(params)=>params.value && params.value!="0:00:00"?params.value:" - "}
            minWidth={100}
            cellClassRules= {cellClassRules}
          />, 
            <AgGridColumn
              field={`worker_cost_${moment(moDiff[j].start).set(startShift).format('MMMM')}`}
              headerName={`${moment(moDiff[j].start).set(startShift).format('MMMM')} Cost`}
              valueFormatter={(params)=> formatNumberWithAbbreviation(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            /> )



            }else{
              console.log("${weekSlug(moDiff[j].weekName)}",`${weekSlug(moDiff[j].weekName)}`,data.map(el=>el[`${weekSlug(moDiff[j].weekName)}`]));
              arr2.push(
                <AgGridColumn
              field={`performance_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Performance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              cellRenderer= {(params)=>valueRenderPerformance(params,weekSlug(moDiff[j].weekName),'From table')}
            />
             ,<AgGridColumn
              field={"attendance_plan_"+weekSlug(moDiff[j].weekName)}
              headerName={`${weekSlug(moDiff[j].weekName)} Plan Attendance`}
              minWidth={80}
              cellClassRules= {cellClassRules}
              valueFormatter={(params)=>parseFloat(params.value)}
            />
           ,<AgGridColumn
              field={`attendance_actual_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Actual Attendance`}
              valueFormatter={(params)=>parseFloat(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            />,
            <AgGridColumn
            field={`over_time_${weekSlug(moDiff[j].weekName)}_count`}
            headerName={`${weekSlug(moDiff[j].weekName)} Over Time Count`}
            valueFormatter={(params)=>params.value? params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}

          />, 
          <AgGridColumn
            field={`under_time_${weekSlug(moDiff[j].weekName)}_count`}
            headerName={`${weekSlug(moDiff[j].weekName)} Shortime Count`}
            valueFormatter={(params)=>params.value?params.value:" - "}
            minWidth={80}
            cellClassRules= {cellClassRules}
          />,
            <AgGridColumn
              field={`over_time_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Overtime`}
              valueFormatter={(params)=> params.value && params.value!="0:00:00"?params.value:" - "}
              minWidth={100}
              cellClassRules= {cellClassRules}
            />,
          <AgGridColumn
            field={`under_time_${weekSlug(moDiff[j].weekName)}`}
            headerName={`${weekSlug(moDiff[j].weekName)} Shortime`}
            valueFormatter={(params)=>params.value && params.value!="0:00:00"?params.value:" - "}
            minWidth={100}
            cellClassRules= {cellClassRules}
          />, 
            <AgGridColumn
              field={`worker_cost_${weekSlug(moDiff[j].weekName)}`}
              headerName={`${weekSlug(moDiff[j].weekName)} Cost`}
              valueFormatter={(params)=> formatNumberWithAbbreviation(params.value)}
              minWidth={80}
              cellClassRules= {cellClassRules}
            /> )

          }
        }
        
  
        arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
        <AgGridReact
                rowHeight={35}
                

                enableRangeSelection= {true}
                //onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
                autoGroupColumnDef={{
                    headerName: 'Name',minWidth: 200,field: 'name',
        
                }}
                width={widthCal}
                minWidth={100}
                headerHeight={35}
             defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
             pagination= {data.length>500}
             paginationPageSize= {500}             
                rowData={data}
                key={key}
                overlayNoRowsTemplate={
                    AGGridEMptyMEssage('')
                  }        
             >    
        {arr2}
        </AgGridReact>
        </div></div>
    
        return arr;
                  }

}


      
      
function AGTableFunction(dataIn,key){

        key = dataIn.length
        let arr;
        let arr2=[];
        // let deptList =[];
        let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        let deptList = formatDataFunction(moDiff,dataIn);
        
      
      let data=[]   
       data =  deptList
console.log("data dpet",data);
        if(data){  
          data = data.sort((a,b)=>{
            return new Date(a.date) -  new Date(b.date)
          })
          
        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(1+(moDiff.length*6)) :(props.widthContainer - 90)/ (1+(moDiff.length*6))      
          widthCal = widthCal > 150?widthCal:150   

    arr =<div className="WholeTableFLow">
                                <div className="headerAreaCost" style={{width:((1+ (8*moDiff.length)) * widthCal) +8,minWidth:"100%"}}>
                              <Row >
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Function Name</Col>
                                {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')} Performance`}</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Plan  Attendance</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Actual Attendance</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Overtime Count</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Shortime Count</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Fn. Overtime</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Fn. Shortime</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')} Cost`}</Col>
                  </>
              }else{
                   return <>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Performance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Plan Attendance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Actual Attendance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Overtime Count</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Shortime Count</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Fn. Overtime</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Fn. Shortime</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName} Cost`}</Col>
                   </>
              }
          })}
                            {/* <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Compliance %</Col> */}
                              </Row>
                            </div>
     <Collapse accordion className="customAccord customAccord2 customAccord3" style={{width:((1+ (8*moDiff.length)) * widthCal) +8,minWidth:"100%"}}>
      {data.map((item, index) => (
        <Panel header={<div className="costBodayDept" >
          <Row>
            <Col style={{maxWidth:widthCal,minWidth:widthCal,paddingLeft:"30px"}}>{item.department}</Col>
          {moDiff.map(el=>{
            console.log("Debug",item,item[`under_time_${moment(el.start).format('MMMM')}`],formatToHour(item[`under_time_${moment(el.start).format('MMMM')}`]))
              if(props.reportPerformance =='month'){
                  return <><Col style={{maxWidth:widthCal,minWidth:"100px"}}>
                              <span className="titleComp">{item[`performance_${moment(el.start).format('MMMM')}`]}</span>
                    </Col>

                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`attendance_plan_${moment(el.start).format('MMMM')}`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`${moment(el.start).format('MMMM')}`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`over_time_${moment(el.start).format('MMMM')}_count`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`under_time_${moment(el.start).format('MMMM')}_count`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatToHour(item[`over_time_${moment(el.start).format('MMMM')}`])}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatToHour(item[`under_time_${moment(el.start).format('MMMM')}`])}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatNumberWithAbbreviation(item[`worker_cost_${moment(el.start).format('MMMM')}`])}</span></Col>
                                                
                            </>

              }else{
                   return <>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{item[`performance_${el.weekName}`]}</Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`attendance_plan_${el.weekName}`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`${el.weekName}`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`over_time_${el.weekName}_count`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`under_time_${el.weekName}_count`]}</span></Col>                   
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatToHour(item[`over_time_${el.weekName}`])}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatToHour(item[`under_time_${el.weekName}`])}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatNumberWithAbbreviation(item[`worker_cost_${el.weekName}`])}</span></Col>
                </>
              }
          })}

          </Row>
        </div>}>
          <Collapse>
      
                
                {AGTableSingle(item.attendance,item.department,'single')}
      
          </Collapse>
        </Panel>
      ))}
    </Collapse></div>
            
      
        SetShowDataFunction(arr);
        // SetShowDataDefault(arr);                      
    }
            
    
    
      }
      function AGTableAgency(dataIn,key){

        key = dataIn.length




        let arr;
        let arr2=[];
        // let deptList =[];
        let moDiff= getMonths(new Date(props.start_date),new Date(props.end_date),props.reportPerformance);
        let deptList = formatDataAgency(moDiff,dataIn);
        
      
      let data=[]   
       data =  deptList

        if(data){  
          data = data.sort((a,b)=>{
            return new Date(a.date) -  new Date(b.date)
          })

        let widthCal= key=='allEmployeee'?(props.widthContainer - 60)/(1+(moDiff.length*6)) :(props.widthContainer - 90)/ (1+(moDiff.length*6))      
          widthCal = widthCal > 150?widthCal:150   

    arr =<div className="WholeTableFLow">
                                <div className="headerAreaCost" style={{width:((1+ (8*moDiff.length)) * widthCal) +8,minWidth:"100%"}}>
                              <Row >
                                <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Function Name</Col>
                                {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')} Performance`}</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Plan  Attendance</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Actual Attendance</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Overtime Count</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Shortime Count</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Fn. Overtime</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')}`} <br/>Fn. Shortime</Col>
                      <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${moment(el.start).format('MMM')} Cost`}</Col>
                  </>
              }else{
                   return <>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Performance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Plan Attendance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Actual Attendance</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Overtime Count</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`} <br/>Shortime Count</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Fn. Overtime</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName}`}<br/> Fn. Shortime</Col>
                    <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{`${el.weekName} Cost`}</Col>
                   </>
              }
          })}
                            {/* <Col style={{maxWidth:widthCal,minWidth:widthCal}}>Compliance %</Col> */}
                              </Row>
                            </div>
     <Collapse accordion className="customAccord customAccord2 customAccord3" style={{width:((1+ (8*moDiff.length)) * widthCal) +8,minWidth:"100%"}}>
      {data.map((item, index) => (
        <Panel header={<div className="costBodayDept" >
          <Row>
            <Col style={{maxWidth:widthCal,minWidth:widthCal,paddingLeft:"30px"}}>{item.agency_name}</Col>
          {moDiff.map(el=>{
              if(props.reportPerformance =='month'){
                  return <><Col style={{maxWidth:widthCal,minWidth:"100px"}}>
                              <span className="titleComp">{item[`performance_${moment(el.start).format('MMMM')}`]}</span>
                    </Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`attendance_plan_${moment(el.start).format('MMMM')}`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`attendance_actual_${moment(el.start).format('MMMM')}`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`over_time_${moment(el.start).format('MMMM')}_count`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{item[`under_time_${moment(el.start).format('MMMM')}_count`]}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatToHour(item[`over_time_${moment(el.start).format('MMMM')}`])}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatToHour(item[`under_time_${moment(el.start).format('MMMM')}`])}</span></Col>
                    <Col style={{maxWidth:widthCal,minWidth:"100px"}}><span >{formatNumberWithAbbreviation(item[`worker_cost_${moment(el.start).format('MMMM')}`])}</span></Col>
                 </>

              }else{
                   return <>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}>{item[`performance_${el.weekName}`]}</Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`attendance_plan_${el.weekName}`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`attendance_actual_${el.weekName}`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`over_time_${el.weekName}_count`]}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{item[`under_time_${el.weekName}_count`]}</span></Col>
                   
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatToHour(item[`over_time_${el.weekName}`])}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatToHour(item[`under_time_${el.weekName}`])}</span></Col>
                   <Col style={{maxWidth:widthCal,minWidth:widthCal}}><span>{formatNumberWithAbbreviation(item[`worker_cost_${el.weekName}`])}</span></Col>
                </>
              }
          })}

          </Row>
        </div>}>
          <Collapse>
                {AGTableSingle(item.attendance,item.agency_name,'single')}
      
          </Collapse>
        </Panel>
      ))}
    </Collapse></div>
        SetShowDataAgency(arr);
        // SetShowDataDefault(arr);                      
    }
          
      }


      function AGGridEMptyMEssage(key){
        let msg;
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        return msg
      }






      return (
  
        props.filterEnableCycle?<>
         <div className="reportChart">

         {isLoading?
          <div className="text-center loader-filter ">
                                      
                                      <div className="loadingInfo loadingInfo2" style={{marginBottom:"20px"}}>
                                    
                                        <img src={spinnerLoader} /> 
                                      </div>
                                        
                                  </div>:<>
                                  <div className="dividerSection">
                                  <h4>Worker Attendance</h4>
                                  {showDataDefault}
                                  </div>
                                   <div className="dividerSection">
                                  <h4>Agency Governance</h4>
                                  {showDataAgency}
                                  </div>

                                   <div className="dividerSection">
                                  <h4>Function Attendance</h4>
                                  {showDataFunction}
                                  </div>

                                  </> 
        }
        
        </div>
        </>:""
      )

}