import React, { useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import moment from 'moment';
import './OrganizationalChartComponent.css';

const shiftNames = {
  P: 'Morning',
  S: 'Afternoon',
  M: 'Night'
};
const headerTime =["12 AM","1 AM","2 AM","3 AM","4 AM","5 AM","6 AM","7 AM","8 AM","9 AM","10 AM","11 AM","12 PM","1 PM","2 PM","3 PM","4 PM","5 PM","6 PM","7 PM","8 PM","9 PM","10 PM","11 PM"]

function getDateFromDayNumber(year, dayNumber) {
  const startDate = new Date(year, 0, 1); // January 1st of the given year
  startDate.setDate(startDate.getDate() + dayNumber - 1); // Subtract 1 because the 1st day is already counted
  return moment(startDate).format("DD MMM YY");
}

const transformDate = (label, type, startDate) => {
  if (type === 'day') {
    const dayNumber = parseInt(label.replace('day ', ''), 10);



    return getDateFromDayNumber(moment(startDate).format('YYYY'),dayNumber);
  } else if (type === 'month') {
    const monthNumber = parseInt(label.replace('month ', ''), 10);
    return moment(startDate).add(monthNumber - 1, 'months').format('MMMM');
  }
  return label;
};

const transformData = (data, type, startDate) => {
  switch (type) {
    case 'day':
      return data && Object.keys(data).map(day => ({
        name: transformDate(day, type, startDate),
        children: data[day] && Object.keys(data[day][day]).map(shift => ({
          name: shiftNames[shift],
          children: data[day][day] && Object.keys(data[day][day][shift]).map(hour => ({
            name: headerTime[parseInt(hour)],
            children: [{ name: `${data[day][day][shift][hour]}%` }]
          })),
        })),
      }));

    case 'week':
      return data && Object.keys(data).map(week => ({
        name: week,
        children:data[week] && Object.keys(data[week]).map(day => ({
          name: transformDate(day, 'day', startDate),
          children: data[week][day] && Object.keys(data[week][day]).map(shift => ({
            name: shiftNames[shift],
            children: data[week][day][shift] &&  Object.keys(data[week][day][shift]).map(hour => ({
              name: headerTime[parseInt(hour)],
              children: [{ name: `${data[week][day][shift][hour]}%` }]
            })),
          })),
        })),
      }));

    case 'month':
      return data && Object.keys(data).map(month => ({
        name: transformDate(month, type, startDate),
        children: data[month] && Object.keys(data[month]).map(day => ({
          name: transformDate(day, 'day', startDate),
          children:data[month][day] &&  Object.keys(data[month][day]).map(shift => ({
            name: shiftNames[shift],
            children:data[month][day][shift] && Object.keys(data[month][day][shift]).map(hour => ({
              name: headerTime[parseInt(hour)],
              children: [{ name: `${data[month][day][shift][hour]}%` }]
            })),
          })),
        })),
      }));

    default:
      return [];
  }
};

const OrganizationalChartComponent = ({ data, type, startDate }) => {
  const [selectedTab, setSelectedTab] = useState(Object.keys(data)[0]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabs = () => {
    return Object.keys(data).map((tab, index) => (
      <button
        key={index}
        onClick={() => handleTabClick(tab)}
        className={selectedTab === tab ? 'buttonChartFilter buttonChartFilterActive button btn' : 'buttonChartFilter button btn'}
        
      >
        {transformDate(tab, type, startDate)}
      </button>
    ));
  };

  const transformedData = transformData({ [selectedTab]: data[selectedTab] }, type, startDate)[0];

  const renderTree = (node) => (
    <TreeNode label={node.name} key={node.name}>
      {node.children && node.children.map(child => renderTree(child))}
    </TreeNode>
  );

  return (Object.keys(data).length > 0?
    <div>
      <div className="buttonFilterArea buttonFilterArea2">{renderTabs()}</div>
      <div className="chart-container" style={{marginTop:20}}>
        {transformedData && (
          <Tree
            label={<div className="root-node">{transformedData.name}</div>}
          >
            {transformedData.children && transformedData.children.map(child => renderTree(child))}
          </Tree>
        )}
      </div>
    </div>:<div className='noDataFOund noDataFOundStyle' style={{padding:"10px 20px",border:"1px solid #ddd",background:"#fff"}}><h4 style={{marginBottom:0,fontSize:14}}>No Result for Bad Performance</h4></div>
  );
};

export default OrganizationalChartComponent;
