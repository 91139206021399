import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import { Slider  } from 'antd';
import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getFopReportFilter } from "../../actionMethods/actionMethods";
import Scrollbars from "react-custom-scrollbars";
import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";
import { getPastReportData } from "../../actionMethods/actionMethods";
import { getPlanAttendanceReport } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
import Item from "antd/lib/list/Item";


const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function PlanActualReport (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [filterFOP,SetFilterFOP] =  useState(false);

    const [employee,SetEmployee]= useState([])
    const [shiftWorker,SetShiftWorker] = useState([]);
    
    const [reportHeader,SetReportHeader] = useState({});
    const [reportHeaderData,SetReportHeaderData] = useState([]);
    const [reportData,SetReportData]= useState([]);
    const [tableData,SetTableData] = useState([]);
    const [selectedRecord,SetSelectedRecord] =  useState();

    const indonesiaTimeZone = 'Asia/Jakarta';  
    let workday = props.workday;
    let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
      

  
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

 function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}
function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
      const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]
  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };


function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}

function replaceNaNWithZero(obj) {
    // Iterate over each property in the object
    for (let key in obj) {
        // Check if the property value is a number and NaN
        if (typeof obj[key] === 'number' && isNaN(obj[key])) {
            // Replace NaN with 0
            obj[key] = 0;
        }
        // If the property value is an object, recursively call replaceNaNWithZero
        else if (typeof obj[key] === 'object') {
            obj[key] = replaceNaNWithZero(obj[key]);
        }
    }
    return obj;
}

function isInShift(checkDateTime, shiftStartTime,shiftEndTime ,sftWorker,worker_id,date,employee) {
  
    try{
  const checkTime = checkDateTime.getTime();
    const startTime = date?new Date(date):new Date(checkDateTime);

    startTime.setHours(shiftStartTime.split(":")[0], shiftStartTime.split(":")[1]);


    const endTime = new Date(`${date} ${shiftEndTime.split(":")[0]}:${shiftEndTime.split(":")[1]}`)



   if(start_hour[0]==="00" && start_hour[1]=='00' && end_hour[0]==="23"&& end_hour[1]==='59'){
      if(endTime <startTime && shiftStartTime!="10:00" && shiftEndTime!="17:59:59"){
        // endTime.setDate(endTime.getDate + 1)
        endTime.setDate(endTime.getDate() + 1)

      }

      if(shiftEndTime === "01:00" && startTime.getHours() < 5){
        endTime.setDate(endTime.getDate() + 1)
      }
      // if(shiftEndTime === "10:00" && checkDateTime.getHours() < 5){
      //   startTime.setDate(startTime.getDate() + 1)
      //   endTime.setDate(startTime.getDate())
      // }
   }
   else{
    if(endTime <startTime && shiftStartTime!="10:00" && shiftEndTime!="17:59:59"){
      // endTime.setDate(endTime.getDate + 1)
      endTime.setDate(endTime.getDate() + 1)
    }

     if(shiftEndTime === "01:00" && startTime.getHours() < 5){
      endTime.setDate(endTime.getDate() + 1)
    }
    if(shiftEndTime === "10:00" && checkDateTime.getHours() < 5){
      startTime.setDate(startTime.getDate() + 1)
      endTime.setDate(startTime.getDate())
    }
    
   }

     if(employee.worker_id==="20112601"){
      console.log("debug reason ",employee.worker_id,checkTime >= startTime.getTime(),checkTime <= endTime.getTime(),startTime,checkTime,endTime)
      console.log("debug reason checkInTime",shiftStartTime,shiftEndTime,checkDateTime, startTime);
      console.log("debug reason checkoutTime",shiftStartTime,shiftEndTime,checkDateTime, endTime)
     }

    return (checkTime >= startTime.getTime() && checkTime <= endTime.getTime() );

  
    }catch(err){
      console.log("Any error skipped",err)
    }  

}

function filterEmployeesByShift(employees, shiftTime,sftWorker) {

  console.log("all EMployee passed",[...employees].filter(el=>el.worker_id==="MR23100187"),employees)

      return  [...employees].filter(employee => {

          
              // console.log(`employee in ${employee.worker_id}`,employee);
          


            const checkInDateTime = new Date(employee.check_in.replaceAll(" GMT",""));
            const checkOutDateTime = employee.check_out?new Date(employee.check_out):new Date();
        
            if (isInShift(checkInDateTime, shiftTime.start, shiftTime.end,sftWorker,employee.worker_id,employee.date,employee)) {
                return true; // Employee's check-in or check-out falls within any of the shift times
            }
        
        return false; // Employee's check-in and check-out don't fall within any of the shift times
    });    
}


function filterEmployeeHeader(data,type,start,end){
 let arr=[];

 if(type=="day"){
let currentDate = new Date(start);
      while (currentDate <= end) {
        //here day wise loop will be going through.
        let thisData= data.filter(el=>el.date === moment(currentDate).format("YYYY-MM-DD") || moment(el.date).format("YYYY-MM-DD") === moment(currentDate).format("YYYY-MM-DD"));
      
        

        currentDate.setDate(currentDate.getDate() + 1);
      }

 }else{

 }

return arr;

}
function getUniqueDataByWorkerId(data) {
  const uniqueData = [];
  const seenWorkerIds = new Set();

  for (const entry of data) {
    if (!seenWorkerIds.has(entry.worker_id)) {
      uniqueData.push(entry);
      seenWorkerIds.add(entry.worker_id);
    }
  }

  return uniqueData;
}

useEffect(()=>{
  let reportHead=[]

async function filterResultHandler(){
    try{
        if(props.filterActive){

          let requestBody=props.filterPassData;
          let newEmp =[];
          let shiftFilter=[];

          SetLoader(true);
          let empList = await getOnBoardEmp(userSession,org_id,requestBody.start_date,requestBody.end_date,sub_org_id).then(res=>res);
                    //  .filter(el=>requestBody.department.indexOf(el.department_name)  > -1)
             let regular = empList.data.regular?empList.data.regular:[];
             let daily = empList.data.daily?empList.data.daily:[];  
             
             let emp = [...regular,...daily].map(el=>{
                if(moment(el.check_in) > moment(el.check_in).set({hour:start_hour[0],minute:start_hour[1]})){
                  el.date = moment(el.check_in).format('YYYY-MM-DD')
                }else{
                  el.date = moment(el.check_in).subtract(1,'days').format('YYYY-MM-DD')
                }

              return el;
             })


        let pastReportEmployee =[];
        let startDate = new Date(requestBody.start_date);
        let endDate = new Date(requestBody.end_date);
         let eAr = await getPlanAttendanceReport(userSession,org_id,sub_org_id,getDateFormat(startDate),getDateFormat(endDate)).then(res=>{ return  res.data}) 
          

         Object.keys(eAr).forEach(key=>{



          if(requestBody.shift.length > 0){
            // pastReportEmployee.push(...eAr[key].attendance_list.filter(el=>requestBody.shift.indexOf(el.shift_code) >-1).map(el=>({...el,date:getDateFormat(key)}))) 
            pastReportEmployee.push(...eAr[key].attendance_list.map(el=>{

              var date = getDateFormat(key); 
              var compareDate= new Date(moment(el.check_in.replaceAll('GMT',"")))
              var timeAndDate = moment(date).set({"hour": end_hour[0], "minute": end_hour[1]});
              if(start_hour[0]==="00" && start_hour[1]=='00' && end_hour[0]==="23"&& end_hour[1]==='59'){

              }else{
                if(timeAndDate >= compareDate ){
                  date = moment(date).subtract(1,'day').format('YYYY-MM-DD');
              }
              }
              
              
              return({...el,date:date})}  ))             
          }else{

            pastReportEmployee.push(...eAr[key].attendance_list.map(el=>{
              
              
                              var date = getDateFormat(key); 
              var compareDate= new Date(moment(el.check_in.replaceAll('GMT',"")))
              var timeAndDate = moment(date).set({"hour": end_hour[0], "minute": end_hour[1]});
              if(start_hour[0]==="00" && start_hour[1]=='00' && end_hour[0]==="23"&& end_hour[1]==='59'){

              }else{

              if(timeAndDate >= compareDate ){
                  date = moment(date).subtract(1,'day').format('YYYY-MM-DD')
                 
              }
            }  
              return ({...el,date:date}) 
            } )) 
          }
         })

      

         let sftWorker = pastReportEmployee.map(el=>el.worker_id)
         

         SetShiftWorker(sftWorker)

         if(requestBody.shiftTime.length > 0){
          console.log("requestBody.shiftTime",requestBody.shiftTime)
          for(let i=0;i<requestBody.shiftTime.length;i++){
            const shiftTime = requestBody.shiftTime[i]; // Assuming only one shift time for now


          
            try{

              const filteredEmployees = filterEmployeesByShift(emp, shiftTime,sftWorker);
              newEmp.push(...filteredEmployees.map(el=>({...el,shift:shiftTime.children})))
              
            }catch(err){
              
            }
            
          }

          let empUnique =[];
          let skip=0;
          
    for(let i=0;i<newEmp.length;i++){

      if(!empUnique.find(el=> el.check_in == newEmp[i].check_in && el.check_out == newEmp[i].check_out && el.date == newEmp[i].date && newEmp[i].worker_id == el.worker_id)){
        empUnique.push(newEmp[i]);
      }else{
        skip++;
      }
    } 
          emp=empUnique
         }
         
         
        //  console.log("emp","MR23100187",emp,newEmp)
         SetEmployee(emp);

         
         
         let tData  = dataFormationPlanActual(getUniqueDataByWorkerId(pastReportEmployee),emp);
         
         SetTableData(tData);
         let head={};
         head.yes= tData.filter(el=>el.compliance =="Yes").length;
         head.no = tData.filter(el=>el.compliance == "No").length;
         head.absent = tData.filter(el=>el.compliance == "Absent").length;
         head.unexpected = tData.filter(el=>el.compliance == "Unexpected").length;
         head.shift_change = tData.filter(el=>el.compliance =="Shift Change").length
         head.attendance = head.yes + head.no + head.unexpected + head.shift_change;
         head.plan_attendance = head.yes + head.no + head.absent + head.shift_change;
         head.compliance = ((head.yes / (head.yes + head.no + head.absent + head.shift_change)) * 100).toFixed(2)
         
         head.key="all"
         reportHead.push(head);


         SetReportHeader(head);
 
         if(!pastReportEmployee){
            toast.error("Please Try again after some time");
            SetLoader(false);
          }            

          
            if(props.filterPassData.report_by=="day"){
              SetPlannedEmp(pastReportEmployee);
              // filterEmployeeHeader(tData,props.filterPassData.report_by,startDate,endDate)
            }else {
              let moDiff =  getMonths(startDate,endDate,props.filterPassData.report_by);
              let allWorker = tData.map(el=>{
                let single={...el};
                if(single.plan_check_in!=" - "){
                  single.plan_check_in =`${getDateFormat(el.date)} ${single.plan_check_in.replaceAll('AM',"").replaceAll('PM',"")}`
                }
                if(single.actual_check_in!=" - "){
                  single.actual_check_in = `${getDateFormat(el.date)} ${single.actual_check_in.replaceAll('AM',"").replaceAll('PM',"")}`
                }
                return single
              });

          
              let unqiueWorkerCal= [...new Set(allWorker.map(el=>el.worker_id))];
              let newOp=[];

              
            
              for(let i = 0;i<unqiueWorkerCal.length ; i++){
              
                let single={}
                single.serial=(i+1)
                let empDetail = emp.find(el=>el.worker_id === unqiueWorkerCal[i]);
                if(empDetail){
                  
                
                single.name=empDetail.name;
                single.worker_id=empDetail.worker_id;
                // Name, worker_id, total planned, total present , how many yes, how many no, Absent, Unexpected, compliance (yes count).
                for(let j=0; j<moDiff.length;j++){
                  // let empMonth =
          
                  let thisMonth  = allWorker.filter(el=>
                    el.worker_id === unqiueWorkerCal[i] &&
                    ((moment(el.plan_check_in).toDate() >= moment(moDiff[j].start).set({hour:start_hour[0],minute:start_hour[1],second:start_hour[2]}) &&
                    moment(el.plan_check_in).toDate() <= moment(moDiff[j].end).add(addDay,'days').set({hour:end_hour[0],minute:end_hour[1],second:end_hour[2]})) ||
                    (moment(el.actual_check_in).toDate() >= moment(moDiff[j].start).set({hour:start_hour[0],minute:start_hour[1],second:start_hour[2]}) &&
                    moment(el.actual_check_in).toDate() <= moment(moDiff[j].end).add(addDay,'days').set({hour:end_hour[0],minute:end_hour[1],second:end_hour[2]})))                    
                  )
                    let yes=  thisMonth.filter(el=>el.compliance==="Yes");
                    let shiftchange=  thisMonth.filter(el=>el.compliance==="Shift Change");
                    let no= thisMonth.filter(el=>el.compliance==="No");
                    let absent= thisMonth.filter(el=>el.compliance==="Absent");
                    let unexpected= thisMonth.filter(el=>el.compliance==="Unexpected");
                    
                 
                  const { totalWorkingHours, workingStatus } = calculateTotalWorkingHourStatusForMonthdWeek(thisMonth,empDetail.agency)
                 
                 
                  if(props.filterPassData.report_by=="month"){
                  
                    single[`${moment(moDiff[j].start).format('MMMM')}_total_planned`]= thisMonth.length - unexpected.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_total_actual`]= yes.length + no.length + unexpected.length + shiftchange.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_yes`]= yes.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_shift_change`]= shiftchange.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_no`]= no.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_absent`]= absent.length;

                    
                    single[`${moment(moDiff[j].start).format('MMMM')}_unexpected`]= unexpected.length;
                    
                    single[`${moment(moDiff[j].start).format('MMMM')}_working_hours`]= totalWorkingHours;
                    single[`${moment(moDiff[j].start).format('MMMM')}_working_status`]= workingStatus;


                    single[`${moment(moDiff[j].start).format('MMMM')}_compliance`]= (yes.length/(yes.length + no.length + absent.length +shiftchange.length)) * 100;

                    if(single[`${moment(moDiff[j].start).format('MMMM')}_total_planned`] < single[`${moment(moDiff[j].start).format('MMMM')}_unexpected`] ){
                      single[`${moment(moDiff[j].start).format('MMMM')}_compliance`]=  100;
                    }

                    

                  }else{
                    
                    single[`${moDiff[j].weekName}_total_planned`]= thisMonth.length - unexpected.length;
                    single[`${moDiff[j].weekName}_total_actual`]= yes.length + no.length + unexpected.length + shiftchange.length;
                    single[`${moDiff[j].weekName}_yes`]= yes.length;
                    single[`${moDiff[j].weekName}_shift_change`]= shiftchange.length;
                    single[`${moDiff[j].weekName}_no`]= no.length;
                    single[`${moDiff[j].weekName}_absent`]= absent.length;
                    single[`${moDiff[j].weekName}_unexpected`]= unexpected.length;
                    
                    single[`${moDiff[j].weekName}_working_hours`]= totalWorkingHours;
                    single[`${moDiff[j].weekName}_working_status`]= workingStatus;



                    single[`${moDiff[j].weekName}_compliance`]= (yes.length/(yes.length + no.length + absent.length + shiftchange.length)) * 100

                    if(single[`${moDiff[j].weekName}_total_planned`] < single[`${moDiff[j].weekName}_unexpected`] ){
                      single[`${moDiff[j].weekName}_compliance`]=  100;
                    }

                  }
                  

                  }
                newOp.push(single);
                }else{
                //here it will absent or may be unexpected. but 100% sure data is not checkedIn
                empDetail = pastReportEmployee.find(el=>el.worker_id === unqiueWorkerCal[i]);
                
        
                single.name=empDetail.name;
                single.worker_id=empDetail.worker_id;
                  
                  
                  for(let j=0; j<moDiff.length;j++){
                  // let empMonth = 
                  let thisMonth  = allWorker.filter(el=>
                    el.worker_id === unqiueWorkerCal[i] &&
                    ((moment(el.plan_check_in).toDate() >= moment(moDiff[j].start).set({hour:start_hour[0],minute:start_hour[1],second:start_hour[2]}) &&
                    moment(el.plan_check_in).toDate() <= moment(moDiff[j].end).add(addDay,'days').set({hour:end_hour[0],minute:end_hour[1],second:end_hour[2]})) ||
                    (moment(el.actual_check_in).toDate() >= moment(moDiff[j].start).set({hour:start_hour[0],minute:start_hour[1],second:start_hour[2]}) &&
                    moment(el.actual_check_in).toDate() <= moment(moDiff[j].end).add(addDay,'days').set({hour:end_hour[0],minute:end_hour[1],second:end_hour[2]})))                    
                  )
                    let yes=  thisMonth.filter(el=>el.compliance==="Yes");
                    let shiftchange=  thisMonth.filter(el=>el.compliance==="Shift Change");
                    let no= thisMonth.filter(el=>el.compliance==="No");
                    let absent= thisMonth.filter(el=>el.compliance==="Absent");
                    let unexpected= thisMonth.filter(el=>el.compliance==="Unexpected");

                  const { totalWorkingHours, workingStatus } = calculateTotalWorkingHourStatusForMonthdWeek(thisMonth,empDetail.agency)

                  

                  if(props.filterPassData.report_by=="month"){
                    
                    single[`${moment(moDiff[j].start).format('MMMM')}_total_planned`]= thisMonth.length - unexpected.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_total_actual`]= yes.length + no.length + unexpected.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_yes`]= yes.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_shift_change`]= shiftchange.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_no`]= no.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_absent`]= absent.length;
                    single[`${moment(moDiff[j].start).format('MMMM')}_unexpected`]= unexpected.length;
                    
                    single[`${moment(moDiff[j].start).format('MMMM')}_working_hours`]= totalWorkingHours?totalWorkingHours:"";
                    single[`${moment(moDiff[j].start).format('MMMM')}_working_status`]= workingStatus?workingStatus:"";
                    
                    single[`${moment(moDiff[j].start).format('MMMM')}_compliance`]= (yes.length/(yes.length + no.length + absent.length + shiftchange.length)) * 100;
                    
                  }else{
                    single[`${moDiff[j].weekName}_total_planned`]= thisMonth.length - unexpected.length;
                    single[`${moDiff[j].weekName}_total_actual`]= yes.length + no.length + unexpected.length;
                    single[`${moDiff[j].weekName}_yes`]= yes.length;
                    single[`${moDiff[j].weekName}_shift_change`]= shiftchange.length;
                    single[`${moDiff[j].weekName}_no`]= no.length;
                    single[`${moDiff[j].weekName}_absent`]= absent.length;
                    single[`${moDiff[j].weekName}_unexpected`]= unexpected.length;
                    single[`${moDiff[j].weekName}_compliance`]= (yes.length/(yes.length + no.length + absent.length + shiftchange.length)) * 100
                    single[`${moDiff[j].weekName}_working_hours`]= totalWorkingHours?totalWorkingHours:"";;
                    single[`${moDiff[j].weekName}_working_status`]= workingStatus?workingStatus:"";

                  }
                  }

                  newOp.push(single);
                }
                
                
              }

              SetPlannedEmp(newOp);

                //  filterEmployeeHeader(newOp,props.filterPassData.report_by,startDate,endDate);
              
              

              // SetLoader(false);
        
            }
            SetLoader(false);
            
          }else{
            SetPlannedEmp([]);
          
          }
    }catch(error) {
            
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);

function timeStringToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return ((hours * 60 + minutes) * 60 + seconds) * 1000;
}

function millisecondsToTimeString(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function valueRenderTimeStatus(params){
    
    const actualWorkHoursMs = timeStringToMilliseconds(params.data.actual_work_hours);
    const planWorkHoursMs = timeStringToMilliseconds(params.data.plan_work_hours);

    const differenceMs = actualWorkHoursMs - planWorkHoursMs;
    const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
    
    const differenceSign = differenceMs >= 0 ? '' : '-';
    var eGui = document.createElement('div');

    if(differenceSign === "-"){
      eGui.innerHTML=`<div class="redColor">${differenceSign}${differenceTimeString}</div>`;
    }else{
      eGui.innerHTML=`<div class="sucessColor">${differenceSign}${differenceTimeString}</div>`;
    } 
    return eGui;  

}

function dataFormationPlanActual(dataPlan,empployee){    
    let data=[];
  try{
        //in 
    for(let i=0;i<dataPlan.length;i++){

      let single={}


      let emp = empployee.find(el=>el.worker_id === dataPlan[i].worker_id);
         
         
      emp = empployee.find(el=> el.worker_id === dataPlan[i].worker_id
         && (moment(el.check_in) >=  moment(dataPlan[i].date).set({hour:start_hour[0],minute:start_hour[1]}))
           && moment(el.check_in) <=  moment(dataPlan[i].date).add(addDay,'day').set({hour:end_hour[0],minute:end_hour[1]})) ;
      

       
        
  if(emp){

        single.name= dataPlan[i].name;
        single.worker_id= dataPlan[i].worker_id;
        single.function = dataPlan[i].sub_function_name || emp.department_name;
        // let planSplit = dataPlan[i].shift_start_time.split(":");
        // let planStart = moment(dataPlan[i].employee_details_datetime).set({hour:planSplit[0],minute:planSplit[1],second:planSplit[2]});
        let planStart = moment(dataPlan[i].check_in?.replace(" GMT",""));
        let acStart = moment(emp.check_in);
        single.plan_check_in = moment(planStart).format('HH:mm A');
        single.actual_check_in = moment(acStart).format('HH:mm A');
        //checkout
        single.check_out =dataPlan[i].check_out?moment(dataPlan[i].check_out?.replace(" GMT","")).format('HH:mm A'):"-";
        single.actual_check_out =dataPlan[i].actual_check_out && dataPlan[i].actual_check_out!="" &&  dataPlan[i].actual_check_out!="" ? moment(dataPlan[i].actual_check_out?.replace(" GMT","")).format('HH:mm A'):"-";

        //working_hour 
        single.working_hours = dataPlan[i].working_hours;
        single.working_status = dataPlan[i].working_status;

      
        single.compliance = checkCompliance(planStart, acStart,dataPlan[i].name,dataPlan[i].data);
        single.date= new Date(emp.date);
        data.push(single);



      }else{
        //not found this mean not checkin
        single.name= dataPlan[i].name;
        single.worker_id= dataPlan[i].worker_id;
        single.function = dataPlan[i].sub_function_name;
        // let planSplit = dataPlan[i].shift_start_time.split(":");
        // let planStart = moment(dataPlan[i].employee_details_datetime).set({hour:planSplit[0],minute:planSplit[1],second:planSplit[2]});
        
        let planStart = moment(dataPlan[i]?.check_in.replace(" GMT",""));


        //future time
        if(new Date(planStart) > new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })){
          single.compliance = "Awaiting";  
        }else if(Math.abs(new Date(new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) - new Date(planStart)) / (1000 * 60) > 30){
          single.compliance = "Absent";  
        }else{
          single.compliance = "Awaiting"; 
        }

        single.date= planStart.toDate();
        single.plan_check_in = moment(planStart).format('HH:mm A');
        single.actual_check_in = " - ";

          //checkout
        single.check_out = moment(dataPlan[i]?.check_out.replace(" GMT","")).format('HH:mm A');;
        single.actual_check_out = " - ";

        //working_hour 
        single.working_hours = dataPlan[i].working_hours;
        single.working_status = dataPlan[i].working_status;

        data.push(single);
      }

        
      }
      
    //unplanned employee

  for(let i=0;i<empployee.length;i++){

      
      if(!data.find(el=>el.worker_id == empployee[i].worker_id && moment(el.date).set({hour:start_hour[0],minute:start_hour[1]}) <= moment(empployee[i].check_in) && moment(el.date).add(addDay,'day').set({hour:end_hour[0],minute:end_hour[1]}) >= moment(empployee[i].check_in) )){
        let single={}
              let emp= empployee[i];
              
                
      //found employee
        single.name= emp.name;
        single.worker_id= emp.worker_id;
        single.function = emp.sub_function_name || emp.department_name;

        let planStart = " - ";
        let acStart = moment(emp.check_in);
        single.plan_check_in = " - "; 
        single.actual_check_in = moment(acStart).format('HH:mm A');

        //checkout 
        single.check_out = " - "; 
        single.actual_check_out = emp.check_out?moment(emp.check_out.replaceAll(' GMT',"")).format('HH:mm A'):"-";
      
       const { workingHours, workingStatus } = calculateWorkingHoursAndStatus(emp.check_in.replaceAll(' GMT',""), emp.check_out?.replaceAll(' GMT',""),emp.agency) ;
        single.working_hours =workingHours
        single.working_status = workingStatus;


        single.compliance = "Unexpected";
        single.date= new Date(empployee[i].date);
        data.push(single);       
      }
    
    }
    

    data = data.sort((a,b)=>new Date(a.date)- new Date(b.date));
    data = data.map((el,index)=>({...el,serial:index+1}))

  }catch(error){

  }

    

    return data;
  }

  function calculateWorkingHoursAndStatus(checkInTime, checkOutTime,agency) {
    // Calculate working hours
    const diffMs = new Date(checkOutTime) - new Date(checkInTime);
    const workingHours = diffMs / (1000 * 60 * 60); // Convert milliseconds to hours

    // Determine working status based on working hours
    let workingStatus;
   
    if(agency.toLowerCase() === "lion parcel"){
          if(isNaN(workingHours)){
          workingStatus = " - ";
      }
      else if (workingHours < 7.5) {
          workingStatus = "Short-time";
      } else if (workingHours >= 7.5 && workingHours < 8.5) {
          workingStatus = "complied";
      } else {
          workingStatus = "Over-time";
      }
    }
    else if(agency.toLowerCase() === "jne" ||agency === "JNE_Daily_Worker"){
          if(isNaN(workingHours)){
          workingStatus = " - ";
      }
      else if (workingHours < 8) {
          workingStatus = "Short-time";
      } else if (workingHours >= 8 && workingHours < 9) {
          workingStatus = "complied";
      } else {
          workingStatus = "Over-time";
      }
    }else{
    if(isNaN(workingHours)){
      workingStatus = " - ";
    }  
    else if (workingHours < 8.5) {
        workingStatus = "Short-time";
    } else if (workingHours >= 8.5 && workingHours < 9.5) {
        workingStatus = "complied";
    } else  {
        workingStatus = "Over-time";
    }
    }
    

    // Convert milliseconds to seconds, then seconds to hours, minutes, seconds
    let seconds = Math.floor(diffMs / 1000);
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;

    // Format hours, minutes, seconds to ensure they're always two digits
    const formattedHours = !isNaN(hours)? hours.toString().padStart(2, '0'):"";
    const formattedMinutes =!isNaN(minutes)? minutes.toString().padStart(2, '0'):"";
    const formattedSeconds =!isNaN(seconds)? seconds.toString().padStart(2, '0'):"";

    
    return { workingHours:`${formattedHours}:${formattedMinutes}:${formattedSeconds}`, workingStatus };
}

function calculateTotalWorkingHourStatusForMonthdWeek(data, agency) {
    let totalWorkingHours = 0;
    const workingHoursThreshold = (agency.toLowerCase() === 'lion parcel'  || agency.toLowerCase() === "jne" ||agency === "JNE_Daily_Worker" ) ? 8 * 60 * 60 : 9 * 60 * 60; // in seconds

    data.forEach(item => {
        // Convert working_hours string to seconds
        if(item.working_hours==""){
          totalWorkingHours += 0
        }else{
          const [hoursStr, minutesStr, secondsStr] = item.working_hours.split(':').map(parseFloat);
        const workingHoursInSeconds = hoursStr * 60 * 60 + minutesStr * 60 + secondsStr;

        // Add to total working hours
        totalWorkingHours += workingHoursInSeconds;
        }
        
    });

    // Convert total working hours to hours and minutes
    const totalWorkingHoursHours = Math.floor(totalWorkingHours / 3600);
    const totalWorkingHoursMinutes = Math.floor((totalWorkingHours % 3600) / 60);
    const totalWorkingHoursSecond = Math.floor((totalWorkingHours % 3600) / 60*60);

    // Calculate number of days
    const numberOfDays = data.filter(el=>el.working_hours!=="").length;

    // Determine working status based on total working hours
    let workingStatus;
    if(agency.toLowerCase() === 'lion parcel'){
      if(totalWorkingHours== 0){
        workingStatus = "";
      }
      else if ( 
        ((numberOfDays * 7.5 * 3600) < totalWorkingHours)  && ((numberOfDays * 8.5 * 3600) > totalWorkingHours ) 
      ) {
          workingStatus = "complied";
      } else if ((numberOfDays * 7.5 * 3600) > totalWorkingHours) {
          workingStatus = "Short-time";
      } else{
          workingStatus = "Over-time";
      }

    }
    else if(agency.toLowerCase() === "jne" ||agency === "JNE_Daily_Worker"){
      if(totalWorkingHours== 0){
        workingStatus = "";
      }
      else if ( 
        ((numberOfDays * 8 * 3600) < totalWorkingHours)  && ((numberOfDays * 9 * 3600) > totalWorkingHours ) 
      ) {
          workingStatus = "complied";
      } else if ((numberOfDays * 8 * 3600) > totalWorkingHours) {
          workingStatus = "Short-time";
      } else{
          workingStatus = "Over-time";
      }

    }
    else{

      if(totalWorkingHours== 0){
        workingStatus = "";
      }
      else if ( 
        ((numberOfDays * 8.5 * 3600) < totalWorkingHours)  && ((numberOfDays * 9.5 * 3600) > totalWorkingHours ) 
      ) {
          workingStatus = "complied";
      } else if ((numberOfDays * 8.5 * 3600) > totalWorkingHours) {
          workingStatus = "Short-time";
      } else{
          workingStatus = "Over-time";
      }


    }
    

    return {
        totalWorkingHours: `${totalWorkingHoursHours}:${totalWorkingHoursMinutes}:${totalWorkingHoursSecond}`,
        workingStatus
    };
}





useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(plannedEmp,employee);
  }
},[props.exportEnable,plannedEmp,employee])

 const generateExcelFile = async (planned,emp) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
      if(props.filterPassData.report_by=="day"){
        await generateSheet(workbook, "Plan Vs Actual",dataFormationPlanActual(planned,emp) ,imageId);
      }else{
        await generateSheetWeekMonth(workbook, "Plan Vs Actual",planned ,imageId);
      }
    
    
  const fileName = 'PlanActualReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet
    const worksheet = workbook.addWorksheet(sheetName);

     worksheet.addImage(logoID, {
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });

    worksheet.addRow([])    
    worksheet.addRow([])    
    worksheet.addRow([])
    worksheet.addRow([])    
    worksheet.mergeCells('A1:B4');    
    worksheet.addRow([])
    const tableData = data.map(item => [moment(item.date).format('DD MMM YYYY'),item.name, item.worker_id,item.function,item.plan_check_in,item.actual_check_in,item.actual_check_out,item.working_hours,item.working_status,item.compliance]);
    const tableStartRow =  6;

   worksheet.addRow(['Date', moment(props.filterPassData.date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['Attendance Report', "Plan vs Actual"]).getCell(2).font = { bold: true };
   worksheet.addRow(['Site', userDetails.org_name]).getCell(2).font = { bold: true };
   worksheet.addRow([]);
   worksheet.addRow(['Attendance Count', data.length - data.filter(el=>el.compliance==="Absent").length ]).getCell(2).font = { bold: true };
   worksheet.addRow([]);
   worksheet.addRow(['Compliance','']).getCell(1).font = { bold: true };
   worksheet.addRow(['Yes', data.filter(el=>el.compliance==="Yes").length]).getCell(2).font = { bold: true };
   worksheet.addRow(['Shift Change', data.filter(el=>el.compliance==="Shift Change").length]).getCell(2).font = { bold: true };
   worksheet.addRow(['No', data.filter(el=>el.compliance==="No").length]).getCell(2).font = { bold: true };
   worksheet.addRow(['Unexpected',data.filter(el=>el.compliance==="Unexpected").length]).getCell(2).font = { bold: true };
   worksheet.addRow(['Absent', data.filter(el=>el.compliance==="Absent").length]).getCell(2).font = { bold: true };

   worksheet.addRow([])



    worksheet.addTable({
      name: sheetName.replaceAll(' ', '_'),
      ref: `A19`,
      columns: [{ name: 'Date' }, { name: 'Name' }, { name: 'Emp ID'}, { name: 'Function'}, { name: 'Check-In (Plan)'}, { name: 'Check-In (Actual)'}, { name: 'Check-Out (Actual)'}, { name: 'Working Hours'},{ name: 'Status'}, { name: 'Complaince'}],
      rows: tableData,
    });
    const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));


    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v?v.toString().length:1);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    });
    table.commit();
  };
      const generateSheetWeekMonth = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet
  let moDiff = getMonths(new Date(props.filterPassData.start_date),new Date(props.filterPassData.end_date),props.filterPassData.report_by)
    const worksheet = workbook.addWorksheet(sheetName);

     worksheet.addImage(logoID, {
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });

    worksheet.addRow([])    
    worksheet.addRow([])    
    worksheet.addRow([])
    worksheet.addRow([])    
    worksheet.mergeCells('A1:B4');    
    worksheet.addRow([])

    const tableData = data.map(item => {
      let newAr= [item.name, item.worker_id];
      for(let i=0;i<moDiff.length;i++){

        if(props.filterPassData.report_by ==="month"){
          newAr.push(
            item[`${moment(moDiff[i].start).format('MMMM')}_total_planned`],
            item[`${moment(moDiff[i].start).format('MMMM')}_total_actual`],
            item[`${moment(moDiff[i].start).format('MMMM')}_yes`],
            item[`${moment(moDiff[i].start).format('MMMM')}_shift_change`],
            item[`${moment(moDiff[i].start).format('MMMM')}_no`],
            item[`${moment(moDiff[i].start).format('MMMM')}_unexpected`],
              item[`${moment(moDiff[i].start).format('MMMM')}_absent`],
            formatTimeWorkHour(item[`${moment(moDiff[i].start).format('MMMM')}_working_hours`]),
            item[`${moment(moDiff[i].start).format('MMMM')}_working_status`],
        
            parseFloat(item[`${moment(moDiff[i].start).format('MMMM')}_compliance`]).toFixed(2)
          )
        }else{
            newAr.push(
            item[`${moDiff[i].weekName}_total_planned`],
            item[`${moDiff[i].weekName}_total_actual`],
            item[`${moDiff[i].weekName}_yes`],
            item[`${moDiff[i].weekName}_shift_change`],
            item[`${moDiff[i].weekName}_no`],
            item[`${moDiff[i].weekName}_unexpected`],
            item[`${moDiff[i].weekName}_absent`],
            formatTimeWorkHour(item[`${moDiff[i].weekName}_working_hours`]),
            item[`${moDiff[i].weekName}_working_status`],
            parseFloat(item[`${moDiff[i].weekName}_compliance`]).toFixed(2)
          )
        }
      }
      return newAr;
    }
      
      
      
      );
    const tableStartRow =  6;
   

    let yes=0;
    let no=0;
    let unexpected= 0;
    let absent= 0;
    let attendance=0
   let compHead=[];
   let compValue=[];
   for(let i=0;i<moDiff.length;i++){
    if(props.filterPassData.report_by =="week"){
      compHead.push(`${moDiff[i].weekName} Yes`)
      compHead.push(`${moDiff[i].weekName} Shift Change`)
      compHead.push(`${moDiff[i].weekName} No`)
      compHead.push(`${moDiff[i].weekName} Absent`)
      compHead.push(`${moDiff[i].weekName} Unexpected`)
      compHead.push(`${moDiff[i].weekName} Total Plan`)
      compHead.push(`${moDiff[i].weekName} Total Actual`)
      compHead.push(`${moDiff[i].weekName} Compliance`)

      
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_yes`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_shift_change`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_no`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_absent`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_unexpected`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_total_planned`]),0))
      compValue.push(data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_total_actual`]),0))
      attendance = attendance +data.reduce((prev,current)=>prev + valueRemoveNull(current[`${moDiff[i].weekName}_total_actual`]),0)
      let avg =0;
      let lengthThing=0;
      avg = data.reduce((prev,current)=>{
        if(current[`${moDiff[i].weekName}_compliance`]===null){
          lengthThing++;
         return prev + 0;  
        }else{
         return prev + valueRemoveNull(current[`${moDiff[i].weekName}_compliance`])
        }
        },0)

      compValue.push(avg/(data.length - lengthThing))
      
    }else{
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Yes`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Shift Change`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} No`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Absent`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Unexpected`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Total Plan`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Total Actual`)
      compHead.push(`${moment(moDiff[i].start).format('MMMM')} Compliance`)

      
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_yes`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_shift_change`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_no`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_absent`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_unexpected`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_total_planned`]),0))
      compValue.push(data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_total_actual`]),0))
      attendance = attendance +  data.reduce((prev,current)=>prev + parseInt(current[`${moment(moDiff[i].start).format('MMMM')}_total_actual`]),0)
      let avg =0;
      let lengthThing=0;
      avg = data.reduce((prev,current)=>{
        if(current[`${moment(moDiff[i].start).format('MMMM')}_compliance`]===null){
          lengthThing++;
         return prev + 0;  
        }else{
         return prev + valueRemoveNull(current[`${moment(moDiff[i].start).format('MMMM')}_compliance`])
        }
        },0)
      
      
      compValue.push(avg/(data.length - lengthThing))
      
    }
    
   }


   worksheet.addRow(['Start Date', moment(props.filterPassData.start_date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['End Date', moment(props.filterPassData.end_date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['Attendance Report', "Plan vs Actual"]).getCell(2).font = { bold: true };
   worksheet.addRow(['Site', userDetails.org_name]).getCell(2).font = { bold: true };
   worksheet.addRow([]);
   worksheet.addRow(['Attendance Count', attendance ]).getCell(2).font = { bold: true };
   worksheet.addRow([]);
   worksheet.addRow(['Compliance','']).getCell(1).font = { bold: true };
  
   const newRow = worksheet.addRow(compHead);
    newRow.eachCell(function(cell, colNumber) {
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor: { argb: 'c5d9f1' }
      };
      cell.font = {
        bold: true,
        color:'fff'
      };
    });
    worksheet.addRow(compValue)

   worksheet.addRow([])

  let column=[{ name: 'Name' }, { name: 'Emp ID'}];

     
      for(let i=0;i<moDiff.length;i++){

        if(props.filterPassData.report_by ==="month"){
          column.push(
            { name: `${moment(moDiff[i].start).format('MMMM')} Planned`},
            { name: `${moment(moDiff[i].start).format('MMMM')} Actual`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Yes)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Shift Change)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (No)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Unexpected)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Absent)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Working Hours)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} (Working Status)`},
            { name: `${moment(moDiff[i].start).format('MMMM')} Compliance`}
          )
        }else{
          column.push(
            { name: `${moDiff[i].weekName} Planned`},
            { name: `${moDiff[i].weekName} Actual`},
            { name: `${moDiff[i].weekName} (Yes)`},
            { name: `${moDiff[i].weekName} (Shift Change)`},
            { name: `${moDiff[i].weekName} (No)`},
            { name: `${moDiff[i].weekName} (Unexpected)`},
            { name: `${moDiff[i].weekName} (Absent)`},
            { name: `${moDiff[i].weekName} (Working Hours)`},
            { name: `${moDiff[i].weekName} (Working Status)`},
            { name: `${moDiff[i].weekName} Compliance`}
          )
         
          

        }
      }


    worksheet.addTable({
      name: sheetName.replaceAll(' ', '_'),
      ref: `A18`,
      columns: column,
      rows: tableData,
    });
    
    const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));


    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v?v.toString().length:1);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength + 2;
    });
    table.commit();
  };


  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};
 
function checkCompliance(planDate, actualDate,name,date) {
    // Convert dates to JavaScript Date objects
    const planDateTime = new Date(planDate);
    const actualDateTime = new Date(actualDate);
    let yestime =  props.filterPassData.yesTime.sort((a,b)=>b - a);
    let notime =  props.filterPassData.noTime.sort((a,b)=>b - a);
    
    // Check if plan date is in the future
    if (planDateTime > new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) {
        return "Awaiting";
    }
    const timeDifference = Math.abs(actualDateTime - planDateTime) / (1000 * 60);
    // Calculate the difference in minutes between plan date and actual date
    

    // Check if the difference is less than or greater than 30 minutes
    
    if (timeDifference <= yestime[0] && timeDifference >= yestime[1]) {
        return "Yes";
    } else if(timeDifference <= notime[0] && timeDifference >= notime[1]) {
        return "No";
    }else{
      return "Shift Change";
    }
}


function valueRenderTime(params,key1,key2){
        var op =document.createElement('div');

        var eGui = document.createElement('div');

      
        
        if(params.data.working_hours  ==="" || (key1 && params.data[key1]==="") ){
          eGui.innerHTML=`<div class="normalGrey"> - </div>`;
        }else if(params.data.working_status === "complied" || (key2 && params.data[key2]==="complied")){
          
          eGui.innerHTML=`<div class="normalGrey">${formatTimeWorkHour(key1?params.data[key1]:params.data.working_hours)}</div>`;
        }
        else if(params.data.working_status === "Short-time" || (key2 && params.data[key2]==="Short-time")){
          eGui.innerHTML=`<div class="redColor">${formatTimeWorkHour(key1?params.data[key1]:params.data.working_hours)}</div>`;
        }else{
          eGui.innerHTML=`<div class="sucessColor">${formatTimeWorkHour(key1?params.data[key1]:params.data.working_hours)}</div>`;
        }
          
      
      return eGui;  
}

function formatTimeWorkHour(timestamp){

  if(timestamp){
    
    const [hours, minutes] = timestamp.split(":").slice(0, 2); // Splitting and taking only hours and minutes
    
    const formattedTime = `${hours.padStart(2, '0')} hr ${minutes.padStart(2, '0')} min`;


    if(hours && minutes){
      return formattedTime;
    }else{
        return " - ";
    }
  
  }
  else{
    return " - "
  }
}





  function AGTablePlanActual(data){    
      
        let arr;
        let date='';
        if(props.filterPassData.report_by=="day"){
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>moment(params.value).format('DD MMM YYYY')}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }

        arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{height:475,maxHeight:475 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            <AgGridColumn
              field="serial"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={80}
              maxWidth={80}
          />
          {date}
          <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn 
              field="function" 
              headerName="Function" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
                        <AgGridColumn 
              field="plan_check_in" 
              headerName="Check-In (Plan)" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
                        <AgGridColumn 
              field="actual_check_in" 
              headerName="Check-In  (Actual)" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>

             {/* checkout  */}
                        <AgGridColumn 
              field="actual_check_out" 
              headerName="Check-Out  (Actual)" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
                        
            {/* working hours */}
            <AgGridColumn 
              field="working_hours" 
              headerName="Working Hour" 
              cellRenderer= {(params)=>valueRenderTime(params)}
              //valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
              <AgGridColumn 
                field="working_status" 
                headerName="Status" 
                valueFormatter={(params) =>params.value?params.value:"-"}    
                cellClass={'textCapitalize textCap'}
                flex={1}
             ></AgGridColumn>

            <AgGridColumn 
              field="compliance" 
              headerName="Compliance" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={(params) =>params.value==="Yes"?'textCapitalize textCap textCapGreen':params.value==="No" || params.value==="Absent"?'textCapitalize textCap textCapRed':'textCapitalize textCap textCapYellow'}
              flex={1}
             ></AgGridColumn>


 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }
  
      function AGTablePlanActualWeekMOnth(dataPlan,empployee){    
    let data=dataPlan

    let moDiff = getMonths(new Date(props.filterPassData.start_date),new Date(props.filterPassData.end_date),props.filterPassData.report_by)
    let widthCal= (props.widthContainer - 60)/11 
    widthCal = widthCal > 120?widthCal:120   
 
        let arr =[];
        arr.push(     <AgGridColumn
              field="serial"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={80}
              maxWidth={80}
          />,<AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              width={170}
          />,
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              width={100}
          />
      )
      
     
      for(let i=0;i<moDiff.length;i++){

        if(props.filterPassData.report_by ==="month"){
          arr.push(<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_total_planned`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} Planned`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
              width={120}
              // max// width={90}
          />,<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_total_actual`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} Actual`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,
          <AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_yes`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Yes)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_shift_change`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Shift Change)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_no`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (No)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
			        width={120}
          />,<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_unexpected`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Unexpected)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,<AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_absent`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Absent)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={(params)=>params.value > 0?"textCap textCapNoDot textCapRed":"textCap textCapNoDot textCapGreen"}
              
			  width={120}
          />,
          <AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_working_hours`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Working Hours)`}
              // valueFormatter={(params)=>params.value?params.value:"-"}
              cellRenderer= {(params)=>valueRenderTime(params,`${moment(moDiff[i].start).format('MMMM')}_working_hours`,`${moment(moDiff[i].start).format('MMMM')}_working_status` )}
              cellClass={(params)=>params.value > 0?"textCap textCapNoDot textCapRed":"textCap textCapNoDot textCapGreen"}
              
			  width={120}
          />,
          <AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_working_status`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} (Status)`}
              // valueFormatter={(params)=>params.value?params.value:"-"}
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,
          
          
          <AgGridColumn
              field={`${moment(moDiff[i].start).format('MMMM')}_compliance`}
              headerName={`${moment(moDiff[i].start).format('MMMM')} Compliance`}
              valueFormatter={(params)=>params.value?params.value.toFixed(2)+"%":"0%"}
              cellClass={(params)=>params.value > 80?"textCap textCapNoDot textCapGreen":params.value > 50?"textCap textCapNoDot textCapOrange":"textCap textCapNoDot textCapRed"}
              
			  width={120}
          />
          )
        }else{
          arr.push(<AgGridColumn
              field={`${moDiff[i].weekName}_total_planned`}
              headerName={`${moDiff[i].weekName} Planned`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
              // width={90}
              // max// width={90}
          />,<AgGridColumn
              field={`${moDiff[i].weekName}_total_actual`}
              headerName={`${moDiff[i].weekName} Actual`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  // width={90}
          />,
          <AgGridColumn
              field={`${moDiff[i].weekName}_yes`}
              headerName={`${moDiff[i].weekName} (Yes)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  // width={90}
          />,<AgGridColumn
              field={`${moDiff[i].weekName}_shift_change`}
              headerName={`${moDiff[i].weekName} (Shift Change)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  // width={90}
          />,<AgGridColumn
              field={`${moDiff[i].weekName}_no`}
              headerName={`${moDiff[i].weekName} (No)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  // width={90}
          />,<AgGridColumn
              field={`${moDiff[i].weekName}_unexpected`}
              headerName={`${moDiff[i].weekName} (Unexpected)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              
			  // // width={90}
          />,<AgGridColumn
              field={`${moDiff[i].weekName}_absent`}
              headerName={`${moDiff[i].weekName} (Absent)`}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={(params)=>params.value > 0?"textCap textCapNoDot textCapRed":"textCap textCapNoDot textCapGreen"}
              
			  // width={90}
          />,
          
           <AgGridColumn
              field={`${moDiff[i].weekName}_working_hours`}
              headerName={`${moDiff[i].weekName} (Working Hours)`}
              // valueFormatter={(params)=>params.value?params.value:"-"}
              cellRenderer= {(params)=>valueRenderTime(params,`${moDiff[i].weekName}_working_hours`,`${moDiff[i].weekName}_working_status`)}
              cellClass={(params)=>params.value > 0?"textCap textCapNoDot textCapRed":"textCap textCapNoDot textCapGreen"}
              
			  width={120}
          />,
          <AgGridColumn
              field={`${moDiff[i].weekName}_working_status`}
              headerName={`${moDiff[i].weekName} (Status)`}
              // valueFormatter={(params)=>params.value?params.value:"-"}
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              
			  width={120}
          />,
          <AgGridColumn
              field={`${moDiff[i].weekName}_compliance`}
              headerName={`${moDiff[i].weekName} Compliance`}
              valueFormatter={(params)=>params.value?params.value.toFixed(2)+"%":"0%"}
              cellClass={(params)=>params.value > 80?"textCap textCapNoDot textCapGreen":params.value > 50?"textCap textCapNoDot textCapOrange":"textCap textCapNoDot textCapRed"}

              
			  // // width={90}
          />
          )

        }
      }
      

        let arrNew=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{height:475,maxHeight:475 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,width:widthCal,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
           {arr} 
      </AgGridReact>
      </div></div>
      
      return arrNew
    
    }



    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
    <div className="App">
    <ToastContainer/>
    <Row>
      <Col lg="5"><h4>Attendance Report:  <span style={{color:"#ef5e8c"}}>Plan Vs Actual</span></h4></Col>
      
      <Col lg="7">
                      <div className="boxStatsSmall">
            <div >
              <Row className="boxStatSmallHeader" style={{margin:0}}> 
                <Col lg={2} className="borderRightDashed" >Plan (Atd.)</Col>
                <Col lg={2} className="borderRightDashed">Actual (Atd.)</Col>
                <Col lg={1} className="borderRightDashed">Yes</Col>
                <Col lg={2} className="borderRightDashed">Shift Change</Col>
                <Col lg={1} className="borderRightDashed">No</Col>
                <Col lg={1} className="borderRightDashed">Absent</Col>
                <Col lg={1} className="borderRightDashed" >Unexpected</Col>
                <Col lg={2} className="borderRightDashed" style={{borderRight:0}}>Compliance</Col>
              </Row>
            </div>
            <div >
              <Row className="boxStatsContent" style={{margin:0}}> 
                <Col lg={2} className="borderRightDashed" >{reportHeader.plan_attendance?reportHeader.plan_attendance:0}</Col>
                <Col lg={2} className="borderRightDashed" >{reportHeader.attendance?reportHeader.attendance:0}</Col>
                <Col lg={1} className="borderRightDashed" >{reportHeader.yes?reportHeader.yes:0}</Col>
                <Col lg={2} className="borderRightDashed" >{reportHeader.shift_change?reportHeader.shift_change:0}</Col>
                <Col lg={1} className="borderRightDashed" >{reportHeader.no?reportHeader.no:0}</Col>
                <Col lg={1} className="borderRightDashed" >{reportHeader.absent?reportHeader.absent:0}</Col>
                <Col lg={1} className="borderRightDashed" >{reportHeader.unexpected?reportHeader.unexpected:0}</Col>
                <Col lg={2} className="borderRightDashed" style={{borderRight:0}}>{reportHeader.compliance?reportHeader.compliance:0} %</Col>
                
              </Row>
            </div>
          </div>
      </Col>

    </Row>
    

      {props.filterPassData.report_by==="day"?AGTablePlanActual(tableData):AGTablePlanActualWeekMOnth(plannedEmp,employee)}

    </div>:""
    )

}