import React, { useState, useCallback,useReducer,useEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from './Viewer'
import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, type
} from 'ramda'
import moment from "moment";
import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from '../../assets/images/location.png'
import ManIcon from '../../assets/images/regularworker-purple.png'
import CHristMasManIcon from '../../assets/images/dailyworker-yellow.png'
import DefaultManIcon from '../../assets/images/deafutManIconSmall.png'
// import mqttService from '../../components/mqttService';

import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";

import ManProductiveIcon from '../../assets/images/man.png'
import ManLessProductiveIcon from '../../assets/images/dailyworker-yellow.png'
import ManNonProductiveIcon from '../../assets/images/nonProductiveMan.png'

import { getCategoryList,getLocationType} from '../actionMethods/actionMethods'

import {getLocationCordinate} from '../actionMethods/actionMethods';
//import {getEmployeeList} from '../../manPowerManagement/actionMethods/actionMethods'
import { getDepartment,getSubDepartment } from '../../productivity/actionMethods/actionMethods';

import {getPproductivitylist} from '../../productivity/actionMethods/actionMethods'
import {getAllEmpProductivityInfo,getCycleCountPlan,getLiveData,getOnBoardEmployee} from '../actionMethods/actionMethods';
import { getRoleDetail } from '../../common/actionMethods';
import {ReactComponent as Eye} from '../../assets/images/eye.svg';
import ReactModal from 'react-modal';

import Scrollbars from 'react-custom-scrollbars';

import '../style/style.scss'
import { Select } from 'antd';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg';

import CMGBulky from '../../assets/cmg_bulky.json'
import CMGManual from '../../assets/cmg_manual.json'





let dataSocket=[];   
//let dataSocketAll=[];   
let batch30=[];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


const mystyle = {
  display: "none !important",
 
};
const FacilitiesTracker = (props) => {

  const { Option } = Select;
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ]);



  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [locationListDefault,SetLocationListDefault] =  useState([]);
    const [reloadInc,SetReloadInc] = useState(0);
  
  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({start:moment.utc(),end:moment.utc()});
  const [productiveType,SetProductiveType] = useState([]);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

    useEffect(()=>{
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": parseInt(props.workday.start_hour.split[0]) || 7, "minute": parseInt(props.workday.start_hour.split[1]) || 0});
    
      let dt={start:null,end:null}
      if(timeAndDate <= moment() ){        
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(addDay,'days').toDate()})); 

      }else {  
        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(addDay,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));
      }

      getLocationType(userSession).then(res=>{
        if(res.status===200){

          let obj = {};
          obj.productive= res.data.find(el=>el.loc_type=="productive").id
          obj.less_productive= res.data.find(el=>el.loc_type=="less_productive").id
          obj.non_productive= res.data.find(el=>el.loc_type=="non_productive").id

          SetProductiveType(obj);
        }
      })

    },[])

  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space,setSpace] = useState()


  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  // let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:4;
  // let GeoElevation= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?3:5;
    let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?3:5;
  
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";

  let userSession = userDetails ? userDetails.session:props.match.params.session?props.match.params.session:'';
  let org_id = userDetails ? userDetails.org_id : props.match.params.org_id?props.match.params.org_id:'';
  const role = props.match.params.role?props.match.params.role:null;
  
  const canvasRange = {
    topRight: { x: 91.01915303072015, z: 28.32575004981593 },
    topLeft: { x: -35.2750555742273, z: 23.835903322863807 },
    bottomLeft: { x: -16.32471441542863, z: -51.90536740912293 },
    bottomRight: { x: 91.01915303072015, z: -51.90536740912293 }
  };

  const [client,SetClient] =useState(null)
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [cameraPlacement,SetCameraPlacement] =useState([]);
  
  const [employeeALL,SetEmployeeAll] =useState([]);
  const [employeeDefault,SetEmployeeDefault] =useState([]);

  const [department,SetDeparment] = useState([]);
  const [sub_department,SetSubDeparment] = useState([]);

  const [activeDepartment,SetActiveDepartment] = useState([]);
  const [activeSubDepartment,SetActiveSubDepartment] = useState([]);

  const [activeType,SetActiveType] = useState('');
  const [apiCall,SetAPICall]=useState(false)
  const [activeAccord,SetActiveAccord] =useState([]);
  const [locationList,SetLocationList] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [slug,SetSlug]=useState('');

  const [modalView,SetModalView]=useState(false);
  const [modalData,SetModalData ]= useState([]);
  const [mapData,SetMapData]=[
                      {name:"Geo Map 1",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 2",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 3",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'},
                      {name:"Geo Map 4",id:'59a74b5c-374a-4ef2-a5c3-63f3aa2b220f'}
                    ]
                    
  const [cycleCount,SetCycleCount]=useState([]);
  const [inc,SetInc]=useState(0)
  const [time,SetTime] =useState('');      
  const [delayTimer,SetDelayTimer]= useState(false);
  const [departmentName,SetDepartmentName]=useState([]);
  const [subDepartmentName,SetSubDepartmentName]=useState([]);


  useEffect(()=>{ 
    
    let requestBody2={};
    requestBody2.session=userSession
    requestBody2.org_id=org_id

    getCategoryList(requestBody2).then(res2=>{
     

        if(res2.status == 200){
          let arrCat=res2.data;
          let requestBody={}
          requestBody.session= userSession;
          requestBody.org_id= org_id;
          let arr=[];

        for(let i=0;i<res2.data.length;i++){

          if(res2.data[i].location.length > 0){
            let loc = res2.data[i];
            loc.count = res2.data[i].location.length;
            arr.push(loc);
          }


          

        }

        SetLocationList(arr); 
        SetLocationListDefault(arr)
        
        }else{
            SetLocationList([]);                
            SetLocationListDefault([])
        }

    SetLoader(false);

})


},[])  
  
function convertToSmplrSpace(lat, lng) {
    // Google Maps Bounding Box
    const lat_min = -6.155318944846295;
    const lat_max = -6.153364216604151;
    const lng_min = 106.61700560713194;
    const lng_max = 106.61879195833586;

    // Smplrspace Bounding Box
    const x_min = 12.116823874598005;
    const x_max = 102.5793435507427;
    const z_min = -71.2673595363475;
    const z_max = -14.306596389661806;

    // Linear transformation formula
    const x_smplr = ((lat - lat_min) / (lat_max - lat_min)) * (x_max - x_min) + x_min;
    const z_smplr = ((lng - lng_min) / (lng_max - lng_min)) * (z_max - z_min) + z_min;

    return { x: x_smplr, z: z_smplr };
}

  const timerLive = ()=>{
    SetTime(moment().format('HH:mm:ss'));
  }
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }
    const timerAPILiveData = (emp,type)=>{
    
    SetDelayTimer(true)
    dataSocket=[];
    
    let uniq=0;
    let rep=0;
    let singleTag=[]
    let dataAll=[]
    let dataUnique=[];
    addPointProductive([])
    addPointLessProductive([]);
    addPointNonroductive([])
addPointDeafult([])
    getLiveData(userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
    // res=LIVEDATA
dataSocket=[];
  if( res.data){
    SetDelayTimer(false)

    //removing old data;
   
    dataAll = res.data.map(item=> JSON.parse(item.toString().replace(/'/g, '"') ));
    let dataID=[]
    for(let i=0;i<dataAll.length;i++){
      if(dataID.indexOf(dataAll[i].tag_id) === -1){
        dataUnique.push(dataAll[i]);
        dataID.push(dataAll[i].tag_id);
      }
    }
    
    dataUnique = dataUnique.map((data,index)=>{
      
      let formatAr={}

        let mm = employeeALL.find(item=>item.tag_serial.trim() == data.tag_id.trim());
        let pos = locationCordinate.find(item=>item.tag_serial == data.contact_tag_id)

     if(mm){
          let productive=[];
            if(activeType==''){
                 
            }else{
              productive = mm.loc_type?mm.loc_type[0][activeType]:[];
             }

        formatAr.id= randomString(32);
        formatAr.type= 'add';
        formatAr.name = mm && mm.name?mm.name: data.tag_id
        formatAr.emp_type = mm && mm.emp_type?mm.emp_type: 'daily'
        formatAr.loc_name = pos && pos.name?pos.name:"";
        if(activeType==''){

          if(pos){

         // let prod = mm.loc_type?mm.loc_type[0]['productive']:[];
          //let nonprod = mm.loc_type?mm.loc_type[0]['non_productive']:[];
          //let lessprod = mm.loc_type?mm.loc_type[0]['less_productive']:[];
          formatAr.location_id =pos.location_id;
          
          if(parseInt(userDetails.org_id)== 31 || parseInt(userDetails.org_id)== 32){
            formatAr.position = {
                "levelIndex": pos?pos.position.levelIndex:0,
                "x": pos?pos.position.x + ((0.5*(Math.random() - 0.4) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.4) * 20))),
                "z": pos?pos.position.z +((0.7*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
                "elevation": 2.2
          }

          }else{
            formatAr.position = {
              "levelIndex": pos?pos.position.levelIndex:0,
              "x": pos?pos.position.x + ((0.55*(Math.random() - 0.55) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.55) * 20))),
              "z": pos?pos.position.z +((1*(Math.random() - 0.6) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 1) * 10))),
              "elevation": 2.2
            }

          }

         

          //   formatAr.position = {
          //   "levelIndex": pos?pos.position.levelIndex:0,
          //   "x": data.x,
          //   "z": data.z,
          //   "elevation": 2.2
          // }
          
          
          formatAr.time= data.contact_time; 
          formatAr.rssi= data.rssi; 
          formatAr.tag_serial= data.tag_id;
          formatAr.contact_tag_id= data.contact_tag_id;         
          formatAr.productivity = data.productivity; 

      }else{
        console.log("pos is undefined",mm,data.contact_tag_id)
      }


        }else{

          let check= productive.find(item=>item.tag_serial == data.contact_tag_id)
          if(check){
            let pos = locationCordinate.find(item=>item.tag_serial == data.contact_tag_id)
       
            if(pos){
            formatAr.location_id =pos.location_id;
  
  //old location          
            formatAr.position = {
              //"levelIndex": 0,
              "levelIndex": pos?pos.position.levelIndex:0,
              // "x": pos?pos.position.x + ((0.5*(Math.random() - 0.5) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.5) * 20))),
              // "z": pos?pos.position.z +((0.5*(Math.random() - 0.5) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.5) * 10))),
              "x": pos?pos.position.x + ((0.55*(Math.random() - 0.55) * 20)):(11.205211986727418 + ((0.111*(Math.random() - 0.55) * 20))),
              "z": pos?pos.position.z +((0.55*(Math.random() - 0.55) * 10)):(-9.236610745591577+ ((0.1*(Math.random() - 0.55) * 10))),
              "elevation": 2.2
            }

          // formatAr.position = {
          //   "levelIndex": pos?pos.position.levelIndex:0,
          //   "x": data.x,
          //   "z": data.z,
          //   "elevation": 2.2
          // }

            
            formatAr.productivity = data.productivity; 
            formatAr.time= data.contact_time; 
            formatAr.rssi= data.rssi; 
            formatAr.tag_serial= data.tag_id; 
  
            //dataSocketAll.push(formatAr);
            
            formatAr.type=activeType
            
          
        }

          }

        }
      }else{
        console.log("why skipped not present",data.tag_id)
      }
      
      // else{
      //   formatAr.condition="Else Condition daily"
      //   formatAr.tag_serial=data.tag_id

      // }

          
        

dataSocket.push(formatAr);



return formatAr;

    })

    dataSocket =dataSocket.filter(item=>item.position);
   
    
  }
  //here all testing code  
  /*
  dataSocket= [];
  let testPlot =[
	{"lat":-6.154848,"lng":106.618638,"name":"Inbound Area_1"},		
	{"lat":-6.154876,"lng":106.618660,"name":"Entrance Gate"},	
	{"lat":-6.154903,"lng":106.618618,"name":"Inbound Area_2"},	
	{"lat":-6.154875,"lng":106.618612,"name":"Unloading Area"},		
	{"lat":-6.154955,"lng":106.618510,"name":"OB Intracity"},
	{"lat":-6.155015,"lng":106.618505,"name":"Loading Intracity"},
	{"lat":-6.155050,"lng":106.618453,"name":"OB Intercity"},
	{"lat":-6.155099,"lng":106.618447,"name":"Loading Intercity"},
	{"lat":-6.155056,"lng":106.618217,"name":"Stagging OB Intercity"},	
	{"lat":-6.154956,"lng":106.618242,"name":"Stagging OB Airfreight"},
	{"lat":-6.154905,"lng":106.618274,"name":"Sort SPX"},
	{"lat":-6.154856,"lng":106.618402,"name":"Incoming _1"},	
	{"lat":-6.154823,"lng":106.618394,"name":"Incoming _2"},	
	{"lat":-6.154773,"lng":106.618473,"name":"Incoming_3"},
	{"lat":-6.154786,"lng":106.618553,"name":"Stagging OB Intracity"},
	{"lat":-6.154758,"lng":106.618543,"name":"Outgoing_1"},
	{"lat":-6.154639,"lng":106.618570,"name":"Outgoing_2"},
	{"lat":-6.154572,"lng":106.618427,"name":"Supplies ATK Assets"},
	{"lat":-6.154452,"lng":106.618491,"name":"Prayer room"},	
	{"lat":-6.154464,"lng":106.618511,"name":"Toilet"},		
	{"lat":-6.154592,"lng":106.618403,"name":"Outgoing_3"},
	{"lat":-6.154818,"lng":106.618537,"name":"DG"},
	{"lat":-6.154723,"lng":106.618370,"name":"Corporate (DE)"},
	{"lat":-6.154804,"lng":106.618167,"name":"Seafreight"},
	{"lat":-6.154859,"lng":106.618118,"name":"Swift"},
	{"lat":-6.154985,"lng":106.618024,"name":"RTS"},
	{"lat":-6.154741,"lng":106.618656,"name":"Dex and Hold"},
	{"lat":-6.154819,"lng":106.618697,"name":"Locker room"}
]




 for(let i=0;i<testPlot.length;i++){
  let cord = convertToSmplrSpace(testPlot[i].lat,testPlot[i].lng)
  let single={
      "id": randomString(32),
      "type": "add",
      "name": testPlot[i].name,
      "emp_type": "regular",
      "loc_name": testPlot[i].name,
      "location_id": 376,
      "position": {
        "levelIndex": 0,
        "x": cord.x,
        "z": cord.z,
        "elevation": 2.2
      },
      "time": "2024-06-14 07:18:00",
      "tag_serial": "01DB1D3ED2BB0C",
      "contact_tag_id": "02C049EF046694",
      "productivity": 3
      }
  dataSocket.push(single)
} 

for(let i=0;i<locationCordinate.length;i++){
    let single={
      "id": locationCordinate[i].unique_loc_id,
      "type": "add",
      "name": locationCordinate[i].name+" Actual",
      "emp_type": "daily",
      "loc_name": locationCordinate[i].name,
      "location_id": locationCordinate[i].location_id,
      "position": locationCordinate[i].position,
      "time": "2024-06-14 07:18:00",
      "tag_serial": locationCordinate[i].tag_serial,
      "contact_tag_id": "02C049EF046694",
      "productivity": 2
      }
  dataSocket.push(single)
}

*/

  addPointProductive(dataSocket.filter(item=>item.productivity == type['productive']))
  addPointLessProductive(dataSocket.filter(item=>item.productivity == type['less_productive']));
  addPointNonroductive(dataSocket.filter(item=>item.productivity == type['non_productive']));
  addPointDeafult(dataSocket.filter(item=>item.productivity == 0));

    })
  }
  

  let lastReqId = null

    function errorHandler(message){

    }

    function SubscribeHandler(message){

    }
    useEffect(() => {
      let pagePerm= props.pages.filter(el=>el.page_name=="Geo Map Management")

      SetPermission(pagePerm);
      SetPermissionAPICall(true)
      
    },[props.pages]);


  function AccordionHandler(val,type='secondary'){
    let ActivList = [...activeAccord];
    let index = ActivList.indexOf(val)
    if(index !== -1){  
        ActivList.splice(index, 1);
        SetActiveAccord(ActivList);      
    }
    else if(type=='primary'){
        SetActiveAccord([val]);
 }     
    else {  
        ActivList.push(val);
        SetActiveAccord(ActivList);      
    }
    
}

  useEffect(()=>{
    if(permission.length > 0){
      let geoLocation=[];
      getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){

        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }

          
 
          getCycleCountPlan(userSession,org_id,moment().utc().format('YYYY-MM-DD')).then(res2=>{
              if(res2.status==200){
                  
                for(let i=0;i<res.data.location.length;i++){
                    let ctPlan = moment().format('HH');
                  
                      let data = res2.data[res.data.location[i].tag_serial]
                      data =  data?data.find(item=>item.hour == ctPlan+":00"):undefined; 
                      let result =0;
                      if(data){
                          res.data.location[i].planned = Math.ceil(data.cycle_count);
                      }else{
                        res.data.location[i].planned = '-';
                      }
                      //res.data.location[i].planned = GetRandomDigit(7,15);
                  }
                  
                  if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                    SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
                    for(let i=0;i<CMGBulky.length;i++){
                      let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
                      // ("CMG Bulky",CMGBulky[i],dataFind,res.data.location)
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }

                    }
                  }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                      SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
                      for(let i=0;i<CMGManual.length;i++){

                      let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }
                    }

                  }else{
                    SetSpaceID(res.data.space_id)  
                    geoLocation = res.data.location
                  }
                    // SetSpaceID(res.data.space_id)  
                    // geoLocation = res.data.location

                  SetLocationCordinate(geoLocation);
              }else{
               
                for(let i=0;i<res.data.location.length;i++){
                    res.data.location[i].planned = '-';
                  }
                  SetLocationCordinate(res.data.location);
              }
            })
        }
      })

      getDepartment(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          SetDeparment(res.data);  
        }
      });
      
      SetLoaderEmp(true);
      

      //here is api call
      //without api
    //   getAllEmpProductivityInfo(getDateFormat(selectedDate),getDateFormat(selectedDate),userSession,org_id,'employee').then(res=>{
    //  // let res= productivityEmployee;
    //     SetLoaderEmp(false);
    //     if(res.status==200){
    //       SetEmployeeAll(res.data);
    //       SetEmployeeDefault(res.data)
    //     }
    //   })

    }

  },[permission])   

useEffect(()=>{
    if(permission.length > 0){
      addPointProductive([])
    addPointLessProductive([]);
    addPointNonroductive([]);
    addPointDeafult([]);

   let requestBody={}
    requestBody.start_date= moment().subtract(1,'days').format('YYYY-MM-DD')
    requestBody.end_date=   moment().format('YYYY-MM-DD')
    //requestBody.slug= activeEmp;
    requestBody.slug= 'employee';
    let cat=[];
    let catDaily=[];
    

          let empList= [];
          
     SetLoaderEmp(true);
     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{

      // res=ONBOARD
      if(res.status==200){
        if(res.data.daily){
          res.data.daily = res.data.daily.filter(item=>item.check_out == null)
        for(let i=0;i<res.data.daily.length;i++){
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].department= res.data.daily[i].department_name;
            res.data.daily[i].dept_name= res.data.daily[i].department_name;
            res.data.daily[i].sub_dept_name= res.data.daily[i].sub_department_name;
            res.data.daily[i].sub_department= res.data.daily[i].sub_department_name;          
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].emp_type= "daily_worker";
            empList.push(res.data.daily[i]);
        }

        }

                if(res.data.regular){
          res.data.regular = res.data.regular.filter(item=>item.check_out == null)

          for(let i=0;i<res.data.regular.length;i++){
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].department= res.data.regular[i].department_name;
            res.data.regular[i].dept_name= res.data.regular[i].department_name;
            res.data.regular[i].sub_dept_name= res.data.regular[i].sub_department_name;
            res.data.regular[i].sub_department= res.data.regular[i].sub_department_name;          
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].emp_type= "regular";
            empList.push(res.data.regular[i]);
        }
      }
            SetEmployeeDefault(empList);
            SetEmployeeAll(empList);

            SetLoaderEmp(false);
      }
     })

    }
},[permission,reloadInc])




useEffect(()=>{

  if(!space){
    return
  }
  SetActiveSubDepartment([]);
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';  
  
  let emp = [...employeeDefault].filter(item=>item.emp_type.toString().includes(type)); 
  if(departmentName.length > 0){
  let filterEmp = [...employeeDefault].filter(item => departmentName.indexOf(item.department_name) > -1 ); 
  dataSocket=[];
 
  SetEmployeeAll(filterEmp);
 
  SetSubDeparment([]);     
  }else{
   
    SetEmployeeAll(employeeDefault);
   
  }

  if(activeDepartment.length== 1){
  getSubDepartment(userSession,activeDepartment[0],org_id,sub_org_id).then(res=>{
    if(res.status==200){
      SetSubDeparment(res.data);
    }
  })
 
}

//SetEmployeeAll(filterEmp);
removeFrame()

 
},[activeDepartment,departmentName,employeeDefault])



useEffect(()=>{
  if(!space ||  departmentName.length == 0){
    return;
  }
  
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';

  if(activeSubDepartment=='all'){
    let filterEmp = [...employeeDefault].filter(item => 
        departmentName.indexOf(item.department_name) > -1  
      )  

dataSocket=[];
    SetEmployeeAll(filterEmp);
  }
  else if(activeSubDepartment.length == 0){
  let filterEmp = [...employeeDefault].filter(item => departmentName.indexOf(item.department_name) > -1 ); 
  dataSocket=[];
  SetEmployeeAll(filterEmp);
    
  }else{
    let filterEmp = [...employeeDefault].filter(item => 
        subDepartmentName.indexOf(item.sub_department) > -1 && item.emp_type.toString().includes(type)
      )  
    SetEmployeeAll(filterEmp);
   
  }
  removeFrame()
 
  

},[activeSubDepartment,subDepartmentName,employeeDefault,departmentName])

function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}


useEffect(()=>{

  SetAPICall(false)
  removeFrame()

},[employeeALL])



  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }



  useEffect(()=>{
      if(permission.length > 0){

        SetTime(moment().format('HH:mm'));
      if (!space) {
        return
      }
     // SetClient(mqttService.getClient(errorHandler));
    }

  

    },[space,permission])
    
    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
    function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  const onReady = useCallback(space => setSpace(space), [])

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:

    }
  },[]/*[
    {
      "id": "45f8d5d7-485d-5fe3-87d4-ec83ad282178",
      "name": "12 PM",
       "name":"Loc 1",
      "tooltip": '12 PM',
      "position": {
        "levelIndex": 0,
        "x": 5.345798104140962,
        "z": -7.660746541830819,
        "elevation": 0.0020000000950055608
      },
      "color":'#000'
    }]
    */)

  const [points, dispatchPoint] = useReducer((points, action) => {
      
    switch (action.type) {
      case 'add':
        return [...points, action.point]
      case 'update':
        return points.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(points)
      default:

    }
    
  },[])
  

function showTooltip(data, event) {
  // Check if event is defined
  
  if (!event) {
    return;
  }

  const tooltip = document.createElement('div');
  tooltip.className = 'custom-tooltip';
  tooltip.innerHTML = data.name;

  // Calculate the tooltip position based on the click event
  const tooltipX = event.clientX;
  const tooltipY = event.clientY;

  // Set the tooltip position
  tooltip.style.left = `${tooltipX}px`;
  tooltip.style.top = `${tooltipY}px`;

  // Append the tooltip to the body or a specific container
  document.body.appendChild(tooltip);

  // Remove the tooltip after a certain duration or user interaction
  setTimeout(() => {
    tooltip.remove();
  }, 3000); // Adjust the duration as needed
}
function getWindowPosition() {
  
  return {
    x: window.scrollX || window.pageXOffset,
    y: window.scrollY || window.pageYOffset,
  };
}


  function addPointProductive(points){
  
  console.log("productive points",points)
    if(space){
      
      space.addDataLayer({
        id: 'points',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name ,

        onClick: (d) => showTooltip(d,window)
        
      
        
        //tooltip: d => d.name + " : "+ moment(d.time).format("HH:mm:ss"),
  
      })
    }

  }

    function addPointLessProductive(points){
console.log("Less productive points",points)
    if(space){
      space.addDataLayer({
        id: 'points2',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManLessProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,

        onClick: (d) => showTooltip(d, window)
        //  tooltip: d => d.name + " : "+ moment(d.time).format("HH:mm:ss"),
  
      })
    }

  }

   function addPointNonroductive(points){
console.log("non productive points",points)
    if(space){
      space.addDataLayer({
        id: 'points3',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:ManNonProductiveIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,

        onClick: (d) => showTooltip(d, window)
        //  tooltip: d => d.name + " : "+ moment(d.time).format("HH:mm:ss"),
  
      })
    }

  }
    function addPointDeafult(points){
    console.log("default productive points",points)
    if(space){
      space.addDataLayer({
        id: 'points4',
        type: 'icon',
        data: autoElevation(points),
        icon:  {
          url:DefaultManIcon,
          width: 30,
          height: 50,
        },
        width: GeoWidth,
        anchor: 'bottom',
        tooltip: d => d.name,

        onClick: (d) => showTooltip(d, window)
        //  tooltip: d => d.name + " : "+ moment(d.time).format("HH:mm:ss"),
  
      })
    }

  }

   const [currentCount, setCount] = useState(0);

  useEffect (() => {
    if(permission.length == 0){
      return
    }

    if (!space) {
      return
    }
 
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: autoElevation(icons),
      icon: {
        url: LocationIcon,
        width: 96,
        height: 96
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => d.name,
      onDrop: ({ data, position }) =>
        dispatchIcon({
              type: 'update',
              id: data.id,
              updates: { position }
            })
    })

  }, [space, icons, autoElevation,permission])

  



  const removeFrame = () => {
    if(!space){
      return
    }
    let data=[...dataSocket];

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
             dispatchPoint({ type: 'remove', id: dt.id });
              //space.removeDataLayer(data[i].id); 
              dataSocket.splice(i,1)
          }
          
         }
      //  for(let i=0 ;i<locationCordinate.length;i++){
      //   updateDataLayer(locationCordinate[i],DefaultIcon);
      //  }
       dataSocket=[];

  }
  
  function updateDataLayer(data,url){


    if(space){

    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: 96,
          height:96
        },
        width: GeoWidth,
        anchor: 'top',
        //tooltip: data => data.name,
      })    
    }
  }

  }


  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: data.width,
        height: data.width
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.name,
    })
  }
}

useEffect(() => {
  if(!space){
    return 
  }
  dataSocket = []
  timerAPILiveData(employeeALL,productiveType);
  const id3 = setInterval(timerLive, 1000);

return () =>{
  clearInterval(id3);
  }   
},[space,employeeALL,productiveType]);
  
 
      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
    
    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);
    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }

    function ModalFormatData(hourData,location_id){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea'> 
         <h4>{location_id.toLowerCase()}  <button className='alignRightFixed' onClick={()=>SetModalView(false)}>x</button></h4>
        </div>
      )

      let inner =[];      
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_serial );
      inner.push(emp)   
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }
    function ModalFormatcategory(hourData,categoryname){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea'> 
         <h4>{categoryname.toLowerCase()}<button className='alignRightFixed' onClick={()=>SetModalView(false)}>x</button></h4>
        </div>
      )

      let inner =[];   

      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_serial );


      inner.push(emp)   
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }


    function ModalFormatLocation(hourData){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea'> 
         <h4>Total employees<button className='alignRightFixed' onClick={()=>SetModalView(false)}>x</button></h4>
        </div>
      )

      let inner =[];   

      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_serial );
      inner.push(emp)   
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      //return 'hello';

    }

    function onChangeDepartment(val){
    let nameVal=[]
      dataSocket=[]
      if(val.indexOf('all')> -1){
          let value =[];

          for(let i=0;i<department.length;i++){
            value.push(department[i].department_id)
            nameVal.push(department[i].name)
          }
          if((val.length -1) == value.length ){
            SetActiveDepartment([]);  
            SetDepartmentName([])
          }else{
            SetDepartmentName(nameVal)
            SetActiveDepartment(value);
            
          }
    
          
      }else{
        
        nameVal = val.map(item=>department.find(el=>el.department_id == item)?.name)
        SetDepartmentName(nameVal)
        SetActiveDepartment(val);
      }


    }

    function onChangeSubDepartment(val){
      //val=[val] 
        dataSocket=[]
    let nameVal=[]
      if(val.indexOf('all')> -1){
        let value =[];
        for(let i=0;i<sub_department.length;i++){
          value.push(sub_department[i].sub_department_id)
          nameVal.push(sub_department[i].sub_dept_name)
        }
        if((val.length -1) == value.length ){
        
          SetSubDepartmentName([])                    
          SetActiveSubDepartment([]);  
        }else{
        
          SetSubDepartmentName(nameVal)
          SetActiveSubDepartment(value);

        }

    
        
    }else{
      nameVal = val.map(item=>sub_department.find(el=>el.sub_department_id == item)?.sub_dept_name);

      
       SetSubDepartmentName(nameVal)
      SetActiveSubDepartment(val);
    }

    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }

    function colsValueFormatter(params){
      var eGui = document.createElement('div');
        
      eGui.innerHTML = '<div><div class="titleFont">'+params.data.emp_name+'</div> <span class="smallerfont smallerfontDepartment">'+params.data.dept_name+'</span> <span class="smallerfont">'+params.data.sub_dept_name+'</span> </div>';
                     return eGui;          
      
    }

    function AGTable(data){

    
      if(data  && window.innerWidth > 767){
        
        let widthCal= 0;
    
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100 - 205)/4) ;
        }else{
          widthCal=(( (window.innerWidth * 80)/100) - 205)/4 ;
        }
        let arr;

        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'emp_name',
      
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
                onCellClicked={ (event) =>
              {
               
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.worker_id}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.worker_id}`, "_blank")

                }
              }
                }

              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
  
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
                      <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="dept_name" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_dept_name"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_dept_name"
             headerName="View"
             width={80}
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else if(data  && window.innerWidth < 767){
      let widthCal= 0;
    
        widthCal=window.innerWidth - 200/1 ;
      let arr;

      arr=<div className={"keyAGTable keyAGTableResponsiveBiggerHeight"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
        <AgGridReact
            rowHeight={45}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
  
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
            //pagination={true}
            rowData={data}
            key={'keyAGTable'}
            onCellClicked={ (event) =>
              {
            
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.tag_serial}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.tag_serial}`, "_blank")

                }
              }
                }
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >
          <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>params.value.toLowerCase()}
            cellRenderer= {(params)=>colsValueFormatter(params)}
            cellClass={'textCapitalize textCap'}
        />
        <AgGridColumn
           field="sub_dept_name"
           headerName="View"
           width={80}
           cellClass={'textCapitalize textCap'}
           cellRenderer= {(params)=>viewIconRenderer(params)} >
          </AgGridColumn> 
    </AgGridReact>
    </div></div>
    
    return arr
    }else{
      return ;
    }
    
    }

    function modalViewShow(loc){

      let data = dataSocket.filter(item=>item.location_id==loc.id);
      let arr =[];
      arr = ModalFormatData(data,loc.location_name);
     
     SetModalData(arr);
     SetModalView(true)
     
    }

    function categorymodalview(Category_data){
      
      let arr =[];
      for(let i=0;i<Category_data.location.length;i++){
     
        let data = dataSocket.filter(item=>item.location_id == Category_data.location[i].id);
       
        for(let j=0;j<data.length;j++){
          arr = [...arr, data[j]]
        } 
      }
      let arr1=[]
     arr1 = ModalFormatcategory(arr,Category_data.name);  
       SetModalData(arr1);
       SetModalView(true)
    }


    function categorymodalview1(location) {

    
      const arr = location.reduce((acc, loc) => {
        const data = dataSocket.filter(item => item.location_id === loc.location_id);
        return [...acc, ...data];
      }, []);
    
      const arr1 = ModalFormatLocation(arr);
      SetModalData(arr1);
      SetModalView(true);
    }
    
   



     function changeGeoMapSpace(val){

      space.remove();
      SetSpaceID(val);



     }
     function locNameRender(params){
  
      var op =document.createElement('div');

      var eGui = document.createElement('div');
    
      eGui.innerHTML= '<div class="elementCell textCap">'+params.value.toLowerCase()+'</div>';

return eGui;

          
  }

  function ShowCardListLocation(){
    let data =[...locationList];
    //condition for filter org and sub org before passing

    // if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){

    // }else if(parseInt(org_id) == 30 && parseInt(sub_org_id)==2){

    // }else{
    //   data = [...locationList]
    // }
    
    let arr=[];
   
    

    if(data.length>0){
      for (let i = 0; i < data.length; i++) {
      
        let index = i + 1;
        // const plannedCount = data[i].location.reduce((acc, loc) => {
        //   const locData = locationCordinate.find(
        //     (cord) => cord.location_id === loc.id && cord.name === loc.location_name
        //   );
        //   const planned = locData && locData.planned ? locData.planned : locData.planned
        //   return acc + planned;
        // }, 0);
              const plannedCount = data[i].location.reduce((acc, loc) => {

                const locData = locationCordinate.find(
                  (cord) => cord.location_id === loc.id && cord.name === loc.location_name
                );

                const planned = locData && locData.planned  && locData.planned!='-'? parseInt(locData.planned) : 0;
                return acc + planned;
              }, 0);

              const validPlannedCount = isNaN(plannedCount) ? 0 : plannedCount;

              arr.push(                 
                // <Row  key={"contentCategory"+data[i].name.toLowerCase()} className={activeAccord.indexOf(data[i].name)!==-1?'contentCategory contentGroup active':'contentCategory contentGroup'}>
                <Row key={"contentCategory" + data[i].name.toLowerCase()}className={`contentGroup${activeAccord.indexOf(data[i].name) !== -1 ? ' active' : ''}${data[i].location && data[i].location.length > 0 ? ' contentCategory' : ''}`}>
                  <div className={`Category-header group-header`}> 
                    {/* <Col lg={3} className="align-middle align-wrapper">
                      {data[i].location.length >= 0 ?<span onClick={ ()=>AccordionHandler(data[i].name,'primary')} >
                        {activeAccord.indexOf(data[i].name)!==-1?<CircleArrowDown style={{height:15}}/>:<CircleArrow  style={{height:15}}/>}</span>:""}
                      <h4 style={{fontSize:9, margin: 0,marginLeft:8}}>{data[i].name.charAt(0).toUpperCase() + data[i].name.slice(1)}</h4>
                     
                    </Col> */}
                            <Col lg={3} className={`align-middle align-wrapper`}>
            {data[i].location && data[i].location.length > 0 && (
              <span onClick={() => AccordionHandler(data[i].name, 'primary')}>
                {activeAccord.indexOf(data[i].name) !== -1 ? (
                  <CircleArrowDown style={{ height: 15 }} />
                ) : (
                  <CircleArrow style={{ height: 15}} />
                )}
              </span>
            )}
            {data[i].location && data[i].location.length > 0 && (
              <h4 style={{ fontSize: "12px",color:"#908fab", margin: 0, marginLeft: 8 }}>
                {data[i].name.charAt(0).toUpperCase() + data[i].name.slice(1)}
              </h4>
            )}
          </Col>


              <Col lg={2} className="align-middle" style={{  fontSize: "9px",marginRight:30 }}>
              <span style={{ fontSize: "10px",color:"#908fab"}}>{data[i].location.length > 0 && validPlannedCount}</span>
            </Col>
          <Col lg={2} className="align-middle" style={{ textAlign: "center", fontSize: "10px",color:"#908fab" }}>
        {data[i].location.length > 0 && (
          <>
            <span style={{ fontSize: "10px", fontWeight: "bold" }}></span>
            {data[i].location.reduce((acc, loc) => {
              const actual = dataSocket.filter((el) => el.location_id === loc.id).length;
              return acc + actual;
            }, 0)}
          </>
        )}
      </Col>

            <Col lg={3} className="align-middle" style={{textAlign:"center",fontSize:"10px"}}>
            {data[i].location.length > 0 ? (
                data[i].location.reduce((acc, loc) => {
                  const actual = dataSocket.filter((el) => el.location_id === loc.id).length;
                  return acc + actual;
                }, 0) > 0 ? (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd" onClick={() => categorymodalview(data[i])}>
                      <Eye style={{ height: 13, cursor: "pointer" }} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd disabled nonCLickable">
                      <Eye style={{ height: 13 }} />
                    </div>
                  </React.Fragment>
                )
              ) : null
            }
          {/* <React.Fragment key={data[i].id}>
          <div className="legendRightAreaProd" onClick={() => categorymodalview(data[i])}>
            <Eye style={{ height: 13, cursor: "pointer" }} />
          </div>
          </React.Fragment> */}
    </Col>
              {/* <Col lg={3} className="align-middle" style={{textAlign:"center",fontSize:10}}>{data[i].location.length}</Col> */}

            </div>
            <div  className={activeAccord.indexOf(data[i].name)!==-1 ?'Category-content active':'Category-content'}>
              <div className="ag-theme-alpine" style={{  width: '100%'}}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    {/* Header cells */}
                  </tr>
                </thead>
                <tbody>
                  {data[i].location.map((loc) => {
                  const locData = locationCordinate.find(
                    (cord) => cord.location_id === loc.id && cord.name === loc.location_name
                  );
                  const planned = locData?.planned || "0";
                    const actual = dataSocket.filter((el) => el.location_id === loc.id).length;
                    const eye =
                      dataSocket.filter((el) => el.location_id == loc.id).length > 0 ? (
                        <div className="legendRightAreaProd" onClick={() => modalViewShow(loc)}>
                          <Eye style={{ height: 13, cursor: "pointer",marginLeft:23}} />
                        </div>
                      ) : (
                        <div className="legendRightAreaProd disabled nonCLickable">
                          <Eye style={{ height: 13 ,marginLeft:23}} />
                        </div>
                      );
                    return (
                    //   <tr key={loc.id} style={{}}>
                    //   <td className="locationName">{loc.location_name}</td>
                    //   <td style={{ padding: 3, fontSize: "11px", color: planned <= actual ? "black" : "grey", width: "20%", textAlign: "right" }}>
                    //     {planned}
                    //   </td>
                    //   <td style={{ padding: 3, fontSize: "11px", color: actual <= planned ? "black" : "grey", width: "20%", textAlign: "right" }}>
                    //     {actual}
                    //   </td>
                    //   <td style={{ width: "30%"}}>{eye}</td>
                    // </tr>
                     <tr key={loc.id} style={{}}>
                      <td className="locationName">{loc.location_name}</td>
                      <td style={{ padding: 3, fontSize: "10px", color: planned <= actual ? "black" : "grey", width: "20%", textAlign: "right", }}>
                        {planned}
                      </td>
                      <td style={{ padding: 3, fontSize: "10px", color: actual <= planned ? "black" : "grey", width: "20%", textAlign: "right" }}>
                        {actual}
                      </td>
                      <td style={{ width: "30%"}}>{eye}</td>
                    </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>                 
            </div>
          </Row>
        )
      }
    }
    return arr;
  }
  
  

      
  return (<>
      {permissionAPICall?permission.length>0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
      {role? <Col lg={12} className="headerFontSpaceLess"> <CommonHeading title="Facility Tracker" /> </Col>:""}

        <Col lg={6} style={{display:role?'none':'block'}}>

                 <div className='tabHeader'>
              
                <div className='tabHeaderList' onClick={()=>props.history.push('/employee-tracker')}>
                <CommonHeading title="Employee Tracker"/>
                </div>   
                <div className='tabHeaderList active'>
                <CommonHeading title="Facility Tracker" />
                </div>
                <div className='tabHeaderList ' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>
           </div>   
    </Col>
        <Col lg={6} className="text-right" style={{display:role?'none':'block'}}>
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className={role?"":"m-t"} >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}>Filter</h5>
            </div>
        
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val)}}
                value={activeDepartment}
                key="departmentFilter"
                style={{width:250}}
                // disabled={!space}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
            >
              <Option value='all' key={"Department All"}>All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.department_id} key={"Department"+item.department_id}>{item.name}</Option>
                }):""
                }
            </Select>
            </div>
            <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>{onChangeSubDepartment(val)}}
                    disabled={activeDepartment.length==0 || activeDepartment.length>1?true:false}
                    value={activeSubDepartment}
                    key="SubdepartmentFilter"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department All"}>All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item.sub_department_id} key={"Sub Department"+item.sub_department_id}>{item.sub_dept_name}</Option>
                    }):""
                    }
                </Select>
            </div>

            {/* <div className='inlineBlockFilter'>
                <button className={activeType==''?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('')} disabled={!space}>All</button>
                <button className={activeType=='productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('productive')} disabled={!space}>Productive</button>
                <button className={activeType=='non_productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('non_productive')} disabled={!space}>Non Productive</button>
                <button className={activeType=='less_productive'?'button active btn-productive-filter':'button btn-productive-filter'} onClick={()=>SetActiveType('less_productive')} disabled={!space}>Less Productive</button>
            </div> */}
            {/* {space?<><div className='greenLive'>Live</div><div className='timerLive'><span className='toptime'>{time}</span><span className='currentDate'>{moment().format('DD-MM-YYYY')}</span></div></>:""} */}
            {space?<><div className={delayTimer?'greenLive disabledClick':'greenLive'} onClick={()=>SetReloadInc((prev)=>prev+1)}>Reload</div><div className='timerLive' style={{marginBottom:"15px"}}><span className='toptime'>{time}</span><span className='currentDate'>{moment().format('DD-MM-YYYY')}</span></div></>:""}
            </div>
        </Col>
      </Row>

      <Row className="m-t">
        <Col lg={9} className='tabletfull' >

        {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""} 
                            <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>



             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''? <Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} /*cameraPlacement={cameraPlacements}*//>:<h2>Please Setup Geo Map or contact to administrator</h2>}
          </div>
        </Col>
        <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle" style={{height:'100%',background:"#fff"}}>
            <div className='legendStart'>
              <div className={!space?'legendList legendHeader disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Plan Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Actual Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Worker List</strong></div>
              </div>
              <div className='legendLeftAreaProd'>{ShowCardListLocation()}</div>
                    {/* {locationCordinate.map(item=>{

                      return(
                        
                                    <div key={"legendList"+item.name} className={!space?'legendList disable':'legendList'}>
                                              <div className='legendLeftAreaProd'>{item.name}</div>
                                              <div className='legendLeftAreaProd legendLeftAreaProdSmall'>{item.planned}</div>
                                              <div className={dataSocket.filter(el=>el.location_id == item.location_id).length > parseInt(item.planned)?'legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallGreen':
                                                dataSocket.filter(el=>el.location_id == item.location_id).length == parseInt(item.planned)?'legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallGreen':item.planned == '-'?"legendLeftAreaProd legendLeftAreaProdSmall":"legendLeftAreaProd legendLeftAreaProdSmall legendLeftAreaProdSmallRed"}>{!space?'-':dataSocket.filter(el=>el.location_id == item.location_id).length}</div>
                                              {dataSocket.filter(el=>el.location_id == item.location_id).length > 0?<div className='legendRightAreaProd' onClick={()=>modalViewShow(item)} ><Eye/></div>:<div className='legendRightAreaProd disabled nonCLickable'><Eye/></div>}
                                              
                                        </div>
                                )
                    })}

                     */}

      {/* <div className={!space?'legendList legendHeader legendFooter disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd' style={{marginTop:8,marginLeft:4}}><strong>Total</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall' style={{marginLeft:3,marginTop:10}}>{locationCordinate.reduce((partialSum, a) => (partialSum==undefined?0:partialSum) + (a.planned!=='-'?a.planned:0), 0)}</div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall' style={{marginTop:10}} >{dataSocket.length}</div>
                  <div className='legendRightAreaProd'>
                      {dataSocket.length > 0 ? (
                <div className='legendRightAreaProd' onClick={() => categorymodalview1(locationCordinate)} key={locationCordinate.location_id}>
                  <Eye  style={{height: 13,marginLeft:24,marginTop:7}}/>
                </div>
              ) : (
                <div className='legendRightAreaProd disabled nonCLickable' key='total-eye-icon'>
                  <Eye  style={{height: 13,marginLeft:24,marginTop:7}}/>
                </div>
              )}
                  </div>
              </div> */}
              <div className={!space?'legendList  legendHeader legendFooter disable ':'legendList legendHeader'}style={{borderBottom: "0px"}}>
              <div className="customContainer contentGroup contentCategory row" style={{borderBottom: "1px solid #ddd"}}>
                <div className="Category-header group-header">
  <div className="customLeftArea align-middle align-wrapper col-lg-3" style={{marginLeft:0}} ><strong>Total</strong></div>

  <div className="customPlanned align-middle col-lg-2" style={{fontSize:10}}>{locationCordinate.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0)}</div>
  <div className="customDataLength align-middle col-lg-2" style={{fontSize:10,textAlign:'center'}}>{dataSocket.length}</div>
  <div className="customRightArea align-middle col-lg-3" style={{marginTop:3,textAlign:"center"}}>
    {dataSocket.length > 0 ? (
      <div className='legendRightAreaProd' onClick={() => categorymodalview1(locationCordinate)} key={locationCordinate.location_id}>
        <Eye style={{height:13}} />
      </div>
    ) : (
      <div className='legendRightAreaProd disabled nonClickable' key="total-eye-icon">
        <Eye  style={{height:13}}/>
      </div>
    )}
  </div>
  </div>
</div>
</div>
  </div>

          </div>
        </Col>
      </Row>
    </Container>

    <ReactModal
                isOpen={modalView}
                style={customStyles}
                onRequestClose={()=>SetModalView(false)}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>

  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilitiesTracker