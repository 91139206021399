import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getChatGPTRecommendation, getFopReportFilter } from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
import ReactHighcharts from "react-highcharts";
import Scrollbars from "react-custom-scrollbars";
import { getOverallProcessReport } from '../../actionMethods/actionMethods'
import OrganizationalChartComponent from "./OrganizationalChartComponent";
import {ReactComponent as EyeIcon} from '../../../assets/images/eye.svg'
import { Button, Modal } from 'antd';


const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function ProcessUseCases(props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const [chartData,SetChartData] = useState([]);
    const [chartData2,SetChartData2] = useState([]);
    
    const [performanceAll,SetPerformanceAll] = useState([]);
    const [performanceThreeshold,SetPerformanceThreeshold] = useState([]);

    const [recommendation1,SetRecommendation1] = useState([])
    const [recommendation1Loader,SetRecommendation1Loader]= useState(false);
    const [recommendation2Loader,SetRecommendation2Loader]= useState(false);
    const [recommendation2,SetRecommendation2] = useState([])
    const [open, setOpen] = React.useState(false);
    const [activeRecommendation,SetActiveRecommendation]= useState('');



    const headerTime =["12 AM","1 AM","2 AM","3 AM","4 AM","5 AM","6 AM","7 AM","8 AM","9 AM","10 AM","11 AM","12 PM","1 PM","2 PM","3 PM","4 PM","5 PM","6 PM","7 PM","8 PM","9 PM","10 PM","11 PM"]
    

    const indonesiaTimeZone = 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
      const [config,SetConfig]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            labels: {
                useHTML:true,
                formatter: function () {
                        return this.value+"%"                         
                }
            },
            min:0,
            max:100,
            tickAmount:6,


        },
        tooltip: {
            shared: true,
            visible: true // Set tooltip always visible
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
      
      const [config2,SetConfig2]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            
            min:0,
                        tickAmount:6,


        },
        tooltip: {
            shared: true,
            visible: true // Set tooltip always visible
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });

      const shiftMapping = {
  P: 'Morning',
  S: 'Afternoon',
  M: 'Night'
};

      
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
const monringTime= []
  const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]    

  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };

function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}


const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const generateData = (startDate, endDate,
    absentMinMax = {min:50,max:100},
    unexpectedMinMax = {min:50,max:100},
    shiftChnageMinMax = {min:50,max:100},
    lateMinMax = {min:50,max:100},
    nonCheckoutMinMax = {min:50,max:100},
) => {

  const data = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(moment(endDate))) {
    const dateStr = currentDate.format('YYYY-MM-DD');
    let single ={}
    single.date=dateStr;
    single.data=[];

    for(let i=0;i<5;i++){
    const absent = getRandomValue(absentMinMax.min, absentMinMax.max);
    const unexpected = getRandomValue(unexpectedMinMax.min, unexpectedMinMax.max);
    const shift_change = getRandomValue(shiftChnageMinMax.min, shiftChnageMinMax.max);
    const late = getRandomValue(lateMinMax.min, lateMinMax.max);
    const non_checkout = getRandomValue(nonCheckoutMinMax.min, nonCheckoutMinMax.max);
    let shiftDt ={};
    shiftDt.type=["Morning","Afternoon","Night"][getRandomValue(0,2)];



    }

    



    data.push(single);

    currentDate = currentDate.add(1, 'days');
  }
  return data;
};

const generateDataOvertime = (startDate, endDate,min= 10000,max= 20000) => {

  const data = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(moment(endDate))) {
    const dateStr = currentDate.format('YYYY-MM-DD');
    const overtime = getRandomValue(min, max);
    const undertime  = getRandomValue(min,overtime);
    const overtime_pay = (overtime - undertime) * 100;

    data.push({
      date: dateStr,
      overtime,
      undertime,
      overtime_pay,
    });

    currentDate = currentDate.add(1, 'days');
  }
  return data;
};




function getDateFromDayNumber(year, dayNumber) {
  const startDate = new Date(year, 0, 1); // January 1st of the given year
  startDate.setDate(startDate.getDate() + dayNumber - 1); // Subtract 1 because the 1st day is already counted
  return moment(startDate).format("DD MMM YY");
}

const formatDataProcess = (apiData,type) => {
  const formattedData = [];
  
  for (const week in apiData.data.low_performance) {
    const weekData = [];
    let dayName =week;
    for (const period in apiData.data.low_performance[week]) {
      for (const time in apiData.data.low_performance[week][period]) {
        let periodName = '';
        switch (period) {
          case 'M':
            periodName = 'Night';
            break;
          case 'P':
            periodName = 'Morning';
            break;
          case 'S':
            periodName = 'Afternoon';
            break;
          default:
            break;
        }
        if(type==="day"){          
          dayName =getDateFromDayNumber(moment(props.filterPassData.start_date).format('YYYY'), parseInt(week.replaceAll('day ',"")));
        }else if(type==="month"){
          dayName =  moment('01-01-2024').add(parseInt(week.replaceAll('month ',"")   - 1),'month').format("MMMM")
        }


        weekData.push({ date:dayName, period: periodName, time, value: apiData.data.low_performance[week][period][time] });
      }
    }


    formattedData.push({ name: dayName, data: weekData });
  }

  return formattedData.filter(el=>el.data.length > 0);
};

  function loadRecomend1(message){
    setOpen(true);
    SetActiveRecommendation('recommendation1')
    getChatGPTRecommendation('Hi gpt, this is data in tree format on which time the organisation is having poor performance from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result as takeways point. So, What is the recurring poor performance time and day of the month? ").then(res=>{
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
      SetRecommendation1(output);
      SetRecommendation1Loader(false);
    })
  }

  function loadRecomend2(message){
    
    getChatGPTRecommendation('Hi gpt, this is the Process performance data in the organisation from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+ "please share result as only takeways point. So, What are takeaways from data?").then(res=>{
    
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
      SetRecommendation2(output);
      SetRecommendation2Loader(false);
    })
  }

const formatPerformanceData = (apiData,type) => {
  const formattedTableData = [];
  const categories = [];
  const series = {};

  for (const week in apiData.data.performance_averages) {
      let dayName =week;
    if(type==="day"){
            // dayName =  moment(`01-01-${moment(props.filterPassData.start_date).format('YYYY')}`).add(parseInt(week.replaceAll('day ',"")),'days').format("DD MMM YY");
            dayName = getDateFromDayNumber(moment(props.filterPassData.start_date).format('YYYY'), parseInt(week.replaceAll('day ',"")))
        }else if(type==="month"){
          dayName =  moment('01-01-2024').add(parseInt(week.replaceAll('month ',"")   - 1),'month').format("MMMM")
        }

    const weekData = { name: dayName };
    
    const processes = apiData.data.performance_averages[week];
    

    for (const process in processes) {
      const overallValue = processes[process];
      weekData[process] = overallValue;
      
      // Initialize series data if it doesn't exist
      if (!series[process]) {
        series[process] = [];
      }
      
      series[process].push(overallValue);
    }
    

    formattedTableData.push(weekData);
    categories.push(dayName);
  }

  // Convert series object to array of objects with name and data properties
  const seriesArray = Object.keys(series).map(process => ({
    name: process,
    data: series[process]
  }));

  return { formattedTableData, categories, seriesArray };
};

  


useEffect(()=>{
  let reportHead=[]
  SetLoader(true);
  
async function filterResultHandler(){
    try{
        if(props.filterActive){
  
          let requestBody = props.filterPassData;

          let resp = await getOverallProcessReport(userSession,org_id,sub_org_id,getDateFormat(props.filterPassData.start_date),getDateFormat(props.filterPassData.end_date),props.filterPassData.threeshold,props.filterPassData.reportBy)        
 
          // let data = formatDataProcess(resp,props.filterPassData.reportBy)
          let data = Object(resp.data.low_performance) 



          let allData2 = formatPerformanceData(resp,props.filterPassData.reportBy)
          let data2 = allData2.formattedTableData
          if(props.filterPassData.reportBy =="day"){
              
              data2 = data2.sort((a,b)=>new Date(a.date) - new Date(b.date));
            }
             
            SetPerformanceAll(data);

            let series2 = [];
            
            series2.push({name:"Overtime",data:data2.map(el=>el.overtime)})
            series2.push({name:"UnderTime",data:data2.map(el=>el.undertime)});
            
            SetPerformanceThreeshold(data2);
            

            let newConfig2 = {...config2}
            newConfig2.xAxis.categories=allData2.categories;
            newConfig2.series = allData2.seriesArray;
            SetChartData2({cat:allData2.categories,series:allData2.seriesArray});
            SetConfig2(newConfig2);

          
          SetLoader(false);






          
        }
              

    }catch(error) {
           
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Attendance Time",data ,imageId);
    
  const fileName = 'ProcessUseCasesReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet
  };
  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};

function AGTableBadAnalysis(data){    
     

        let arr;
        let date='';
        if(props.filterPassData.reportBy=="day"){
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }else{
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }

        arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:100,height:(data.length * 32) + 36,maxHeight:200 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
          
          
          <AgGridColumn 
              field="period" 
              headerName="Shift" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          <AgGridColumn 
              field="time" 
              headerName="Time" 
              valueFormatter={(params) =>params.value && headerTime[parseInt(params.value)]?`${headerTime[parseInt(params.value)]}`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
            <AgGridColumn 
              field="value" 
              headerName="Performance" 
              valueFormatter={(params) =>params.value?`${params.value}%`:`0%`}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }


    


function AGTableAttendanceOvertime(data) {
  let arr;
  
  arr = (
    <div className={"keyAGTableSmallPlan"}>
      <div className="ag-theme-alpine if cell-size-40" style={{ minHeight: 350, height: (data.length * 32) + 36, maxHeight: 375, width: "calc(100% - 1px)" }}>
        <AgGridReact
          rowHeight={30}
          headerHeight={20}
          defaultColDef={{ sortable: true, resizable: true, minWidth: 45, flex: 1, suppressColumnVirtualisation: true, skipHeaderOnAutoSize: true }}
          rowData={data}
          key={'keyAGTable'}
        >
          <AgGridColumn
            field="name"
            headerName={props.filterPassData.reportBy}
            cellClass={'textCapitalize textCap'}
            flex={1}
            width={150}
            maxWidth={200}
          />

          {Object.keys(data[0]).filter(key => key !== 'name').map((key, index) => (
            <AgGridColumn
              key={index}
              field={key}
              headerName={key}
              valueFormatter={(params) => params.value ? `${params.value}%` : "-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          ))}

        </AgGridReact>
      </div>
    </div>
  );

  return arr;
}
  

    function AGTablePanelBad(data){
      let arr =[];

arr.push(
<Collapse accordion className="customAccord customAccord2 customAccord3" style={{width:"100%",minWidth:"100%"}}>
      {data.map((item, index) =>
      
      { 
        
         return (
      
        <Panel 
        
        header={<div className="costBodayDept" >
          <Row>
            <Col style={{paddingLeft:"30px"}}>{item.name}</Col>
         </Row>
        </div>}
        >
          <Collapse>
          
{AGTableBadAnalysis(item.data)}          
          </Collapse>
</Panel>
    )}  )  }  

</Collapse>)



      return arr;
    }



    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:

    <div className="App">
        
<ToastContainer/>

    <Row style={{overflow:"auto",paddingBottom:"20px",marginBottom:20}}>
      <Col lg={9} className="process"><h4 style={{fontSize:16}}>Bad Performance analysis <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span></h4></Col>
                    <Col lg={9} className="someStyleHeadline" style={{display:"table-cell"}}>
                                                {/* {AGTablePanelBad(performanceAll)} */}
      <OrganizationalChartComponent data={performanceAll} type={props.filterPassData.reportBy} startDate={moment(`01-01-${moment(props.filterPassData.start_date).format('YYYY')}`)} />
                                                

                    </Col>
      <Col lg={3}>
        <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd",minHeight:1}}>
                        <div className="reccomentHeader"><h4 style={{borderBottom:0,margin:0}}>Insights  <span className="recommendationEye" style={{display:recommendation1.length?"inline-block":"none",marginTop:0}} onClick={()=>{SetActiveRecommendation('recommendation1');setOpen(true)}}><EyeIcon/></span> <span className="reloadRecommend" style={{margin:0}} onClick={()=> {SetRecommendation1Loader(true);loadRecomend1(performanceAll); }}>{recommendation1.length > 0? "Reload":"Load"}</span></h4></div>                       
                         

                       </div>
                       </div>
        
      </Col>
      
    </Row>
      <hr />

    <Row>
      <Col lg="12" className=""><h4 style={{fontSize:16}}>Process Performance  <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span></h4></Col>
      
                    <Col lg={6} className="someStyleHeadline" style={{display:"table-cell"}}>

                       {AGTableAttendanceOvertime(performanceThreeshold)}

                    </Col>
                    <Col lg={6}>
                                           <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd"}}>
                        <div className="reccomentHeader"><h4>Insights <span className="recommendationEye"  onClick={()=>{SetActiveRecommendation('recommendation2');setOpen(true)}} style={{display:recommendation2.length?"inline-block":"none"}}><EyeIcon/></span> <span className="reloadRecommend" onClick={()=> {SetRecommendation2Loader(true);loadRecomend2(performanceThreeshold); }}>{recommendation2.length > 0? "Reload":"Load"}</span> </h4></div>
                        <div className="recommentContent">

                          <Scrollbars style={{height:100}}>
                              {recommendation2Loader?<div style={{textAlign:"center"}}><img src={spinnerLoader} width={"70px"} /></div>:
                           <ul style={{listStyle:"none"}}>
                            {recommendation2.map((el,index)=>{
                              return <li key={"reccomend2 "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
                            })}
                           </ul>}
                           </Scrollbars>

                        </div>
                       </div>
                       </div>


        <ReactHighcharts config={config2}/>
      </Col>
      
    </Row>
    
<Modal
        title={"Insights"}
        footer={null}

        open={open}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        {recommendation1Loader || recommendation2Loader?<div style={{textAlign:"center"}} >
          <img src={spinnerLoader} />
          </div>:  
        <ul className="testScroll">
          <Scrollbars style={{height:400}}>
          {activeRecommendation=="recommendation1"?recommendation1.map((el,index)=>{
            return <li key={"recommendModa "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          }):
          recommendation2.map((el,index)=>{
            return <li key={"recommendModal2 "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          })
      }
      </Scrollbars>

        </ul>}
        
      </Modal>      

    </div>:""
    )

}