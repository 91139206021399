import React, { useState, useEffect,useRef } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';
import '../styles/style.scss'

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import StackChart from "./StackChart";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import DeviceStatus from '../../components/DeviceStatus'
import {getPproductivitylist,
        getPproductivityOverview,
        getPproductivityOverviewAll,
        getDepartment,
        getSubDepartment,
        getShift } from '../actionMethods/actionMethods'

        
import { logoutUser } from "../../login/actionMethods/actionMethods";
import { Select   } from 'antd';
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import Anouncement from '../../assets/images/megaphone.png'
import closeModal from '../../assets/images/closeModal.png'
import { toast,ToastContainer } from "react-toastify";
import Modal from 'react-modal'

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    },
    content: {
        position: 'absolute',
        width: '32rem',
        height: '22rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #ccc',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0'
    }
}

function Productivity(props) {
  const { Option } = Select;
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

    
  const [dates, setDates] = useState({
      start: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD')),
      end: new Date(moment(props.date?props.date:date).format('YYYY-MM-DD')),
    });
  
  let StarFill =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#f2bc19"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM351.9 311.8l33.72 103.9l-88.25-64.18c-5.61-4.08-13.21-4.08-18.82 0L190.3 415.6l33.72-103.9c2.139-6.589-.2053-13.81-5.808-17.88L129.9 229.6h109.2c6.933 0 13.08-4.465 15.22-11.06L288 114.8l33.7 103.8c2.141 6.594 8.285 11.06 15.22 11.06h109.2l-88.34 64.24C352.2 297.1 349.8 305.2 351.9 311.8z"/></svg>`;
  let StarEmpty =`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#b5b1a6"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.19 295.3 0 288 0S273.4 4.19 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.553 0 7.227-1.09 10.62-3.556L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM370.8 317.9l45.34 139.7l-118.7-86.3c-5.61-4.079-13.21-4.079-18.82 0l-118.7 86.3l45.34-139.7c2.139-6.589-.2054-13.81-5.808-17.88L80.7 213.6h146.8c6.933 0 13.08-4.465 15.22-11.06L288 62.96l45.33 139.6c2.141 6.594 8.285 11.06 15.22 11.06h146.8l-118.7 86.35C370.1 304.1 368.6 311.3 370.8 317.9z"/></svg>`;
 
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");
  const [actEmp,SetActEmp]=useState('week');
  const [actDaily,SetActDaily]=useState('week');
  const [slug,setSlug] = useState('employee')
  
  const [employeeList,SetEmployeeList] =useState([]);

  const [tabData,SetTabData]=useState([]);
  const [tabDataEmployeeDefault,SetTabDataEmployeDefault]=useState([]);
  

  const [tabDataDailyDefault,SetTabDataDailyDefault]=useState([]);
  const [searchValue,updateSearchValue] =useState('');
  const [isLoading,SetisLoading]=useState(false);
  const [isLoading1,SetisLoading1]=useState(true);
  const [isLoading2,SetisLoading2]=useState(true);

  const [globalSearch, setGlobalSearch] = useState('')
  const [globalCategory, setGlobalCategory] = useState('Category')
  const [suggestions, setSuggestions] = useState([])
  const [activeTeam,SetActiveTeam]=useState('regular')
  const [activeEmp,SetActiveEmp]=useState('employee')



  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let lastReqId = null
  const [widthContainer, setWidthContainer] = useState(0);
  const elementRef = useRef(null);   
  const [parentAccord,SetParentAccord] =useState([])


  const [tabDataEmployee,SetTabDataEmploye]=useState([]);
  const [tabDataDaily,SetTabDataDaily]=useState([]);

  const[searchCat,SetSearchCat]=useState([]);
  const[searchSubCat,SetSearchSubCat]=useState([]);
  const[searchEmployee,SetSearchEmployee]=useState([]);

  const[searchCatDefault,SetSearchCatDefault]=useState([]);
  const[searchSubCatDefault,SetSearchSubCatDefault]=useState([]);
  const[searchEmployeeDefault,SetSearchEmployeeDefault]=useState([]);

  //dailyWorker
  const[searchCatDailyDefault,SetSearchCatDailyDefault]=useState([]);
  const[searchSubCatDailyDefault,SetSearchSubCatDailyDefault]=useState([]);
  const[searchDailyDefault,SetSearchDailyDefault]=useState([]);

  const [activeDefault,SetActiveDefault]=useState('');
  const [displaySearch,SetSearchDisplay]=useState(false);
  const [subTabCatActive,SetSubTabCatActive]=useState(false)
  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [departmentList,SetDepartmentList]=useState([])
  const [departmentListDaily,SetDepartmentListDaily]=useState([])
  const [subdepartmentList,SetSubDepartmentList]=useState([])
  
  const [department_id,SetDepartmentID]=useState()
  const [shiftID,SetShiftID]=useState()
  const [sub_department_id,SetSubDepartmentID]=useState()
  const [shiftList,SetShiftList]=useState([])
  const [department_idDaily,SetDepartmentIDDaily]=useState()
  const [sub_department_idDaily,SetSubDepartmentIDDaily]=useState()
  const [subdepartmentListDaily,SetSubDepartmentListDaily]=useState([])
  const [shiftListDaily,SetShiftListDaily]=useState([])
  const [shiftIDDaily,SetShiftIDDaily]=useState()
  const [chartData,SetChartData]=useState({
              employee: { categories: [],series: [] },
              daily: { categories: [], series: [] },
    });
  const [filterDisable,SetFilterDisable] = useState(true);
  const [filterDisableDaily,SetFilterDisableDaily] = useState(true);
  const [apiDataDefault,SetAPIDataDefault]= useState([]);

  const [role,SetRole] = useState([]);
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [employeeAll,SetEmployeeAll] =useState([]);
  
  const [employeeAllDefault,SetEmployeeAllDefault] =useState([])
  const [dailyAll,SetDailyAll] =useState([]);
  const [dailyDefault,SetDailyDefault] =useState([])

  const [noData,SetNoData]=useState('No data found');
  const [errorDaily,SetErrorDaily] =useState(false)
  const [errorEmployee,SetErrorEmployee] =useState(false)
  const [slugUserType,SetSlugUserType]= useState('employee');

  const [dailyDept,SetDailyDept]= useState([]);
  const [regularDept,SetRegularDept]= useState([]);
  const [anouncementModal,SetAnouncementModal]= useState(false);
  
useEffect(()=>{

  if(moment('2023-08-01').toDate() < moment(selectedDate)){
    // props.history.push(`/productivity`)
  }else{
      
  }
},[selectedDate])

  useEffect(()=>{

    if(moment() < moment('2023-04-14 22:00')){
      SetAnouncementModal(true);
    }
  },[])



  useEffect(() => {
    let perm= props.role.productivity_management?JSON.parse(props.role.productivity_management):[];
    if(perm || perm.hasOwnProperty("productivity_management")){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }

},[props.pages]);

  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }

  useEffect(()=>{
    if(permission && permission.length > 0){
    let arr=[]
    let arr2=[]
    let requestBody={}
    requestBody.startdate= getDateFormat(dates.start);
    requestBody.enddate= getDateFormat(dates.end);
    //requestBody.slug= activeEmp;
    requestBody.slug= 'employee';
    let cat=[];
    let catDaily=[];
    


    SetisLoading(true);

       getPproductivitylist(requestBody,userSession,org_id,sub_org_id).then( res=> {
    
        if(res.data && res.status==200){
          let catIndex=0;
          let empList= [];
          Object.keys(res.data).forEach((key) => {
            
            let element=res.data[key];
            let child= [];
            let childName='';
            let prod = 0;
            let nonprod = 0;
            let lessprod = 0;
            let und = 0;
            let acs = 0;
            let total_working_hour = 0;



            let parentLength=0;
            let subdept=[]
            Object.keys(element).forEach((key2) => {
             
              if(isInt(key2)){
                

                prod = parseFloat(prod) +parseFloat( element[key2].productive);
                nonprod= parseFloat(nonprod)+parseFloat(element[key2].non_productive);
                lessprod = parseFloat(lessprod)+parseFloat(element[key2].less_productive);
                und = parseFloat(und) +parseFloat(element[key2].undefined);
                acs = parseFloat(acs) + parseFloat(element[key2].access_policy_breach)
              
                element[key2].department=key
                element[key2].sub_department=key2

              }else{
                subdept.push(key2);
                
                childName=key2
                let prodCat = 0;
                let nonprodCat = 0;
                let lessprodCat = 0;
                let undCat = 0;
                let acsCat = 0;
                let avgZero=0;
                let total_working_hour_cat=0;
  
                for (let c = 0;c<element[key2].length;c++){
                  parentLength++;
                  prodCat= parseFloat(prodCat) + parseFloat(element[key2][c].productive);
                  nonprodCat= parseFloat(nonprodCat)+parseFloat(element[key2][c].non_productive);
                  lessprodCat=parseFloat(lessprodCat)+parseFloat(element[key2][c].less_productive);
                  undCat= parseFloat(undCat) + parseFloat(element[key2][c].undefined);
                  acsCat= parseFloat(acsCat) + parseFloat(element[key2][c].access_policy_breach);
                  
                  prod =  parseFloat(prod) + parseFloat(element[key2][c].productive);
                  nonprod= parseFloat(nonprod)+parseFloat(element[key2][c].non_productive);
                  lessprod = parseFloat(lessprod)+parseFloat(element[key2][c].less_productive);
                  und = parseFloat(und) +parseFloat(element[key2][c].undefined);
                  acs = parseFloat(acs) + parseFloat(element[key2][c].access_policy_breach);
  
                  element[key2][c].type='employee'
                  let sumHour =parseFloat(element[key2][c].productive) + parseFloat(element[key2][c].less_productive)
                  let nonSumHour =parseFloat(element[key2][c].undefined) + parseFloat(element[key2][c].non_productive);
                  element[key2][c].total_working_hour = parseFloat(sumHour)>100?100:parseFloat(sumHour.toFixed(2)) ;
                  element[key2][c].rating = ratingCal(parseFloat(element[key2][c].total_working_hour)) ;
                  element[key2][c].total_wasted_hour = parseFloat(sumHour) > 100?0:parseFloat(nonSumHour)>100?100:parseFloat(nonSumHour.toFixed(2));
                  total_working_hour=total_working_hour+parseFloat(element[key2][c].total_working_hour);
                  total_working_hour_cat =parseFloat(total_working_hour_cat)+parseFloat(element[key2][c].total_working_hour);
                  if(parseFloat(element[key2][c].productive)  == 0  && parseFloat(element[key2][c].non_productive)== 0 && parseFloat(element[key2][c].less_productive)== 0 && parseFloat(element[key2][c].undefined)  == 0 ){
                    avgZero++;
                    parentLength= parentLength -1;
                  }                
                    
                  element[key2][c].department=key
                  element[key2][c].sub_department=key2
                  empList.push(element[key2][c]);
  
                }
                child.push({name:key2,data:element[key2],productive:parseFloat(prodCat/(element[key2].length - avgZero)).toFixed(2),non_productive:parseFloat(nonprodCat/(element[key2].length- avgZero)).toFixed(2),less_productive:parseFloat(lessprodCat/(element[key2].length- avgZero) ).toFixed(2),undefined:parseFloat(undCat/(element[key2].length - avgZero)).toFixed(2) ,access_policy_breach:parseFloat(acsCat/element[key2].length).toFixed(2),total_working_hour:(total_working_hour_cat/(element[key2].length- avgZero)).toFixed(2),rating:ratingCal(total_working_hour_cat/element[key2].length- avgZero) })
              
              }
  
            });
  
            if(childName!==''&& Number.isInteger(childName) ===false ){
              cat.push({name:key,sub_department:subdept}) 

              arr.push({name:key,child:child,productive:parseFloat(prod/parentLength).toFixed(2),non_productive:parseFloat(nonprod/parentLength).toFixed(2),less_productive:parseFloat(lessprod/parentLength).toFixed(2),undefined:parseFloat(und/parentLength).toFixed(2) ,access_policy_breach:parseFloat(acs/parentLength).toFixed(2),total_working_hour:(total_working_hour/parentLength).toFixed(2),rating:ratingCal(total_working_hour/parentLength) })  
              
             
            }else{
              cat.push({name:key,sub_department:subdept}) 

              arr.push({name:key,data:element,productive:parseFloat(prod/element.length).toFixed(2),non_productive:parseFloat(nonprod/element.length).toFixed(2),less_productive:parseFloat(lessprod/element.length).toFixed(2),undefined:parseFloat(und/element.length).toFixed(2) ,access_policy_breach:parseFloat(acs/element.length).toFixed(2),total_working_hour:(total_working_hour/parentLength).toFixed(2),rating:ratingCal(total_working_hour/parentLength)})            
            
            }
            
            
            
              
           });



           SetRegularDept(cat);

           arr.startdate= getDateFormat(dates.start);
           arr.enddate= getDateFormat(dates.end);
           arr.type= activeEmp;
            showCardListFormat(arr,'employee');

            SetTabDataEmploye(arr)
            SetTabDataEmployeDefault(arr)
            SetTabData(arr) 

           SetisLoading(false);

           SetEmployeeAll(empList)
           SetEmployeeAllDefault(empList) 

          }else{
            toast.error("TW:02 Something went wrong while fetching productivity list of regular worker");
          }
  

        });
    
        requestBody.slug= 'daily_worker'; 

        getPproductivitylist(requestBody,userSession,org_id,sub_org_id).then( res=> {
    
          if(res.data && res.status==200){
            let catIndex=0;
            let empList= [];
            Object.keys(res.data).forEach((key) => {
              
              let element=res.data[key];
              let child= [];
              let childName='';
              let prod = 0;
              let nonprod = 0;
              let lessprod = 0;
              let und = 0;
              let acs = 0;
              let total_working_hour = 0;
  
  
  
              let parentLength=0;
              let subdept=[]
              Object.keys(element).forEach((key2) => {
                if(isInt(key2)){
  
                  prod = prod + element[key2].productive;
                  nonprod= nonprod+element[key2].non_productive;
                  lessprod = lessprod+element[key2].less_productive;
                  und = und +element[key2].undefined;
                  acs = acs + element[key2].access_policy_breach
                  element[key2].department=key
                  element[key2].sub_department=key2

                }else{
                  subdept.push(key2);
                  
                  childName=key2
                  let prodCat = 0;
                  let nonprodCat = 0;
                  let lessprodCat = 0;
                  let undCat = 0;
                  let acsCat = 0;
                  let avgZero=0;
                  let total_working_hour_cat = 0;
    
                  for (let c = 0;c<element[key2].length;c++){
                    parentLength++;
                    prodCat=parseFloat(prodCat)+parseFloat(element[key2][c].productive);
                    nonprodCat=parseFloat(nonprodCat)+parseFloat(element[key2][c].non_productive);
                    lessprodCat=parseFloat(lessprodCat)+parseFloat(element[key2][c].less_productive);
                    undCat= parseFloat(undCat) + parseFloat(element[key2][c].undefined);
                    acsCat= parseFloat(acsCat) + parseFloat(element[key2][c].access_policy_breach);
    
                    prod = parseFloat(prod) + parseFloat(element[key2][c].productive);
                    nonprod= parseFloat(nonprod)+parseFloat(element[key2][c].non_productive);
                    lessprod = parseFloat(lessprod)+parseFloat(element[key2][c].less_productive);
                    und = parseFloat(und) +parseFloat(element[key2][c].undefined);
                    acs = parseFloat(acs) + parseFloat(element[key2][c].access_policy_breach);
    
                    element[key2][c].type='employee'
                    let sumHour = parseFloat(element[key2][c].productive) + parseFloat(element[key2][c].less_productive)
                    let nonSumHour = parseFloat(element[key2][c].undefined) +parseFloat(element[key2][c].non_productive);
                    element[key2][c].total_working_hour = parseFloat(sumHour)>100?100:parseFloat(sumHour.toFixed(2)) ;
                    element[key2][c].total_wasted_hour = parseFloat(sumHour) > 100?0:parseFloat(nonSumHour)>100?100:parseFloat(nonSumHour.toFixed(2));
                    element[key2][c].rating = ratingCal(parseFloat(element[key2][c].total_working_hour)) ;
                    total_working_hour=parseFloat(total_working_hour)+parseFloat(element[key2][c].total_working_hour);
                    total_working_hour_cat = parseFloat(total_working_hour_cat)+parseFloat(element[key2][c].total_working_hour);

                    if(parseFloat(element[key2][c].productive)  == 0  && parseFloat(element[key2][c].non_productive)== 0 && parseFloat(element[key2][c].less_productive)== 0 && parseFloat(element[key2][c].undefined)  == 0 ){
                      avgZero++;
                      parentLength= parentLength - 1;
                    }                
                      
                    element[key2][c].department=key
                    element[key2][c].sub_department=key2                     
                    empList.push(element[key2][c]);
    
                  }
                //  child.push({name:key2,data:element[key2],productive:parseFloat(prodCat/(element[key2].length - avgZero)).toFixed(2),non_productive:parseFloat(nonprodCat/(element[key2].length- avgZero)).toFixed(2),less_productive:parseFloat(lessprodCat/(element[key2].length- avgZero) ).toFixed(2),undefined:parseFloat(undCat/(element[key2].length - avgZero)).toFixed(2) ,access_policy_breach:parseFloat(acsCat/element[key2].length).toFixed(2) })
                child.push({name:key2,data:element[key2],productive:parseFloat(prodCat/(element[key2].length - avgZero)).toFixed(2),non_productive:parseFloat(nonprodCat/(element[key2].length- avgZero)).toFixed(2),less_productive:parseFloat(lessprodCat/(element[key2].length- avgZero) ).toFixed(2),undefined:parseFloat(undCat/(element[key2].length - avgZero)).toFixed(2) ,access_policy_breach:parseFloat(acsCat/element[key2].length).toFixed(2),total_working_hour:(total_working_hour_cat/(element[key2].length- avgZero)).toFixed(2),rating:ratingCal(total_working_hour_cat/element[key2].length- avgZero) })

              

                }
               });
    
              if(childName!==''&& Number.isInteger(childName) ===false ){
                catDaily.push({name:key,sub_department:subdept}) 
  
                // arr2.push({name:key,child:child,productive:parseFloat(prod/parentLength).toFixed(2),non_productive:parseFloat(nonprod/parentLength).toFixed(2),less_productive:parseFloat(lessprod/parentLength).toFixed(2),undefined:parseFloat(und/parentLength).toFixed(2) ,access_policy_breach:parseFloat(acs/parentLength).toFixed(2)})  
                arr2.push({name:key,child:child,productive:parseFloat(prod/parentLength).toFixed(2),non_productive:parseFloat(nonprod/parentLength).toFixed(2),less_productive:parseFloat(lessprod/parentLength).toFixed(2),undefined:parseFloat(und/parentLength).toFixed(2) ,access_policy_breach:parseFloat(acs/parentLength).toFixed(2),total_working_hour:(total_working_hour/parentLength).toFixed(2),rating:ratingCal(total_working_hour/parentLength) })  
                 
              }else{
                catDaily.push({name:key,sub_department:subdept}) 
  
             // arr2.push({name:key,data:element,productive:parseFloat(prod/element.length).toFixed(2),non_productive:parseFloat(nonprod/element.length).toFixed(2),less_productive:parseFloat(lessprod/element.length).toFixed(2),undefined:parseFloat(und/element.length).toFixed(2) ,access_policy_breach:parseFloat(acs/element.length).toFixed(2)})            
                 arr2.push({name:key,data:element,productive:parseFloat(prod/element.length).toFixed(2),non_productive:parseFloat(nonprod/element.length).toFixed(2),less_productive:parseFloat(lessprod/element.length).toFixed(2),undefined:parseFloat(und/element.length).toFixed(2) ,access_policy_breach:parseFloat(acs/element.length).toFixed(2),total_working_hour:(total_working_hour/parentLength).toFixed(2),rating:ratingCal(total_working_hour/parentLength)})          
                 
                 
              }
              
              
              
                
             });
  
  
             arr2.startdate= getDateFormat(dates.start);
             arr2.enddate= getDateFormat(dates.end);
             arr2.type= 'daily_worker';
             
                showCardListFormat(arr2,'daily_worker');
                SetDailyDept(catDaily)
                SetTabDataDaily(arr2)
                SetTabDataDailyDefault(arr2)
                SetDailyAll(empList)
                SetDailyDefault(empList);

  
            }else{
              toast.error("TW:03 Something went wrong while fetching productivity list of daily worker");
            }
    
          });
        SetisLoading(false)
    }
    

  },[dates,permission])



  useEffect(()=>{
    if(permission && permission.length > 0){
      if(regularDept.length> 0 || dailyDept.length> 0){
        getDepartment(userSession,
          org_id,sub_org_id).then(res=>{ 
          if(res.status==200){
            let arrReg=[]
            for(let i=0;i<regularDept.length;i++){
              arrReg.push({name:regularDept[i].name,department_id:res.data.find(item=>item.name==regularDept[i].name)?.department_id})
            }
            SetDepartmentList(arrReg);
            let arrDaily=[]
            for(let i=0;i<dailyDept.length;i++){
              arrDaily.push({name:dailyDept[i].name,department_id:res.data.find(item=>item.name==dailyDept[i].name)?.department_id})
            }
            SetDepartmentListDaily(arrDaily);
          }else{
            toast.error("TW:04 Something went wrong while retriving department");
          }
    
          if(res.status==300){
            toast.error("TW:06  Status 300 while retriving department");
            handleLogout();
    
          }
        })
    
      }
  }
  },[permission,regularDept,dailyDept])
  useEffect(()=>{
    if(permission && permission.length > 0){
    SetSubDepartmentID('');
    SetShiftID('');

    let findData = apiDataDefault.find(item=> item.department_id == department_id);
   
    if(findData){
      SetSubDepartmentList(findData.sub_department);
      SetShiftList(findData.shift);
    }
    else if(department_id=='all'){
      SetSubDepartmentList([]);
      SetShiftList([]);
    }
    else if(department_id!='' && department_id ){
      let dataDef= [...apiDataDefault];
      let sf=[];
      let sub =[];

      getSubDepartment(userSession,department_id,org_id,sub_org_id).then(res=>{
        if(res.status==200 || res.code == 200){
          SetSubDepartmentList(res.data);
          sub = res.data;
          
          getShift(userSession,org_id,department_id,sub_department_id,sub,sub_org_id).then(res=>{ 
            if(res.status=200){
              SetShiftList(res.data);
              sf = res.data;
              dataDef.push({department_id:department_id,sub_department:sub,shift:sf})
              SetAPIDataDefault(dataDef);
            }
          })
        }else{
          toast.error("TW:05 Something went wrong while retriving sub department");
        }
        if(res.status==300){
          toast.error("TW:07 Status 300 while retriving sub department");
          handleLogout();
  
        }
      })

   }
}
  },[department_id,permission])

  useEffect(()=>{
    if(permission && permission.length > 0){
    SetSubDepartmentIDDaily('');
    SetShiftIDDaily('');

    let findData = apiDataDefault.find(item=> item.department_id == department_idDaily);
    let dataDef= [...apiDataDefault];
    let sf=[];
    let sub =[];
    if(findData){
      SetSubDepartmentListDaily(findData.sub_department);
      SetShiftListDaily(findData.shift);
    }
    else if(department_idDaily=='all'){
      SetSubDepartmentListDaily([]);
      SetShiftListDaily([]);
      
    }
    else if(department_idDaily!='' && department_idDaily){
      getSubDepartment(userSession,department_idDaily,org_id,sub_org_id).then(res=>{
        if(res.status==200 || res.code == 200){
          SetSubDepartmentListDaily(res.data);
          sub = res.data;        
          getShift(userSession,org_id,sub_org_id,department_idDaily,sub_department_idDaily).then(res=>{ 
            if(res.status==200){
              SetShiftListDaily(res.data);
              sf=res.data;
              dataDef.push({department_id:department_idDaily,sub_department:sub,shift:sf})
              SetAPIDataDefault(dataDef);
            }else{
              toast.error(`TW:08 Something went Wrong while retriving shift`);
            }
          })
        }  
        if(res.status==300){
          handleLogout();
          toast.error(`TW:09 Something went Wrong while retriving shift`);
        }
      }) 

  }
}
  },[department_idDaily,permission])

  useEffect(()=>{
    if(permission && permission.length > 0){
    if(sub_department_id && sub_department_id!=''){
       getShift(userSession,org_id,department_id,sub_department_id,sub_org_id).then(res=>{ 
        if(res.status==200){
          SetShiftList(res.data);
        }else{
          toast.error(`TW:10 Something went Wrong while retriving shift`);
        }
    })
  }
}
  },[sub_department_id,permission])

  useEffect(()=>{
    if(permission && permission.length > 0){
    if(sub_department_idDaily && sub_department_idDaily!=''){

    
    getShift(userSession,org_id,department_idDaily,sub_department_idDaily,sub_org_id).then(res=>{ 
      if(res.status==200){
        SetShiftListDaily(res.data);
      }else{
        toast.error(`TW:11 Something went Wrong while retriving shift`);
      }
    })
  }
}
  },[sub_department_idDaily,permission])


  useEffect(()=>{
    localStorage.setItem('ProductiveStart',dates.start)
    localStorage.setItem('ProductiveEnd',dates.end)
  },[dates])


  

  useEffect(() => {
    if(permission && permission.length > 0){
      setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }
    }, [elementRef,permission]);

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
    
    localStorage.setItem('ProductiveStart',date);
    localStorage.setItem('ProductiveEnd',date);
      setDates({
        start: date,
        end: date,
    })

  }
 

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


 function handleLogout() {
  let requestBody = {}
  requestBody.token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
  requestBody.session = userDetails.session?userDetails.session:'123456789'

        
        logoutUser(requestBody).then(res => {
            if (res && res.status >= 200 && res.status <=+ 299) {
                    localStorage.removeItem('isLoggedIn')
                    localStorage.removeItem('userLoginDetails')
                    localStorage.removeItem('selectedDate')
                    localStorage.removeItem('tokenAuthTrace')
                    props.history.push(`/login`)
            }
        })
    }




  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    //setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    setDates((prev) => ({ ...prev, [`${type}`]: date }));

    if(type=='start'){
      localStorage.setItem('ProductiveStart',date);
    }
    localStorage.setItem('ProductiveEnd',date);
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }








function chartChangeTab(type,value){
  if(type=='employee'){
    SetActEmp(value)
  }else if(type=='asset'){
  SetActDaily(value)
  }
  
}
function handleClickCard(id,type='',event){
  
  if(activeEmp=='employee'){
   // props.history.push(`/productivity/employee/:${id}`);  
    window.open(`/productivity/employee/:${id}/${getDateFormat(selectedDate)}`, "_blank")

  }
  else if(activeEmp=='daily_worker'){
    window.open(`/productivity/daily_worker/:${id}/${getDateFormat(selectedDate)}`, "_blank")
    // props.history.push(`/productivity/daily_worker/:${id}`);  
  }
}

function capitalizeFirstLetter(string) {
  if(string){
    return string.toLowerCase();
  }
}

function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}





function AGTable(data,key){
  if(data){


  
  let widthCal= key=='allEmployeee'?(widthContainer - 60)/ 8:(widthContainer - 90)/ 8;
  
  let arr;
    arr=<div className={key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36) , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={35}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
  //            cellRendererParams: { checkbox: true },       
          }}
          headerHeight={35}
       //   defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:140 }}
       defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
           
          //pagination={true}
          rowData={data}
          key={key}
          onCellClicked={ (event) => handleClickCard(event.data.worker_id,activeEmp) }
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
  
      <AgGridColumn
          field="emp_name"
          headerName={"Name"}
          headerComponentParams =  {{
              template:`<div class="ag-cell-label-container" role="presentation"> 
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the employee.</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
              </div> 
            </div>`	
          }}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
      />
            <AgGridColumn
          field="worker_id"
          headerName={"Emp ID"}
          headerComponentParams =  {{
              template:`<div class="ag-cell-label-container" role="presentation"> 
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Worker ID.</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
              </div> 
            </div>`	
          }}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
      />
      <AgGridColumn field="productive" headerName="% Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">High Productivity</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn> 
       <AgGridColumn field="less_productive" headerName="% Less Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Less Productivity </span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn> 
      {/* <AgGridColumn field="total_working_hour" headerName="Total Working Hour" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Total Working Hour = Productive + Less Productive</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn> */}
            <AgGridColumn field="non_productive" headerName="% Non-Productive" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">No Productivity </span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
      <AgGridColumn field="undefined" headerName="Out of Range" valueFormatter={(params) =>params.value!='na'?params.value+'%':'-'}  headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Access Breached</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
            <AgGridColumn field="total_working_hour" headerName="Performance" valueFormatter={(params) =>params.value!='na'?params.value+'%':'-'}  headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Performance = Productive % + Less Productive %</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
      <AgGridColumn field="rating" headerName="Rating" cellRenderer= {(params)=>starRatingRender(params)}  headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Rating by performance</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
  
  </AgGridReact>
  </div></div>
  
  return arr
}else{
  return ;
}

}
function AGTableEmp(data,key){
  if(data){

  // data =  data.sort

  let widthCal= key=='allEmployeee'?(widthContainer - 60)/ 5:(widthContainer - 90)/ 5;
  
  let arr;
    arr=<div className={key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36) , width: "calc(100% - 1px)"}}>
      <AgGridReact
          rowHeight={35}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
  //            cellRendererParams: { checkbox: true },       
          }}
          headerHeight={35}
       //   defaultColDef={{sortable: true,resizable: true,minWidth: 45,width:140 }}
       defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
           
          //pagination={true}
          rowData={data}
          key={key}
          onCellClicked={ (event) => handleClickCard(event.data.worker_id,event.data.type?event.data.type:'') }
          overlayNoRowsTemplate={
              AGGridEMptyMEssage('')
            }        
       >
  
      <AgGridColumn
          field="emp_name"
          headerName={"Name"}
          headerComponentParams =  {{
              template:`<div class="ag-cell-label-container" role="presentation"> 
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Name of the employee.</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
              </div> 
            </div>`	
          }}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
      />
                  <AgGridColumn
          field="worker_id"
          headerName={"Emp ID"}
          headerComponentParams =  {{
              template:`<div class="ag-cell-label-container" role="presentation"> 
                  <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Worker ID.</span></span>
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
              </div> 
            </div>`	
          }}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
      />
            <AgGridColumn 
              sort={'desc'}
            field="total_working_hour" headerName="Total Working Hour" valueFormatter={(params) =>params.value!='na'?params.value+"%":'-'}    headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Total Working Hour = Productive + Less Productive</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>

            <AgGridColumn field="total_wasted_hour" headerName="Total Wasted Hour" valueFormatter={(params) =>params.value!='na'?params.value+'%':'-'}  headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Total wasted Hour = Non Productive + Out of Range </span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
            <AgGridColumn field="rating" headerName="Rating" cellRenderer= {(params)=>starRatingRender(params)}  headerComponentParams =  {{
          template:`<div class="ag-cell-label-container" role="presentation"> 
              <div ref="eLabel" class="ag-header-cell-label" role="presentation"> 
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
          <span class="tooltipcustom"><img src='${helpIcon}'/><span class="descHelp tootltipHelp">Rating by performance</span></span>
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span> 
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span> 
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span> 
          </div> 
      </div>`	
      }} ></AgGridColumn>
  
  </AgGridReact>
  </div></div>
  
  return arr
}else{
  return ;
}

}

function HandlerAccordParent(val,type='secondary'){
 
  let ActivList = [...parentAccord];
  let index = ActivList.indexOf(val)

  if(index !== -1){  
    ActivList.splice(index, 1);
    SetParentAccord(ActivList);
  }
  else if(type=='primary'){
          ActivList.push(val);
    SetParentAccord([val]);
   }else{     
        ActivList.push(val);          
        SetParentAccord(ActivList);
      }
}

useEffect(()=>{
  if(permission && permission.length > 0){
    StackChartEmployee();
  }
},[selectedDate,actEmp,permission])

useEffect(()=>{  
  if(permission && permission.length > 0 ){

    StackChartDaily();
  }
},[selectedDate,actDaily,permission])

function StackChartEmployee(dept_id = department_id, sub_dept_id = sub_department_id,shift_id= shiftID){
  if(permission && permission.length > 0){
  let arr=[]
  let requestBody={}
  SetFilterDisable(true)
  if(actEmp=='week'){
    requestBody.startdate= getDateFormat(moment(selectedDate).subtract(6, "days").toDate());
  }else if(actEmp=='month'){
    requestBody.startdate= getDateFormat(moment(selectedDate).subtract(30, "days").toDate());
  }else{
    requestBody.startdate= getDateFormat(moment(selectedDate).subtract(60, "days").toDate());
  }
  
  requestBody.enddate= getDateFormat(selectedDate);
  requestBody.slug= 'employee';
  requestBody.department_id= dept_id
  requestBody.sub_department_id=sub_dept_id
  requestBody.shift_id= shift_id
  SetisLoading1(true);
let cat=[];
let series= []
let lessProductive= []
let Productive= []
let nonProductive= []
let undefinedProductive=[]
let startDate = new Date(requestBody.startdate);
let endDate = new Date(requestBody.enddate);
  
if(dept_id!='' && dept_id!='all' && dept_id ){
  getPproductivityOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{
    if(res.status!=200){
      SetisLoading1(false);
      SetErrorEmployee(true)
    }else{
      SetErrorEmployee(false)
    }
    if(res.data && res.status==200){
     
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        let d2 = new Date(startDate);          
          let item = res.data[moment(startDate).format('YYYY/MM/DD')]
          if(item){
            cat.push(moment(startDate).format('YYYY/MM/DD'))

            lessProductive.push(AddZeroFormatter(item.less_productive));
            Productive.push(AddZeroFormatter(item.productive))
            nonProductive.push(AddZeroFormatter(item.non_productive))
            undefinedProductive.push(AddZeroFormatter(item.undefined))
          }else{
            cat.push(moment(startDate).format('YYYY/MM/DD'))
            lessProductive.push(0);
            Productive.push(0)
            nonProductive.push(0)
            undefinedProductive.push(0)
          }

      }


 SetChartData((prev) => ({ ...prev,employee:{
                categories:cat,
                series:[
                      {name:"Out of Range",data:undefinedProductive,color:"#dedee3"},
                      {name:"Non Productive",data:nonProductive,color:"#fc0001"},
                      {name:"Less Productive",data:lessProductive,color:"#fdbf00"},
                      {name:"Productive",data:Productive,color:"#90cf4e"},
                    ]}
                 }));
        


      SetisLoading1(false);
    }else{
      toast.error("TW:12 Something went wrong while retriving productivity overview");
    }

    });
  }
  else{
    getPproductivityOverviewAll(requestBody,userSession,org_id,sub_org_id).then(res=>{
      if(res.status!=200){
        SetisLoading1(false);
        SetErrorEmployee(true)
      }else{
        SetErrorEmployee(false)
      }
      if(res.data && res.status==200){
       
        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          let d2 = new Date(startDate);          
            let item = res.data[moment(startDate).format("YYYY-MM-DD")].overall
            if(item){
              cat.push(moment(startDate).format('YYYY/MM/DD'))
              lessProductive.push(AddZeroFormatter(item.less_productive));
              Productive.push(AddZeroFormatter(item.productive))
              nonProductive.push(AddZeroFormatter(item.non_productive))
              undefinedProductive.push(AddZeroFormatter(item.undefined))
            }else{
              cat.push(moment(startDate).format('YYYY/MM/DD'))
              lessProductive.push(0);
              Productive.push(0)
              nonProductive.push(0)
              undefinedProductive.push(0)
            }
  
        }
       
        
        SetChartData((prev) =>
                  

                  ({ ...prev,employee:{
            categories:cat,
            series:[
                  {name:"Out of Range",data:undefinedProductive,color:"#dedee3"},
                  {name:"Non Productive",data:nonProductive,color:"#fc0001"},
                  {name:"Less Productive",data:lessProductive,color:"#fdbf00"},
                  {name:"Productive",data:Productive,color:"#90cf4e"},
                ]}
             })
            
             );
  
        SetisLoading1(false);
      }else{
        toast.error("TW:13 Something went wrong while retriving productivity overview ");
      }
  
      });
  }

  }
}



// function AddZeroFormatter(value){
//   let sp =value.toString().split('.');
//   let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
//   let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
//   if(parseFloat(sp[1]) < 10){
//     end ='0'+ parseFloat(sp[1]);
//   }
//   return parseFloat(inital+"."+end);
  
// }
function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));
  
}



function StackChartDaily(dept_id = department_idDaily, sub_dept_id = sub_department_idDaily,shift_id= shiftIDDaily){
  
  let arr=[]
  let requestBody={}
  if(permission && permission.length > 0){
  SetFilterDisableDaily(true)
  
  if(actDaily=='week'){
    requestBody.startdate = getDateFormat(moment(selectedDate).subtract(6, "days").toDate());
  }else if(actDaily=='month'){
    requestBody.startdate = getDateFormat(moment(selectedDate).subtract(30, "days").toDate());
  }else{
    requestBody.startdate = getDateFormat(moment(selectedDate).subtract(60, "days").toDate());
  }
  
  requestBody.enddate= getDateFormat(selectedDate);
  requestBody.slug= 'daily_worker';
  requestBody.department_id= dept_id
  requestBody.sub_department_id=sub_dept_id
  requestBody.shift_id= shift_id
  SetisLoading2(true);
  let cat=[];
  let series= []
  let lessProductive= []
  let Productive= []
  let nonProductive= []
  let undefinedProductive=[]
  let startDate = new Date(requestBody.startdate);
  let endDate = new Date(requestBody.enddate);

if(dept_id && dept_id!='' && dept_id!='all'){
  getPproductivityOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{

  if(res.status!=200){
    SetErrorDaily(true)
    SetisLoading2(false);
  }else{
    SetErrorDaily(false);
  }
  if(res.data && res.status==200){

    for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
      let d2 = new Date(startDate);          
        let item = res.data[moment(startDate).format('YYYY/MM/DD')]
        

        if(item){
          cat.push(moment(startDate).format('YYYY/MM/DD'))
          lessProductive.push(AddZeroFormatter(item.less_productive));
          Productive.push(AddZeroFormatter(item.productive))
          nonProductive.push(AddZeroFormatter(item.non_productive))
          undefinedProductive.push(AddZeroFormatter(item.undefined))
        }else{
          cat.push(moment(startDate).format('YYYY/MM/DD'))
          lessProductive.push(0);
          Productive.push(0)
          nonProductive.push(0)
          undefinedProductive.push(0)
        }

    }

    
    SetChartData((prev) => ({ ...prev,daily:{
      categories:cat,
      series:[
            {name:"Out of Range",data:undefinedProductive,color:"#dedee3"},
            {name:"Non Productive",data:nonProductive,color:"#fc0001"},
            {name:"Less Productive",data:lessProductive,color:"#fdbf00"},
            {name:"Productive",data:Productive,color:"#90cf4e"},
          ] 
         }
       }));

       
    SetisLoading2(false);
      }else{
        toast.error("TW:14 Something went wrong while retriving productivity overview daily worker");
      }
    });
  }else{
    getPproductivityOverviewAll(requestBody,userSession,org_id,sub_org_id).then(res=>{
      if(res.status!=200){
        SetisLoading2(false);
        SetErrorDaily(true)
      }else{
        SetErrorDaily(false)
      }
      if(res.data && res.status==200){ 
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        let d2 = new Date(startDate);          
          let item = res.data[moment(startDate).format("YYYY-MM-DD")].overall
  
          if(item){
            cat.push(moment(startDate).format('YYYY/MM/DD'))
            lessProductive.push(AddZeroFormatter(item.less_productive));
            Productive.push(AddZeroFormatter(item.productive))
            nonProductive.push(AddZeroFormatter(item.non_productive))
            undefinedProductive.push(AddZeroFormatter(item.undefined))
          }else{
            cat.push(moment(startDate).format('YYYY/MM/DD'))
            lessProductive.push(0);
            Productive.push(0)
            nonProductive.push(0)
            undefinedProductive.push(0)
          }
  
      }
      SetChartData((prev) => ({ ...prev,daily:{
        categories:cat,
        series:[
              {name:"Out of Range",data:undefinedProductive,color:"#dedee3"},
              {name:"Non Productive",data:nonProductive,color:"#fc0001"},
              {name:"Less Productive",data:lessProductive,color:"#fdbf00"},
              {name:"Productive",data:Productive,color:"#90cf4e"},
            ] 
           }
         }));
      SetisLoading2(false);
        }else{
          toast.error("TW:15 Something went wrong while retriving productivity overview daily Worker");
        }
      })
    
  }


} 

}


function showCardListFormat(data,type){

  let cat=[];
  let subCat=[];
  let employee=[];

  for(let i=0; i<data.length;i++ ){
    
    let format =[]; 
    cat.push(data[i]);
    if(data[i].child){
      let parentCat = data[i];



      if(parentCat.child){
            
      for(let ii=0;ii<parentCat.child.length; ii++){
        
        let childCat = parentCat.child[ii]
          if(childCat.child){  

            for (let iii=0;iii<childCat.child.length;iii++){
                for(let emp=0;emp<childCat.child[iii].data.length;emp++){
                  employee.push(childCat.child[iii].data[emp])
                }
            }

          }else{
          subCat.push(childCat.name)
            for(let emp=0;emp<childCat.data.length;emp++){
            employee.push(childCat.data[emp])
          }

          }
      }
      }

    }else {
          for(let emp=0;emp<data[i].data.length;emp++){
            employee.push(data[i].data[emp])
          } 
    }

  }

  // SetSearchCatDefault(cat);
  // SetSearchEmployeeDefault(employee)
  // SetSearchSubCatDefault(subCat)
  return {cat:cat,employee:employee,subCat:subCat}
  
}


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function SearchTriggerValue(value){
  updateSearchValue(value);
  SetSearchDisplay(true);

  if(slug=='allEmployee'){
    let emp=employeeAllDefault;
    if(activeEmp=='daily_worker'){
      emp=dailyDefault
    }

    let arrSearchEmployee = emp.filter(item => item.emp_name.toString().toLowerCase().includes(value)) 
    SetEmployeeAll(arrSearchEmployee);    
    SetSearchEmployee(arrSearchEmployee);


  }else{

  
  let arrSearchCat2 =[];
  let arrSearchSubCat=[];
  let arrSearchEmployee=[];
  let cardList={cat:[],employee:[],subCat:[]}

    if(activeEmp=='employee'){
      cardList=showCardListFormat(tabDataEmployeeDefault,activeEmp)
    }else{
      cardList=showCardListFormat(tabDataDailyDefault,activeEmp)
    }
    let arrSearchCat = cardList.cat.filter(item => item.name.toString().toLowerCase().includes(value))   
  if(slug=='daily_worker'){
   arrSearchCat2 = tabDataDailyDefault.filter(item => item.name.toString().toLowerCase().includes(value)) 
   arrSearchSubCat = cardList.subCat.filter(item => item.toString().toLowerCase().includes(value)) 
   arrSearchEmployee = cardList.employee.filter(item => item.emp_name.toString().toLowerCase().includes(value)) 
      
  }else{
  arrSearchCat2 = tabDataEmployeeDefault.filter(item => item.name.toString().toLowerCase().includes(value)) 
    arrSearchSubCat = cardList.subCat.filter(item => item.toString().toLowerCase().includes(value)) 
    arrSearchEmployee = cardList.employee.filter(item => item.emp_name.toString().toLowerCase().includes(value)) 
  
  }
 
  let arr=[];

  for(let i=0;i<cardList.cat.length;i++){
    //if child exist
    if(cardList.cat[i].child){
      let childLoop = cardList.cat[i].child;

      //if parent seearch cat found
      if (cardList.cat[i].name.toString().toLowerCase().includes(value.toString().toLowerCase())) {
        let cond=cardList.cat[i];
        cond.condition ="condition 1"
        arr.push(cond);
      }
      else{
        //Searching in Sub Cat 
        let catrepeat=[];
        let empAr2=[];
          for(let y=0;y<childLoop.length;y++){
            //if subcat name match with search
            let childCat= childLoop[y]
           if(childLoop[y].name.toString().toLowerCase().includes(value.toString().toLowerCase())){

            catrepeat.push({name:childLoop[y].name,data:childLoop[y].data,condition:"condition 2"});
           // arr.push({name:cardList.cat[i].name,child:[childLoop[y]],condition:"condition 2" })
          }

          else{
            //Searching in employee
            let empAr=[];
            for(let emp=0;emp<childCat.data.length;emp++){
              
              if(childCat.data[emp].emp_name.toString().toLowerCase().includes(value.toString().toLowerCase())){
                let cond=childCat.data[emp];
                cond.condition ="condition 3"
                empAr.push(cond)
            }
            

            }
            if(empAr.length >0){

              catrepeat.push({name:childLoop[y].name,data:empAr,condition:"condition 2"});
            }   
          }
        }
        if(catrepeat.length > 0){
          if(arr.indexOf(cardList.cat[i].name) < 0){
            
            arr.push({name:cardList.cat[i].name,child:catrepeat,condition:"condition 8" })
         }
        }
        else if(catrepeat.length > 0){

          if(arr.indexOf(cardList.cat[i].name) < 0){

            arr.push({name:cardList.cat[i].name,child:catrepeat,condition:"condition 8" })
         }   
        }
        // else if(empAr.length >0 ){
        //   if(arr.indexOf(cardList.cat[i].name) < 0){

        //      arr.push({name:cardList.cat[i].name,child:empAr2,condition:"condition 4" })
        //   } 
        // }

      }
 
    }
    else{
      //If  child not exist direct search for category
      if (cardList.cat[i].name.toString().toLowerCase().includes(value.toString().toLowerCase())) {
        let cond=cardList.cat[i];
        cond.condition ="condition 5"
        
        arr.push(cond);

      }
      else{ 
        //Direct Search in employee
        let empAr=[];
        for(let emp=0;emp<cardList.cat[i].data.length;emp++){
          
          if(cardList.cat[i].data[emp].emp_name.toString().toLowerCase().includes(value.toString().toLowerCase())){   
              let cond = cardList.cat[i].data[emp];
              cond.condition ="condition 6"
              empAr.push(cond)
        }
  
        }
        if(empAr.length>0){
          arr.push({name:cardList.cat[i].name,data:empAr,condition:"condition 7 " })
        }
      }


    }
  }

  if((value.length==0||value=='') && slug=='employee'){
    SetTabDataEmploye(tabDataEmployee)
    SetSubTabCatActive(false)
  }else if((value.length==0||value=='') && slug=='daily_worker'){
    SetTabDataDaily(tabDataDailyDefault)
    SetSubTabCatActive(false)    
  }

  let findDuplicates =  arr.filter((item, index) => arr.indexOf(item) != index)

  if(activeEmp=='employee'){
    SetTabDataEmploye(arr)
    SetSubTabCatActive(true)

  }else if(activeEmp=='daily_worker'){
    SetTabDataDaily(arr)
    SetSubTabCatActive(true)
  }  
  else{
    SetTabData(arr)
    SetSubTabCatActive(true)
  }

  SetSearchCat(arrSearchCat);
  SetSearchEmployee(arrSearchEmployee);
  SetSearchSubCat(arrSearchSubCat);
  }
}

function showCardList(data){
    let arr= []
    let index=1
    let cat=[];
    let subCat=[];
    let employee=[];

    for(let i=0; i<data.length;i++ ){






      let format =[]; 
      if(data[i].child){
        let parentCat = data[i];
        let key=0;
       
            format.push( <div
                  key={i+" ParentCat"}
                  className={parentAccord.indexOf(i)!==-1 || searchValue.length>0?"Parencat active":"Parencat"}  >
                          
                          
                          
                          <div className="count">{parentCat.length}</div>
                          {/* <Row>
                            <Col lg={2}><div className="title"><span className="accordIcon" onClick={()=>HandlerAccordParent(i,'primary')}> {parentAccord.indexOf(i)!==-1 || subTabCatActive===true ?<Minus/> :<Plus/> }</span><span className="textTitle">{data[i].name}</span></div></Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].productive!="NaN"?(employeeAll.filter(item=>item.department==data[i].name && item.productive>0 ).reduce((a,b)=>a + parseFloat(b.productive),0)/employeeAll.filter(item=>item.department==data[i].name &&item.productive>0).length).toFixed(2)+"%":"0%"} </Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].less_productive!="NaN"?(employeeAll.filter(item=>item.department==data[i].name && item.less_productive>0).reduce((a,b)=>a + parseFloat(b.less_productive),0)/employeeAll.filter(item=>item.department==data[i].name && item.less_productive>0).length).toFixed(2) +"%":"0%"}</Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].non_productive!="NaN"?(employeeAll.filter(item=>item.department==data[i].name && item.non_productive>0).reduce((a,b)=>a + parseFloat(b.non_productive),0)/employeeAll.filter(item=>item.department==data[i].name && item.non_productive>0).length ).toFixed(2)+"%":"0%"}</Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].undefined!="NaN"?data[i].undefined+"%":"0%"}</Col>

                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].rating!='NaN'?<div dangerouslySetInnerHTML={{__html: starRating(data[i].rating)}}></div> :<div dangerouslySetInnerHTML={{__html: starRating(0)}}></div>}</Col>

                          </Row> */}
                          <Row>
                            <Col lg={2}><div className="title"><span className={parentCat.child && parentCat.child.length > 0?"accordIcon":"disabled"} onClick={()=>HandlerAccordParent(i,'primary')}> {parentAccord.indexOf(i)!==-1 || subTabCatActive===true ?<Minus/> :<Plus/> }</span><span className="textTitle">{data[i].name}</span></div></Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].productive!="NaN"?+data[i].productive+"%":"0%"} </Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].less_productive!="NaN"? data[i].less_productive+"%":"0%"}</Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].non_productive!="NaN"?+data[i].non_productive+"%":"0%"}</Col>
                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].undefined!="NaN"?data[i].undefined+"%":"0%"}</Col>

                            <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":data[i].rating!='NaN'?<div dangerouslySetInnerHTML={{__html: starRating(data[i].rating)}}></div> :<div dangerouslySetInnerHTML={{__html: starRating(0)}}></div>}</Col>

                          </Row>


                      </div>)


                       if(parentCat.child){
                        

                        index++
                        

                        for(let ii=0;ii<parentCat.child.length; ii++){
                         
                          let childCat = parentCat.child[ii]
                           if(childCat.child){  
                            index++
                            
                              for (let iii=0;iii<childCat.child.length;iii++){
                                let random = (Math.random()*21444).toFixed(0);

                                         format.push( <div
                                          key={'ThirdLevelCat'+i+iii}
                                          className={parentAccord.indexOf('ThirdLevelCat'+i+iii)!==-1 || subTabCatActive===true ?"ThirdLevelCat active":"ThirdLevelCat"}>
                                                            <div className="title"><span className="highlightTyp"><span className={childCat.child?"accordIcon":"disabled"} onClick={()=>HandlerAccordParent('ThirdLevelCat'+i+iii)}> {parentAccord.indexOf('ThirdLevelCat'+i+iii)!==-1 || subTabCatActive===true ?<Minus/> :<Plus/> }</span> <DoubleArrow /></span><span className="textTitle">{childCat.child[iii].name}</span></div>
                                                            <div className="count">{childCat.child[iii].length}</div>
                                                        </div>)    
                                          index++                     
                                          format.push( AGTable(childCat.child[iii].data,'ChildThirdSeries '+childCat.child[iii].name))    
                                    }



                           }else{
                            let random =(Math.random()*212344).toFixed(0)
                            
                            format.push(  <div
                              key={'SecondLevelCat'+i+ii}
                              className={parentAccord.indexOf('SecondLevelCat'+i+ii)!==-1|| searchValue.length>0?"SecondLevelCat active":"SecondLevelCat"}>
                                            
                                            {/* <Row>
                                                <Col lg={2}>
                                                <div className="title"><span className={'SecondLevelCat'+i+ii+" accordIcon "+parentAccord.indexOf('SecondLevelCat'+i+ii)} onClick={()=>{HandlerAccordParent('SecondLevelCat'+i+ii); }}>{parentAccord.indexOf('SecondLevelCat'+i+ii)!==-1 || subTabCatActive===true ? <Minus/>:<Plus/>}</span><span className="textTitle">{childCat.name}</span>
                                                  </div>
                                                </Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.productive!="NaN"?childCat.productive+" "+(employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.productive > 0).reduce((a,b)=>a+b.productive,0)/employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.productive > 0).length).toFixed(2)+"%":0}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.less_productive!="NaN"?childCat.less_productive+" "+(employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.less_productive > 0).reduce((a,b)=>a+b.less_productive,0)/employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.less_productive > 0).length).toFixed(2):0+"%"}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.non_productive!="NaN"?childCat.non_productive+" "+(employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.non_productive > 0).reduce((a,b)=>a+b.non_productive,0)/employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.non_productive > 0).length).toFixed(2):0+"%"}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.undefined!="NaN"?childCat.undefined+" "+(employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.undefined > 0).reduce((a,b)=>a+b.undefined,0)/employeeAll.filter(item=>item.department == data[i].name && item.sub_department ==childCat.name && item.undefined > 0).length).toFixed(2):0+"%"}</Col>

                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.rating!="NaN"?<div dangerouslySetInnerHTML={{__html: starRatingPercent(childCat.total_working_hour)}}></div>:<div dangerouslySetInnerHTML={{__html: starRatingPercent(0)}}></div> }</Col>
                                              </Row> */}
                                              <Row>
                                                <Col lg={2}>
                                                <div className="title"><span className={`SecondLevelCat${i+ii} accordIcon ${parentAccord.indexOf('SecondLevelCat'+i+ii)} ${childCat.data.length > 0?'':'disabled'}`}  onClick={()=>{HandlerAccordParent('SecondLevelCat'+i+ii); }}>{parentAccord.indexOf('SecondLevelCat'+i+ii)!==-1 || subTabCatActive===true ? <Minus/>:<Plus/>}</span><span className="textTitle">{childCat.name}</span>
                                                  </div>
                                                </Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.productive!="NaN"?childCat.productive+"%":0+"%"}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.less_productive!="NaN"?childCat.less_productive+"%":0+"%"}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.non_productive!="NaN"?childCat.non_productive+"%":0+"%"}</Col>
                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.undefined!="NaN"?childCat.undefined+"%":0+"%"}</Col>

                                                <Col lg={2}> {searchValue.length>0 || searchValue!=''?"":childCat.rating!="NaN"?<div dangerouslySetInnerHTML={{__html: starRatingPercent(childCat.total_working_hour)}}></div>:<div dangerouslySetInnerHTML={{__html: starRatingPercent(0)}}></div> }</Col>
                                              </Row>
                                        </div>)    

                            format.push( AGTable(childCat.data,'ChildSecondSeries '+childCat.name))    
               
                           }

                        }
                       }


      }else {
        index++
        format.push(<div
          key={i+" ParentCat"}
          className={parentAccord.indexOf(i)!==-1 || subTabCatActive===true || searchValue.length>0 ?"Parencat active":"Parencat"}  >
                      <Row>
                            <Col lg={2}><div className="title"><span className="accordIcon" onClick={()=>HandlerAccordParent(i,"primary")}> {parentAccord.indexOf(i)!==-1 ?<Minus/> :<Plus/> }</span><span className="textTitle">{data[i].name}</span></div></Col>
                            <Col lg={2}>{searchValue.length>0 || searchValue!=''?"":data[i].productive+"%"}</Col>
                            <Col lg={2}>{searchValue.length>0 || searchValue!=''?"": data[i].less_productive+"%"}</Col>
                            <Col lg={2}>{searchValue.length>0 || searchValue!=''?"":data[i].non_productive+"%"}</Col>
                            <Col lg={2}>{searchValue.length>0 || searchValue!=''?"":data[i].undefined+"%"}</Col>
                            {/* <Col lg={1}>{searchValue.length>0 || searchValue!=''?"":data[i].total_wasted_hour+"%"}</Col> */}
                            <Col lg={2}>{searchValue.length>0 || searchValue!=''?"":<div dangerouslySetInnerHTML={{__html: starRating(data[i].rating)}}></div>}</Col>
                          </Row>

                 
              </div>
              )
              format.push(AGTable(data[i].data,'nochild '+data[i].name));
       
      }      
      
      arr.push(<div className={parentAccord.indexOf(i)!==-1|| subTabCatActive===true?"innerDivAccord active":"innerDivAccord" }>{ format}</div>)

    }

    if(data.length > 0){
      return arr;
    }else{
      return   (   <Row className="noRecordStyle" style={{margin:"0px"}}>
      <Col lg={12} className="Parencat" style={{margin:"5px 0px 0px 0px"}}><div className="title"><span className="textTitle">No Record Found</span></div></Col>
     
    </Row>)
    }

    

  }
function CatEvent(value){
  SetSearchDisplay(false);
  SetActiveDefault(value)
  SetSubTabCatActive(false);
  SetTabData( searchCatDefault.filter(item => item.name.toString().toLowerCase().includes(value.toString().toLowerCase())) );
}
function SubCatEvent(value){
  SetSearchDisplay(false);
  SetActiveDefault(value)
  SetSubTabCatActive(true);
  let arr=[];
  for(let i=0;i<searchCatDefault.length;i++){
    if(searchCatDefault[i].child){
      let childLoop = searchCatDefault[i].child;
      for(let y=0;y<childLoop.length;y++){
        if(childLoop[y].name.toString().toLowerCase().includes(value.toString().toLowerCase())){
          arr.push({name:searchCatDefault[i].name,child:[childLoop[y]] })
        }
      }
    }
  }
  SetTabData( arr);
}
function ratingCal(val){
  if(parseFloat(val)>= 90){
      return 5
  }else if(parseFloat(val)>= 80 && parseFloat(val)< 90){
      return 4
  }
  else if(parseFloat(val)>= 70 && parseFloat(val)< 80){
      return 3
  }
  else if(parseFloat(val)>= 60 && parseFloat(val)< 70){
      return 2
  }
  else if(parseFloat(val)>= 50 && parseFloat(val) < 60){
      return 1
  }else{
      return 0
  }

}
function starRatingRender(params){
        

  var op =document.createElement('div');

  var eGui = document.createElement('div');
   
      eGui.innerHTML=`${starRating(params.value)}`;
          



   return eGui;



}
function starRating(val){
  let rate='';
  if(val==0){
      return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
  }else{
      for(let i=1;i<=5;i++){
          if(i<=val){
              rate+=`${StarFill}`;
          }else{
              rate+=`${StarEmpty}`;
          }
      }
  }
  return `<div class="ratingFill ratingAG">${rate}</div>`;


}
function starRatingPercent(val){

  let rat= parseFloat(ratingCal(val));
  let rate='';
  if(rat==0){
      return `<div class="ratingFill ratingAG">${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}${StarEmpty}</div>`
  }else{
      for(let i=1;i<=5;i++){
          if(i<=rat){
            rate+=`${StarFill}`;
          }else{
            rate+=`${StarEmpty}`;
          }
      }
  }
  return `<div class="ratingFill ratingAG">${rate}</div>`;


}

  return (<>
    {permissionAPICall?permission.length > 0?
    <div className="manpowerManagementMainDiv productivityManagementDiv">
          <ToastContainer
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss={false}
            pauseOnHover={true}
            width={"500px"}
          />
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={3} className="m-t-sm">
            <CommonHeading title="Productivity" />
          </Col>
          <Col lg={9} className="text-right">
          
          <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                        </div>
                        <div className="commonHeadingDateMainDivParent">
                            <CommonDatePicker
                                selectedDate={selectedDate}
                                handleSelectDate={handleDateSelect}
                                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                            />
                        </div>

                        

          </Col>
        </Row>


 
          <Row>
            <Col>
              <div
                className="manpowerManagementEmployeeListMainDiv m-t"
                style={{ backgroundColor: "#202236", padding: "2rem 1.5rem" }}
              >

                <h2 style={{color:"#fff"}}>Compare</h2>
                <div className="chartMainDiv chartMainDivProductivity">
                  <div className="chart">
                    <div className="titleBox">
                      <div className="title">Regular Worker
                          <div className="al-rt">
                          {/* <div  className="leftSelect">
                              <Select
                                  placeholder="Select Department"
                                  optionFilterProp="children"
                                  onChange={(val)=>{SetDepartmentID(val);SetSubDepartmentID('');SetShiftID('');StackChartEmployee(val,'',''); }}
                                  dropdownClassName={'smallerDrop'}
                                  style={{width:"75px"}} 
                                  value={department_id?department_id:'all'}
                                  key="departmentFilter"
                              >
                                <Option value='all' key={"DepartmentAll"}>All</Option>
                                  {
                                  departmentList && departmentList.length > 0? departmentList.map((item)=>{
                                      return <Option value={item.department_id} key={"Department"+item.department_id}>{item.name}</Option>
                                  }):""
                                  }
                              </Select>
                                <Select
                                placeholder="Select Sub Department"
                                optionFilterProp="children"
                                onChange={(val)=>{SetSubDepartmentID(val);SetShiftID('');StackChartEmployee(department_id,val,'');  }}
                                dropdownClassName={'smallerDrop'}
                                value={sub_department_id?sub_department_id:'all'}
                                style={{width:"75px"}}
                                disabled={subdepartmentList.length > 0?false:true}
                                key="subdepartmentFilter"
                            >
                                                              <Option value='all' key={"SubDepartmentAll"}>All</Option>
                                {subdepartmentList.map((item)=>{
                                    return <Option value={item.sub_department_id} key={"SubDepartment"+item.sub_department_id}>{item.sub_dept_name}</Option>
                                })
                                }
                            </Select>
                            
                              
                                                    
                          </div> */}
                          <div className="righSelect">
                          {/* <Select
                          placeholder="Select Shift"
                          optionFilterProp="children"
                          onChange={(val)=>{SetShiftID(val);StackChartEmployee(department_id,sub_department_id,val)}}
                          dropdownClassName={'smallerDrop'}
                          value={shiftID?shiftID:'all'}
                          style={{width:"75px"}}
                          disabled={shiftList.length > 0?false:true}
                          key="shiftFilter"
                      >
                        <Option value='all' key={"ShiftAll"}>All</Option>
                          {shiftList && shiftList.length > 0 ?shiftList.map(item=>{
                              return <Option value={item.shift_id} key={item.shift_name}>{item.shift_name}</Option>
                            })
                          :""
                          }
                      </Select> */}
                      {/* !filterDisable?<button className="btn btn btn-small" style={{marginLeft:"5px"}} disabled={filterDisable} onClick={StackChartEmployee}>Submit</button>:'' */}
                      </div>
                            <button className={actEmp=='week'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('employee','week')} >1 Week</button>
                            <button className={actEmp=='month'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('employee','month')}>1 Month</button>
                            <button className={actEmp=='2 month'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('employee','2 month')}>2 Month</button>
                          </div>
                        </div>
                        </div>
                        
                        <div className="titleBox">
                             {
                             
                             department_id!='' && department_id && departmentList && departmentList.filter(item=>item.department_id == department_id)[0]?<div className="filterSelected" onClick={()=> {SetDepartmentID('');SetShiftID('');SetSubDepartmentID('');StackChartEmployee('','','')}} >{departmentList.filter(item=>item.department_id == department_id)[0].name  } <span>x</span></div>:""} 
                             {sub_department_id!='' && sub_department_id && subdepartmentList && subdepartmentList.filter(item=>item.sub_department_id == sub_department_id)[0] ?<div className="filterSelected" onClick={()=>{SetSubDepartmentID('');SetShiftID('');StackChartEmployee(department_id,'','') }}>{subdepartmentList.filter(item=>item.sub_department_id == sub_department_id)[0].sub_dept_name} <span>x</span></div>:""} 
                              {shiftID!='' && shiftID && shiftList && shiftList.filter(item=>item.shift_id == shiftID)[0]?<div className="filterSelected" onClick={()=>{ SetShiftID('');StackChartEmployee(department_id,sub_department_id,'') }}>{shiftList.filter(item=>item.shift_id == shiftID)[0].shift_name} <span>x</span></div>:""} 
                            </div> 
                        

                        {isLoading1?
                          <div className="text-center m-t-lg m-b-lg">
                            <img src={spinnerLoader} className="" />
                         </div>:errorEmployee?<div className="errorThing">No Data Found</div>:
                        <StackChart
                          categories={chartData.employee.categories}
                          series={chartData.employee.series}
                          seriesName={"Employee Productivity"}
                          chartData={chartData}
                          type={actEmp}

                        />
                        }
                  </div>
                  <div className="chart">
                    <div className="titleBox">
                      <div className="title">Daily Worker
                          <div className="al-rt">
                          {/* <div  className="leftSelect">
                                  <Select
                                  placeholder="Select Department"
                                  optionFilterProp="children"
                                  onChange={(val)=>{SetDepartmentIDDaily(val); SetSubDepartmentIDDaily('');SetShiftIDDaily('');StackChartDaily(val,'','')  }}
                                  dropdownClassName={'smallerDrop'}
                                  style={{width:"75px"}} 
                                  value={department_idDaily?department_idDaily:'all'}
                                  key="departmentFilterDaily"
                              >
                                <Option value='all' key={"DepartmentAllDaily"}>All</Option>
                                  {
                                  departmentListDaily && departmentListDaily.length > 0? departmentListDaily.map((item)=>{
                                      return <Option value={item.department_id} key={"DepartmentDaily"+item.department_id}>{item.name}</Option>
                                  }):""
                                  }
                              </Select>
                                <Select
                                placeholder="Select Sub Department"
                                optionFilterProp="children"
                                onChange={(val)=>{SetSubDepartmentIDDaily(val);SetShiftIDDaily(''); StackChartDaily(department_idDaily,val,'')}}
                                dropdownClassName={'smallerDrop'}
                                value={sub_department_idDaily?sub_department_idDaily:'all'}
                                style={{width:"75px"}}
                                disabled={subdepartmentListDaily.length > 0?false:true}
                                key="subdepartmentFilterDaily"
                            >
                                <Option value='all' key={"SubDepartmentAllDaily"}>All</Option>
                                {subdepartmentListDaily.map((item)=>{
                                    return <Option value={item.sub_department_id} key={"SubDepartmentDaily"+item.sub_department_id}>{item.sub_dept_name}</Option>
                                })
                                }
                            </Select>
                            
                              
                                                    
                          </div> */}
                          <div className="righSelect">
                          {/* <Select
                              placeholder="Select Shift"
                              optionFilterProp="children"
                              onChange={(val)=>{SetShiftIDDaily(val);StackChartDaily(department_idDaily,sub_department_idDaily,val)}}
                              dropdownClassName={'smallerDrop'}
                              value={shiftIDDaily?shiftIDDaily:'all'}
                              disabled={shiftListDaily.length > 0?false:true}
                              style={{width:"75px"}}
                              key="shiftFilterDaily"
                      >
                        <Option value='all' key={"ShiftAllDaily"}>All</Option>
                          {shiftListDaily && shiftListDaily.length > 0 ?shiftListDaily.map(item=>{
                              return <Option value={item.shift_id} key={"Daily"+item.shift_name}>{item.shift_name}</Option>
                            })
                          :""
                          }
                      </Select> */}
                      { /*!filterDisableDaily?<button className="btn btn btn-small" disabled={filterDisableDaily} style={{marginLeft:"5px"}} onClick={StackChartDaily}>Submit</button>:"" */}
                      </div>
                            <button className={actDaily=='week'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('asset','week')}>1 Week</button>
                            <button className={actDaily=='month'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('asset','month')}>1 Month</button>
                            <button className={actDaily=='2 month'?"btn btn btn-small":"btn-transparent btn btn-small"} onClick={()=>chartChangeTab('asset','2 month')}>2 Month</button>
                          </div>
                        </div>
                    </div>
                    <div className="titleBox">
                             {department_idDaily!=''&& department_idDaily && departmentList && departmentList.filter(item=>item.department_id == department_idDaily)[0]?<div className="filterSelected" onClick={()=>{SetDepartmentIDDaily('');SetSubDepartmentIDDaily('');SetShiftIDDaily('');StackChartDaily('','','')} }>{departmentList.filter(item=>item.department_id == department_idDaily)[0].name} <span>x</span></div>:""} 
                             {sub_department_idDaily!='' && sub_department_idDaily && subdepartmentListDaily && subdepartmentListDaily.filter(item=>item.sub_department_id == sub_department_idDaily)[0]?<div className="filterSelected" onClick={()=>{SetSubDepartmentIDDaily('');StackChartDaily(department_idDaily,'','')} }>{subdepartmentListDaily.filter(item=>item.sub_department_id == sub_department_idDaily)[0].sub_dept_name} <span>x</span></div>:""} 
                              {shiftIDDaily!='' && shiftIDDaily && shiftListDaily && shiftListDaily.filter(item=>item.shift_id == shiftIDDaily)[0]?<div className="filterSelected" onClick={()=>{SetShiftIDDaily('');StackChartDaily(department_idDaily,sub_department_idDaily,'')} }>{shiftListDaily.filter(item=>item.shift_id == shiftIDDaily)[0].shift_name} <span>x</span></div>:""}                              
                        </div>

                    {isLoading2?
                        <div className="text-center m-b-lg">
                        <img src={spinnerLoader} className="m-t-lg" />
                    </div>:errorDaily?<div className="errorThing">No Data Found</div>:
                      <StackChart
                          categories={chartData.daily.categories}
                          series={chartData.daily.series}
                          seriesName={"Truck Productivity"}
                          type={actDaily}
                          
                        />
                    }
                  </div>

                </div>
              </div>
            </Col>
          </Row>
       


          <Row className="m-t">
          <Col lg={12}>
              <div className="manpowerManagementEmployeeListMainDiv">

            <div className={'siteListMainDiv ' + (props.hideHeading ? 'p-l-0 p-r-0' : '')} style={props.hideHeading ? { paddingTop: 0, paddingBottom: 0 } : {}}  ref={elementRef}>
                <Row style={{ alignItems: 'center' }}>
                    <Col lg={8} className={props.hideHeading ? 'p-l-0' : ''}>
                    <button type="button" className={slug=='allEmployee'?"btn btn-group btn-active":"btn btn-group"} onClick={()=>{setSlug('allEmployee');showCardListFormat(activeEmp=='employee'?tabDataEmployeeDefault:tabDataDailyDefault,activeEmp);SearchTriggerValue('') }}>Employee</button>
                       <button type="button" className={slug=='employee'?"btn btn-group btn-active":"btn btn-group"} onClick={()=>{setSlug('employee');showCardListFormat(activeEmp=='employee'?tabDataEmployeeDefault:tabDataDailyDefault,activeEmp);SearchTriggerValue('') }} >Team</button>
                       {/* <button type="button" className={slug=='asset'?"btn btn-group btn-active":"btn btn-group"} onClick={()=>{setSlug('asset');showCardListFormat(tabDataDailyDefault);SearchTriggerValue('') }}>Daily</button> */}
                    </Col>

                    <Col  lg={4} className={props.hideHeading ? 'p-r-0 resp-team-box' : ''}>
                            <div className='globalSearch globalSearchAdvance'>
                                <input
                                    type='text'
                                    value={searchValue}
                                    placeholder={'Search'}
                                    name="siteSearch" 
                                    onChange={(event) => SearchTriggerValue(event.target.value) }
                                />
                                {displaySearch?
                                <div className="suggestionBox">
                                {searchValue.trim().length  && slug!='allEmployee' ?
                                     <>
                                     {searchCat.length > 0?<div className="catTitle">Category <strong>{searchCat.length}</strong></div>:""}
                                     {searchCat.map((s, index) => {
                                            return  <div className='suggestion' key={index} onClick={()=>CatEvent(s.name)} >{s.name}</div>
                                          })
                                        }</>: null
                                }
                                {searchValue.trim().length && slug!='allEmployee'?
                                    <>
                                      {searchSubCat.length > 0? 
                                      <div className="catTitle">Sub Category <strong>{searchSubCat.length}</strong></div>
                                        :""}
                                        {
                                        searchSubCat.map((s, index) => {
                                          return       <div className='suggestion' onClick={()=>SubCatEvent(s)} key={index} >{s}</div>
                                        })}
                                         
                                         </>: null
                                }
                                {searchValue.trim().length ?
                                    <>
                                    {searchEmployee.length > 0? 
                                      <div className="catTitle">Employee <strong>{searchEmployee.length}</strong></div>
                                        :""}

                                        {searchEmployee.map((s, index) => {
                                         return  <div className='suggestion' key={index} onClick={() => handleClickCard(s.tag_serial,activeEmp)} >{s.emp_name}</div>
                                        })}
                                        </>: null
                                }
                                </div>:""}

                                {displaySearch?<div className="bghideDisplaySearch" onClick={()=>SetSearchDisplay(false) }></div>:""}

                            </div>
                        </Col>



                     {/* <Col lg={4} className={props.hideHeading ? 'p-r-0 resp-team-box' : ''}>
                            <div className="listingSearchMainDiv">
                            <div
                              className="startDateEndDateMainDiv date-start"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>Start Date</label>
                              <DatePicker
                                selected={dates.start}
                                onChange={(date) => handleDate(date, "start")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText(
                                  "Start Date"
                                )}
                                maxDate={moment(dates.end).toDate()}
                                minDate={new Date('2023-01-01')}
                              />
                             
                            </div>


                            <div
                              className="startDateEndDateMainDiv date-end overflow-prevent-date"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label>End Date</label>
                              <DatePicker
                                selected={moment(dates.end).toDate()}
                                onChange={(date) => handleDate(date, "end")}
                                dateFormat={'MMM dd'}
                                isClearable={false}
                                placeholderText={getTranslatedText("End Date")}
                                maxDate={selectedDate}
                                minDate={dates.start}
                                // popperPlacement="top-right"
                                popperPlacement="auto-right"
                              />
                             
                            </div>
                            </div>
                        </Col> */}
                </Row>
               

                < Row >
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv">
                            {isLoading ?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                                // employeeList && employeeList.length > 0 ?
                                slug!='allEmployee'?<React.Fragment>

                                 <div className="subLineTab"><div className={activeEmp=='employee'?"sublinetablist active":"sublinetablist"} onClick={()=>SetActiveEmp('employee')}>Regular Worker</div><div className={activeEmp=='daily_worker'?"sublinetablist active":"sublinetablist"}  onClick={()=>SetActiveEmp('daily_worker')} >Daily Worker</div></div> 
                                 <div style={{marginTop:"20px"}}> 
                                  <div
                  key={"ParentCatHeader"}
                  className={"Parencat Parencatheader"}  >
                          <Row>
                            <Col lg={2}> Name</Col>
                            <Col lg={2}>% Productive</Col>
                            <Col lg={2}>% Less Productive</Col>
                            <Col lg={2}>% Non Productive</Col>
                            <Col lg={2}>% Out of Range</Col>
                            <Col lg={2}>Rating</Col>
                          </Row>
                      </div>

                       {slug=='employee' && activeEmp=='employee'?showCardList(tabDataEmployee):""}
                       {slug=='employee' && activeEmp=='daily_worker' ?showCardList(tabDataDaily):""}</div>
                                </React.Fragment>:<>
                                <div className="subLineTab">
                                  <div className={activeEmp=='employee'?"sublinetablist active":'sublinetablist'} onClick={()=>SetActiveEmp('employee')}>Regular Worker</div><div className={activeEmp=='daily_worker'?"sublinetablist active":'sublinetablist'} onClick={()=>SetActiveEmp('daily_worker')} >Daily Worker</div></div>
                                  <div style={{marginTop:"20px"}}>{activeEmp=='employee'?AGTableEmp(employeeAll,'allEmployeee'):AGTableEmp(dailyAll,'allEmployeeeDaily')} </div></>
                                
                            }

                        </div>
                    </Col>
                </Row>
            </div>
            </div>
        </Col>
    </Row>

 <Modal
                isOpen={anouncementModal}
                style={customStyle}
                className={"modalAnnouncement"}
                closeOnClick={true}
            >
              <img src={closeModal} className="closeModalImg" style={{cursor:"pointer"}} onClick={()=>SetAnouncementModal(false)} />
                <div className='logoutModal'>
                
                    <div className="logoutTitle"><div className="announceMEntTitle"> We Are upgrdaing <img src={Anouncement}/></div></div>
                    <div className='logoutDesc'>
                      <p>We are upgrading our team structure with function names for mobile app view feature.</p>
                      <p> During this upgrade activity, productivity calculation of sub department may experience some intermittent issues on 14-04-2023 till 10PM.</p>
                  </div>

                </div>
            </Modal>
      </Container>


    </div>:<Col lg={12}>
    {/* <div className='errorPermission'></div> */}
<div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
</Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                        <img src={spinnerLoader} />
                    </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(Productivity)
);
