import axios from "axios";


let prefixURL = process.env.REACT_APP_URL_LOGIN
let prefixURL2 = process.env.REACT_APP_URL
let prefixURLV2 = process.env.REACT_APP_URL_V2;


let token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";

export function getLocationCordinate( sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL + `/location/get_location_cordinate?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getDeviceReport(sessionID, org_id,sub_org_id){
    return axios.get(prefixURL + `/device/get_device_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
    .then(res => res.data).catch(err => err)
}
export function getAllEmpProductivityInfo(start_date,end_date, sessionID, org_id,slug) {
    return axios.get(prefixURL2 + `/get_all_emp_productivity_info?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&slug=${slug}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => res.data).catch(err => err)
}
export function getCycleCountPlan( sessionID, org_id,date) {
    return axios.get(prefixURL2 + `/get_cycle_count_plan?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&date=${date}`)
        .then(res => res.data).catch(err => err)
}
//https://api.traceplus.ai/trace/api/v1/onboarding/get_onboarding_report?session=f67bf1924d984a2095327a3f08a4e449&org_id=28&start_date=2023-05-08&end_date=2023-05-09
export function getOnBoardEmployee(requestBody, sessionID, org_id,sub_org_id){
        return axios.get(prefixURL2 + `/onboarding/get_onboarding_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${requestBody.start_date}&end_date=${requestBody.end_date}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getTeamListofMap( sessionID, org_id,date,slug) {
    return axios.get(prefixURL2 + `/get_team_info?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&date=${date}&slug=${slug}`)
        .then(res => res.data).catch(err => err)
}

export function getLiveData( sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/live_data?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getCategoryList(requestBody) {   
    return axios.get(prefixURL + `/category/list_category?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}
export function getStreamData(requestBody) {   
    return axios.get(prefixURL + `/stream_data?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}
export function getActiveEmployeeV2(sessionID, org_id,date){

    return axios.get(prefixURLV2 + `/onboarding/active_employee_list?session=${sessionID}&org_id=${org_id}&date=${date}`).then(res=>res.data).catch(err=>err)
}
export function getLocationType( sessionID) {
    return axios.get(prefixURL + `/location/get_location_type?session=${sessionID}`)
        .then(res => res.data).catch(err => err)
}

